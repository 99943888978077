import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  filter: 1,
};
export const rfiSlice = createSlice({
  name: "rfi",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

export const { setFilter } = rfiSlice.actions;

export default rfiSlice.reducer;
