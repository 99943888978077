import {
  Box,
  Button,
  Card,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";

export const RadioGroupWrapper = styled(RadioGroup)({
  paddingLeft: "10px",
  "& .MuiRadio-root": {
    height: "16px",
    width: "20px",
  },
  "& .MuiFormControlLabel-root": {
    gap: "4px",
  },
  "& .MuiTypography-root": {
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "14px",
    fontWeight: 400,
  },
});

export const CropTypeWrapper = styled(Box)(({ theme }) => ({
  width: "100%",

  "& .dropdown-wrapper": {
    width: "100%",
    maxWidth: "100%",
  },

  "& .dropdown-input-row": {
    width: "100%",
  },

  "& .dropdown-parent": {
    width: "100%",
  },

  "& .label-text": {
    "& .material-icons-outlined": {
      display: "none",
    },
  },

  [theme.breakpoints.up("md")]: {
    width: "360px",
  },
}));

export const AddMoreButton = styled(Button)(({ theme, isMobile }) => ({
  background: "var(--Tokens-Button-Borderless, #005CBC)",
  padding: "6px 9px",
  borderRadius: 24,
  border: "1px solid #C0C0C0",
  boxSizing: "border-box",
  fontSize: 14,
  fontWeight: 700,
  color: "#FFFFFF",
  textTransform: "none",
  height: "32px",
  display: "flex",
  gap: "4px",
  width: isMobile ? "100%" : "auto",
}));

export const ViewDocumentButton = styled(Button)({
  padding: "4px 20px",
  borderRadius: 24,
  border: "1px solid #005CBC",
  fontSize: 12,
  fontWeight: 700,
  color: "var(--Tokens-Button-Borderless, #005CBC)",
  textTransform: "none",
  height: "24px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  width: "fit-content",
});

export const UploadInfo = styled(Box)({
  background: "var(--Tokens-Background-Layer-02, #EFF5FC)",
  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
  padding: "8px 16px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  height: "fit-content",
});

export const UploadInfoLabel = styled(Typography)({
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "14px",
  fontWeight: 700,
  color: "var(--Tokens-Text-Secondary, #616161)",
});

export const UploadInfoDescription = styled(Typography)({
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "12px",
  fontWeight: 400,
  color: "var(--Tokens-Text-Secondary, #616161)",
});

export const FooterButton = styled(Button)({
  padding: "4px 32px",
  borderRadius: 24,
  fontSize: 14,
  fontWeight: 700,
  color: "#FFFFFF",
  textTransform: "none",
  height: "32px",
});

export const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

export const Body = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",

  [theme.breakpoints.up("md")]: {
    padding: "16px 52px 0",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "12px 32px 0",
  },
  [theme.breakpoints.up("xs")]: {
    padding: "8px 14px 0",
  },
}));

export const CardWrapper = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",

  [theme.breakpoints.up("md")]: {
    padding: "24px",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "24px 16px",
  },
  [theme.breakpoints.up("xs")]: {
    padding: "24px 16px",
  },
}));

export const HeaderBox = styled(Box)(({ theme, isMobile }) => ({
  display: "flex",
  padding: "2px 16px",
  justifyContent: "space-between",
  alignItems: isMobile ? "start" : "center",
  flexDirection: isMobile ? "column" : "row",
  gap: isMobile && "16px",
}));

export const HeaderTitle = styled(Typography)({
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontWeight: 700,
  fontSize: 18,
});
