import { Button, Typography } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BackToFunction = ({ label, handleBack }) => {
  return (
    <Button
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        gap: "24px",
        textTransform: "none",
        padding: "20px 0px 20px 0px",
        justifyContent: "start",
        alignItems: "center",
      }}
      onClick={handleBack}
    >
      <ArrowBackIcon sx={{ width: "24px", height: "24px", fill: "#005CBC" }} />
      <Typography
        sx={{
          color: "var(--Tokens-Button-Borderless, #005CBC)",
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "var(--Font-Size-Body, 16px)",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-Body, 22px)",
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default BackToFunction;
