import React, { useEffect, useState } from "react";
import { TabsNavigation, Tab } from "pepsico-ds";
import UploadTabSection from "./UploadTabSection";
import { useSelector } from "react-redux";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import Loading from "../../utils/Loader";
export default function UploadPendingApproval({ search }) {
  const [tab, setTab] = useState(0);
  const handleClick = (index) => setTab(index);
  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [videos, setVideos] = useState([]);
  const [links, setLinks] = useState([]);
  const isDarkTheme = useSelector((state) => state.theme);
  useEffect(() => {
    setLoading(true);
    fetchHistory().then((data) => {
      if (data?.status === 200) {
        setDocuments(data?.data?.documents);
        setRow(data?.data);
        setTotalData([
          ...data?.data?.documents,
          ...data?.data?.videos,
          ...data?.data?.links,
        ]);
      }
      setVideos(data?.data?.videos);
      setLinks(data?.data?.links);
    });
  }, []);
  const fetchHistory = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/user-upload/pending-content-list?userId=ADMIN`
      );
      return response;
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <Loading />;
  return (
    <div className="flex flex-col gap-6">
      <TabsNavigation selectedIndex={tab} isDark={isDarkTheme}>
        <Tab
          iconTrailing={false}
          label={`All (${totalData?.length ? totalData?.length : 0}) `}
          onClick={() => handleClick(0)}
          orientation="horizontal"
          isDark={isDarkTheme}
        />
        <Tab
          iconTrailing={false}
          label={`Documents (${row?.documentCount ? row?.documentCount : 0}) `}
          onClick={() => handleClick(1)}
          orientation="horizontal"
          isDark={isDarkTheme}
        />
        <Tab
          label={`Videos (${row?.videoCount ? row?.videoCount : 0})`}
          iconTrailing={false}
          onClick={() => handleClick(2)}
          isDark={isDarkTheme}
        />
        <Tab
          label={`Links (${row?.linkCount ? row?.linkCount : 0})`}
          iconTrailing={false}
          onClick={() => handleClick(3)}
          isDark={isDarkTheme}
        />
      </TabsNavigation>
      {tab === 0 && <UploadTabSection data={totalData} search={search} />}
      {tab === 1 && <UploadTabSection data={documents} search={search} />}
      {tab === 2 && <UploadTabSection data={videos} search={search} />}
      {tab === 3 && <UploadTabSection data={links} search={search} />}
    </div>
  );
}
