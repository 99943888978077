const getTextFieldStyles = (
  isMobile,
  isDarkTheme,
  darkTheme,
  width = "338px"
) => ({
  width: isMobile ? "100%" : width, // Adjust width for mobile/desktop
  backgroundColor: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : "white", // Background color for dark mode
  "& .MuiInputBase-root": {
    color: isDarkTheme ? "white " : "black", // Input text color
    height: "32px",  // Height for desktop
  },
  "& .MuiOutlinedInput-root": {
    border: isDarkTheme ? "1px solid white" : " ", // Border color
  },
  "& .MuiSvgIcon-root": {
    color: isDarkTheme ? "white" : "black", // Icon color
  },
  "& .MuiInputBase-input::placeholder": {
    fontWeight: 400,
    fontSize: 14,
    color: "#A0A0A0",
  },
});

const datePickerStyles = (isDarkTheme, isMobile, darkTheme, commonStyles) => ({
  width: isMobile ? "100%" : "338px", // Adjust width for mobile/desktop
  backgroundColor: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : "white", // Background color for dark mode
  "& .MuiInputBase-root": {
    color: isDarkTheme ? "white" : "black", // Input text color
    height: "32px",  // Height for desktop
  },
  "& .MuiOutlinedInput-root": {
    border: isDarkTheme ? "1px solid white" : " ", // Border color
  },
  "& .MuiSvgIcon-root": {
    color: isDarkTheme ? "white" : " ", // Icon color
  },
});

const autoCompletStyles = (
  isDarkTheme,
  isMobile,
  darkTheme,
  width = "338px"
) => ({
  width: isMobile ? "100%" : width,
  backgroundColor: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : "white",

  "& .MuiOutlinedInput-root": {
    border: isDarkTheme ? "1px solid white" : " ",
    height: "32px",
    "&:hover": {
      borderColor: isDarkTheme ? "white" : " ",
    },
  },
  "& .MuiInputBase-input": {
    color: isDarkTheme ? "white" : " ",
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: isDarkTheme ? "white" : " ", // Change dropdown icon color
  },
});

export { getTextFieldStyles, datePickerStyles, autoCompletStyles };
