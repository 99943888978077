import React from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Typography } from "@mui/material";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { boxStyles, labelStyles, contentStyles } from "./gridStyle";

export default function GridSection({ accordionContent }) {
  const isDarkTheme = useSelector((state) => state.theme);

  const renderGridItem = (label, content) => (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Box sx={boxStyles(isDarkTheme ? darkTheme : lightTheme)}>
        <Typography sx={labelStyles(isDarkTheme)}>{label}</Typography>
        <Typography sx={contentStyles(isDarkTheme)}>{content}</Typography>
      </Box>
    </Grid>
  );

  return (
    <div className="flex flex-row gap-4 flex-wrap w-full">
      <Grid container spacing={2}>
        {accordionContent?.map(({ label, content }) =>
          renderGridItem(label, content)
        )}
      </Grid>
    </div>
  );
}
