import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  search: "",
  sector: "",
  country: "",
  variety: "",
  flashColor: "",
  ipOwnerShip: "",
  favourite: 1,
};

const internalPageSlice = createSlice({
  name: "internalPage",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSector: (state, action) => {
      state.sector = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setVariety: (state, action) => {
      state.variety = action.payload;
    },
    setFavourite: (state, action) => {
      state.favourite = action.payload;
    },
    setFlashColor: (state, action) => {
      state.flashColor = action.payload;
    },
    setIpOwnerShip: (state, action) => {
      state.ipOwnerShip = action.payload;
    },
  },
});
export const {
  setSearch,
  setSector,
  setCountry,
  setVariety,
  setFavourite,
  setFlashColor,
  setIpOwnerShip,
} = internalPageSlice.actions;
export default internalPageSlice.reducer;
