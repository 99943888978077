import { Box } from "@mui/material";
import { Filter } from "pepsico-ds";
import React from "react";
import { getDropDownOptionValuesForCuratorFilters } from "../../utils/function";
import { useSelector } from "react-redux";
import { CURATOR_PAGE_API_CONSTANT } from "../../constant/constant";
import "./CustomCuratorFilter.css";

const CustomCuratorFilter = ({
  pageName,
  filters,
  updatedFilterValue,
  filterValue,
}) => {
  const isDarkTheme = useSelector((state) => state.theme);

  const updateParentFilterValue = (value, filterType) => {
    if (filterType === "subject") {
      updatedFilterValue(value?.label, "subject");
    }

    if (filterType === "sector") {
      updatedFilterValue(value?.label, "sector");
    }

    if (filterType === "country") {
      updatedFilterValue(value?.label, "country");
    }
    if (filterType === "contentType") {
      updatedFilterValue(value?.label, "contentType");
    }
    if (filterType === "visibility") {
      updatedFilterValue(value?.label, "visibility");
    }
    if (filterType === "contentLevel") {
      updatedFilterValue(value?.label, "contentLevel");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      {(pageName === CURATOR_PAGE_API_CONSTANT.pending_approval ||
        pageName === CURATOR_PAGE_API_CONSTANT.yearly_review) && (
        <>
          <Filter
            label="Subjects"
            onChange={(e) => updateParentFilterValue(e, "subject")}
            value={filterValue?.subjects}
            options={getDropDownOptionValuesForCuratorFilters(
              filters?.subjects
            )}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            label="Sector"
            onChange={(e) => updateParentFilterValue(e, "sector")}
            value={filterValue?.sector}
            options={getDropDownOptionValuesForCuratorFilters(filters?.sectors)}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            label="Country"
            onChange={(e) => updateParentFilterValue(e, "country")}
            value={filterValue?.country}
            options={getDropDownOptionValuesForCuratorFilters(
              filters?.countries
            )}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
        </>
      )}
      {pageName === CURATOR_PAGE_API_CONSTANT.yearly_review && (
        <Filter
          label="Period"
          onChange={(e) => updateParentFilterValue(e, "country")}
          value={filterValue?.country}
          options={getDropDownOptionValuesForCuratorFilters(filters?.period)}
          selection="single"
          size="medium"
          isDark={isDarkTheme}
        />
      )}
      {(pageName === CURATOR_PAGE_API_CONSTANT.artificat_history ||
        pageName === CURATOR_PAGE_API_CONSTANT.denied_history) && (
        <>
          <Filter
            className="curator-articats-history"
            label="Content type"
            onChange={(e) => updateParentFilterValue(e, "contentType")}
            value={filterValue?.subjects}
            options={getDropDownOptionValuesForCuratorFilters(
              filters?.contentType
            )}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            className="curator-articats-history"
            label="Visibility"
            onChange={(e) => updateParentFilterValue(e, "visibility")}
            value={filterValue?.sector}
            options={getDropDownOptionValuesForCuratorFilters(
              filters?.visibility
            )}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            className="curator-articats-history"
            label="Content level"
            onChange={(e) => updateParentFilterValue(e, "contentLevel")}
            value={filterValue?.country}
            options={getDropDownOptionValuesForCuratorFilters(
              filters?.contentLevel
            )}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
        </>
      )}
    </Box>
  );
};

export default CustomCuratorFilter;
