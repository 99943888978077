import React, { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  titleTypographyStyles,
  descriptionTypographyStyles,
  labelTypographyStyles,
  valueTypographyStyles,
  flexBoxStyles,
} from "./cardStyle";
import { Button } from "pepsico-ds";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { darkTheme } from "../../../../theme/Theme";
import LikeButton from "../../LikeButton";

export default function MainBodyCard({ card }) {
  const [updatedLikes, setUpdatedLikes] = useState(card?.likes || 0);
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  const handleDetails = (id) => {
    navigate(`/details-request-information/${id}`);
  };

  const handleLikesUpdated = (newLikes) => {
    setUpdatedLikes(newLikes);
  };

  return (
    <Box
      sx={{
        borderBottom: "1px solid #D7E5F8",
        display: "flex",
        padding: "16px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        alignSelf: "stretch",
        background: isDarkTheme ? darkTheme.backgroundColorLayer3 : "",
      }}
    >
      <Typography
        sx={{
          ...titleTypographyStyles,
          color: isDarkTheme ? "white" : "#27251F",
        }}
      >
        {card?.title}
      </Typography>
      <Typography
        sx={{
          ...descriptionTypographyStyles,
          color: isDarkTheme ? "white" : "#27251F",
        }}
      >
        {card?.description}
      </Typography>

      <Box
        sx={
          isMobile
            ? { display: "flex", flexDirection: "column" }
            : flexBoxStyles
        }
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              ...labelTypographyStyles,
              color: isDarkTheme ? "white" : "#27251F",
            }}
          >
            Requested Date:
          </Typography>
          <Typography
            sx={{
              ...valueTypographyStyles,
              color: isDarkTheme ? "white" : "#27251F",
            }}
          >
            {card?.requestedDate}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              ...labelTypographyStyles,
              color: isDarkTheme ? "white" : "#27251F",
            }}
          >
            Requested by:
          </Typography>
          <Typography
            sx={{
              ...valueTypographyStyles,
              color: isDarkTheme ? "white" : "#27251F",
            }}
          >
            {card?.displayName}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              ...labelTypographyStyles,
              color: isDarkTheme ? "white" : "#27251F",
            }}
          >
            View Counts:
          </Typography>
          <Typography
            sx={{
              ...valueTypographyStyles,
              color: isDarkTheme ? "white" : "#27251F",
            }}
          >
            {card?.views}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <LikeButton
            cardId={card?.id}
            initialLiked={card?.liked}
            onLikesUpdated={handleLikesUpdated}
          />
          <Typography
            sx={{
              ...valueTypographyStyles,
              color: isDarkTheme ? "white" : "#27251F",
            }}
          >
            Likes: {updatedLikes} {/* Show the updated likes */}
          </Typography>
        </Box>
        <Button
          variant="secondary"
          text="View details"
          size="medium"
          iconTrailing="arrow_forward"
          onClick={() => handleDetails(card?.id)}
        />
      </Box>
    </Box>
  );
}

// PropTypes validation
MainBodyCard.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    requestedDate: PropTypes.string,
    displayName: PropTypes.string,
    views: PropTypes.number,
    likes: PropTypes.number, // Optional likes count
    liked: PropTypes.bool, // Optional like status
    id: PropTypes.string.isRequired, // ID of the card
  }).isRequired, // Ensure card is required and follows the shape
};
