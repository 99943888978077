// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  select: "Pending Approvals",
};
export const uploadHistorySlice = createSlice({
  name: "uploadHistory",
  initialState,
  reducers: {
    setSelectedPage: (state, action) => {
      state.select = action.payload;
    },
  },
});

export const { setSelectedPage } = uploadHistorySlice.actions;

export default uploadHistorySlice.reducer;
