import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Button } from "pepsico-ds";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
export default function RequestInformationHeader() {
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleAddRequest = () => {
    navigate("/add-request-information");
  };
  return (
    <Box
      data-testid="request-info-box"
      sx={{
        display: "flex",
        width: "100%", // Set the width of the container
        padding: isMobile ? "8px 16px" : "32px 0px", // Top and bottom padding: 32px, left and right: 0px
        justifyContent: isMobile ? "centre" : "space-between", // Distribute items with space between them
        alignItems: "flex-start", // Align items at the start of the container
        flexDirection: isMobile ? "column" : "",
        gap: isMobile ? "8px" : "",
      }}
    >
      {/* Add your content here */}
      <Typography
        sx={{
          color: isDarkTheme ? "white" : "var(--Tokens-Text-Primary, #3A3A3A)", // Text color
          fontFeatureSettings: "'liga' off, 'clig' off", // Disable ligatures and contextual ligatures
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)", // Font family
          fontSize: "var(--Font-Size-H4, 28px)", // Font size
          fontStyle: "normal", // Normal font style
          fontWeight: 700, // Font weight
          lineHeight: "var(--Line-Height-H4, 40px)", // Line height
          letterSpacing: "1px", // Letter spacing
        }}
      >
        Request for information
      </Typography>
      <Box
        sx={{
          display: "flex",
          marginY: "auto",
        }}
      >
        <Button
          size="large"
          text="Add new request"
          variant="primary"
          onClick={handleAddRequest}
        />
      </Box>
    </Box>
  );
}
