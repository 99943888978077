import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import {
  Body,
  CardWrapper,
  Container,
  HeaderBox,
  HeaderTitle,
} from "../Components/curatorApprovalStyle";
import { artifactManagementData } from "../constants";
import { setSelectedCuratorPage } from "../../../slices/curatorPageSlice";
import ArtifactManagement from "../Components/ArtifactManagement";
import ContentDetails from "../Components/ContentDetails";
import BackToFunction from "../Components/BackToFunction";
import CropType from "../Components/CropType";
import Approval from "../Components/Approval";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast/toast";
import SummarySection from "../Components/SummarySection";
import {
  getSelectedPendingApprivalData,
  submitPendingApprovalRequest,
  denyPendingApprovalRequest,
  downloadFileToLocal,
  uploadYearlyReviewArtificatsFiles,
  deleteCuratorFile,
} from "../../../API/curatorApi";
import Footer from "../Components/Footer";
import CuratorApprovalModal from "../CuratorApprovalModal";
import { useNavigate, useParams } from "react-router-dom";
import { setUser } from "../../../slices/userSlice";
import useThemeRender from "../../../theme/useThemeRender";
import Loading from "../../../utils/Loader";

const ModifyPendingApproval = () => {
  const { register, formState } = useForm({
    defaultValues: {
      contentTitle: "",
      specialProject: "",
      versionNumber: "",
    },
  });
  const navigate = useNavigate();
  const queryParams = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isDarkTheme = useSelector((state) => state.theme);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contentId, setContentId] = useState(null);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState(null);
  const [isDenyOperationType, setIsDenyOperationType] = useState(false);
  const editorRef = useRef(null);
  const { errors } = formState;
  const { oktaAuth } = useOktaAuth();
  const curatorPageLookUpData = useSelector(
    (store) => store?.curatorPageLookUpData
  );
  const [lookUpOptions, setLookupOptions] = useState({
    specialProjects: [],
    subjects: [],
    sectors: [],
    countries: [],
    language: [],
    subjectMatterExpert: [],
    disclaimer: [],
    cropType: [],
    visibility: [],
    contentLevel: [],
  });
  const [contentDetailsSectionData, setContentDetailsSectionData] = useState({
    contentTitle: "",
    visibility: "",
    contentLevel: "",
    specialProjects: [],
    subjects: [],
    sectors: [],
    countries: [],
    language: "",
    subjectMatterExpert: "",
  });
  const [cropTypeSectionData, setCropTypeData] = useState({
    keyWordsText: "",
    cropType: "",
  });
  const [approvalSection, setApprovalSection] = useState({
    legalApproval: null,
    dropImage: null,
    uploadedImageId: null,
  });
  const [summarySectionData, setSummarySectionData] = useState({
    documentNotes: "",
    summary: "",
    disclaimerDetails: "",
    isTranslation: false,
    translationLanguage: "",
    translatedText: "",
    disclaimer: "",
  });
  const [artifactManagementArray, setArtifactManagementArray] = useState([
    artifactManagementData,
  ]);

  useEffect(() => {
    getSelectedPendingApprivalData(queryParams?.id)
      .then((response) => {
        setContentDetailsSectionData({
          contentTitle: response?.title,
          visibility: response?.visibility,
          contentLevel:response?.level,
          specialProjects: response?.specialProjects,
          subjects: response?.subject,
          sectors: response?.sector,
          countries: response?.country,
          language: response?.language === null ? "" : response?.language,
          subjectMatterExpert: response?.sme === null ? "" : response?.sme,
        });
        setCropTypeData({
          keyWordsText: response?.keywords,
          cropType: response?.cropType,
        });
        setApprovalSection({
          legalApproval: response?.isLegalReview,
          dropImage: response?.document,
        });
        setSummarySectionData({
          documentNotes: response?.documentNotes,
          summary: response?.summary,
          disclaimerDetails: response?.disclaimerDetails,
          translatedText: response?.translatedText,
          translationLanguage: response?.translationLanguage,
          disclaimer: response?.disclaimer,
        });
        setArtifactManagementArray(response?.artifactList);
        setContentId(response?.contentId);
        setIsPageLoading(false);
      })
      .catch((error) => {
        console.log("Get Approval API Error:", error?.message);
        showErrorToast("Get Approval API Error:", error?.message);
        setIsPageLoading(false);
      });
    setLookupOptions({
      sectors: curatorPageLookUpData?.sectors,
      specialProjects: curatorPageLookUpData?.specialProjects,
      subjectMatterExpert: curatorPageLookUpData?.subjectMatterExpert,
      subjects: curatorPageLookUpData?.subjects,
      countries: curatorPageLookUpData?.countries,
      language: curatorPageLookUpData?.language,
      cropType: curatorPageLookUpData?.cropType,
      disclaimer: curatorPageLookUpData?.disclaimers,
      visibility: curatorPageLookUpData?.visibility,
      contentLevel: curatorPageLookUpData?.contentLevel,
    });
  }, []);

  useEffect(() => {
    oktaAuth.getUser().then((info) => {
      setLoggedInUserDetail(info);
      dispatch(setUser(info));
    });
  }, [oktaAuth]);

  const uploadArtificatsFiles = (file, isLegalDoc = false) => {
    setIsPageLoading(true);
    console.log("uploadArtificatsFiles", file, isLegalDoc);

    uploadYearlyReviewArtificatsFiles(file, isLegalDoc)
      .then((data) => {
        console.log("file data", data);
        if (isLegalDoc) {
          setApprovalSection((prevState) => {
            return {
              ...prevState,
              uploadedImageId: data?.fileId,
            };
          });
        }
        showSuccessToast("File uploaded Successfully");
        setIsPageLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        showErrorToast("Something went wrong in file upload");
        setIsPageLoading(false);
      });
  };
  const deleteArtifcateFile = (file) => {
    setIsPageLoading(true);
    console.log("deleteArtifcateFile", file);

    deleteCuratorFile(file)
      .then((data) => {
        console.log("file data", data);
        showSuccessToast("File deleted Successfully");
        setIsPageLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        showErrorToast("Something went wrong in file upload");
        setIsPageLoading(false);
      });
  };

  const downloadFile = (fileId, fileName) => {
    setIsPageLoading(true);
    console.log("fileURL", fileId);
    downloadFileToLocal(fileId, fileName)
      .then((response) => {
        console.log("response", response);
        if (response?.status === 200) {
          showSuccessToast("File downloaded successfully");
        } else {
          showErrorToast("Something went wrong while downloading file");
        }
        setIsPageLoading(false);
      })
      .catch((error) => {
        console.log("error in file download", error);
        setIsPageLoading(false);
        showErrorToast("Something went wrong while downloading file");
      });
  };

  const setApprovalDropzoneDisabled = () => {
    console.log(
      "approvalSection?.legalApproval",
      approvalSection?.legalApproval
    );
    if (
      approvalSection?.legalApproval === 1 ||
      approvalSection?.legalApproval === 3 ||
      approvalSection?.legalApproval === false
    ) {
      return true;
    }

    return false;
  };

  const handleNavigation = () => navigate("/curator");

  const onConfirm = {
    label: "Approve",
    handConfirmEvent: () => {
      setIsDenyOperationType(false);
      setIsModalVisible(true);
    },
  };

  const onCancel = {
    label: "Decline",
    handleCancelEvent: () => {
      setIsModalVisible(true);
      setIsDenyOperationType(true);
    },
  };

  const makePendingApprovalApiCall = async (
    opetationType,
    explanation = ""
  ) => {
    setIsModalVisible(false);
    setIsPageLoading(true);
    const apiInputPayLoad = {
      contentId: contentId,
      contentGuid: queryParams?.id,
      title: contentDetailsSectionData?.contentTitle,
      visibility: contentDetailsSectionData?.visibility,
      specialProjects: contentDetailsSectionData?.specialProjects,
      subjects: contentDetailsSectionData?.subjects,
      sectors: contentDetailsSectionData?.sectors,
      countries: contentDetailsSectionData?.countries,
      language: contentDetailsSectionData?.language,
      subjectMatterExpert: contentDetailsSectionData?.subjectMatterExpert,
      contentLevel: contentDetailsSectionData?.contentLevel,
      cropType: cropTypeSectionData?.cropType,
      keywords: cropTypeSectionData?.keyWordsText,
      legalReview: approvalSection?.legalApproval,
      document: "",
      documentNotes: summarySectionData?.documentNotes,
      originalFileId: approvalSection?.uploadedImageId,
      summary: summarySectionData?.summary,
      disclaimer: summarySectionData?.disclaimer,
      disclaimerDetail: summarySectionData?.disclaimerDetails,
      isTranslation:
        summarySectionData?.isTranslation === undefined
          ? false
          : summarySectionData?.isTranslation == "Yes"
          ? true
          : false,
      translationLanguage: summarySectionData?.translationLanguage
        ? summarySectionData?.translationLanguage
        : "",
      translatedText: summarySectionData?.translatedText,
      rejectedExplanation: " ",
      reviewedBy: `${loggedInUserDetail?.FirstName} ${loggedInUserDetail?.LastName}`,
    };

    console.log("Making the form data", {
      contentDetailsSectionData,
      cropTypeSectionData,
      approvalSection,
      summarySectionData,
      artifactManagementArray,
      opetationType,
      apiInputPayLoad,
    });
    const notificationTitle =  apiInputPayLoad?.title === null ||  apiInputPayLoad?.title === undefined ? 'Content' : apiInputPayLoad?.title;
    if (opetationType === "deny") {
      apiInputPayLoad.rejectedExplanation = explanation;

      denyPendingApprovalRequest(apiInputPayLoad)
        .then((response) => {
          console.log("denyPendingApprovalRequest submits", response);
          setIsModalVisible(true);
          if (response?.status !== 200) {
            showErrorToast(
              `Opps! Something went wrong:${response?.data?.message}`
            );
          } else {
            showSuccessToast(`${notificationTitle} denined successfully!`);
            dispatch(setSelectedCuratorPage("Pending approvals"));
            navigate("/curator");
          }
        })
        .catch((error) => {
          setIsModalVisible(true);
          showErrorToast(`Opps! Something went wrong:${error?.message}`);
          console.log(error);
        });
    } else {
      submitPendingApprovalRequest(apiInputPayLoad)
        .then((response) => {
          console.log("submitPendingApprovalRequest submits", response);
          setIsModalVisible(true);
          if (response?.status !== 200) {
            showErrorToast(`Opps! Something went wrong:${response?.message}`);
            setIsModalVisible(false);
          } else {
            showSuccessToast(
              `${notificationTitle} approved successfully!`
            );
            dispatch(setSelectedCuratorPage("Pending approvals"));
            navigate("/curator");
          }
        })
        .catch((error) => {
          setIsModalVisible(true);
          showErrorToast(`Opps! Something went wrong:${error?.message}`);
          console.log(error);
        });
    }
  };

  return (
    <Container>
      <Body>
        <BackToFunction
          label={"Back to pending approvals"}
          handleBack={handleNavigation}
        />
        {isPageLoading && <Loading />}
        <CardWrapper
          sx={{ background: useThemeRender("cardBackgroundColorLayer1") }}
        >
          <ContentDetails
            errors={errors}
            register={register}
            setContentDetailsSectionData={setContentDetailsSectionData}
            contentDetailsSectionData={contentDetailsSectionData}
            lookUpOptions={lookUpOptions}
          />

          <CropType
            cropTypeOptions={lookUpOptions?.cropType}
            cropTypeSectionData={cropTypeSectionData}
            setCropTypeData={setCropTypeData}
          />

          <Approval
            isDarkTheme={isDarkTheme}
            isDisabled={setApprovalDropzoneDisabled()}
            approvalSection={approvalSection}
            setApprovalSection={setApprovalSection}
            uploadArtificatsFiles={uploadArtificatsFiles}
            deleteArtifcateFile={deleteArtifcateFile}
          />

          <SummarySection
            editorRef={editorRef}
            summarySectionData={summarySectionData}
            setSummarySectionData={setSummarySectionData}
            lookUpOptions={lookUpOptions}
          />

          <HeaderBox isMobile={isMobile}>
            <HeaderTitle>Artifact management</HeaderTitle>
          </HeaderBox>

          <ArtifactManagement
            register={register}
            artifactManagementArray={artifactManagementArray}
            setArtifactManagementArray={setArtifactManagementArray}
            downloadFile={downloadFile}
          />
        </CardWrapper>
      </Body>

      <Footer
        onCancel={onCancel}
        onConfirm={onConfirm}
        btnStyle={{ background: "#D40020", flex: isMobile && 1 }}
      />

      {isModalVisible && (
        <CuratorApprovalModal
          user={loggedInUserDetail}
          approveModal={isModalVisible}
          isDeny={isDenyOperationType}
          setIsModalVisible={setIsModalVisible}
          makePendingApprovalApiCall={makePendingApprovalApiCall}
        />
      )}
    </Container>
  );
};

export default ModifyPendingApproval;
