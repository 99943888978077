import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  Autocomplete,
  useMediaQuery,
  TextField,
} from "@mui/material";

import commonStyles from "../../../Landing/addVariteyDrawerStyle";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  openCountryDrawer,
  resetFiles,
  setCountryEdit,
} from "../../../../../../slices/potatoProtectionSlice";
import Footer from "./Footer";
import FileUpload from "./FileUpload";
import DatePickerField from "../../../../../../utils/Fields/DatePickerField";
import Header from "./Header";
import TextInputField from "../../../../../../utils/Fields/TextInputField";
import DateFormat from "../../../../../../utils/DateFormat";
import axios from "axios";
import { useParams } from "react-router-dom";
import { darkTheme, lightTheme } from "../../../../../../theme/Theme";
import { autoCompletStyles } from "../../../../../../utils/FieldStyles/FieldStyles";
import dayjs from "dayjs";
import { BASE_URL } from "../../../../../../constant/apiUrl";
import { fetchVariety } from "../../../../../../slices/potatoProtectionSlice";
import validateDate from "./Validations";
import { postAPI } from "../../../../../../API/ApiService";
import { getAPI } from "../../../../../../API/ApiService";
import { showErrorToast } from "../../../../../../utils/Toast/toast";
export default function EditCountryDrawer({ data }) {
  const isDarkTheme = useSelector((state) => state.theme);
  const [select, setSelected] = useState("");
  const [editData, setEditData] = useState("");
  const { isCountryOpen, isCountryEdit, files } = useSelector(
    (store) => store.protectionPotato
  );
  const [country, setCountryData] = useState([]);
  const token = localStorage.getItem("auth_token");
  const { register, handleSubmit, formState, reset, control, setError } =
    useForm({
      defaultValues: {
        country: "",
        pbrRequestDate: null,
        pbrGrantDate: null,
        pbrNumber: "",
        registrationDate: null,
        registrationNumber: "",
        pvpRequestDate: null,
        pvpNumber: "",
        pvpGrantDate: null,
        patentFileDate: null,
        patentGrantDate: null,
        patentNumber: "",
      },
    });
  const { id } = useParams();
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=COUNTRY`
        );
        const fecthData = response?.data;
        const data = fecthData.map((item) => item.name);
        dispatch(resetFiles());
        setCountryData(data);
      } catch (err) {
        // setError(err.message);
      } finally {
        // setLoading(false);
      }
    };

    fetchCountryData();
  }, []);
  useEffect(() => {
    // Populate data based on the current selection.
    if (isCountryEdit) {
      const populateData = data?.filter((item) => item.country === select);

      // Even if the same country is selected, update editData with a deep comparison.
      if (
        populateData.length &&
        JSON.stringify(editData) !== JSON.stringify(populateData[0])
      ) {
        setEditData(populateData[0]);
      }
    }
  }, [select, data, editData, isCountryEdit]);
  console.log(files, "---------");

  useEffect(() => {
    if (isCountryOpen) {
      // Reset form and selection when the drawer opens.
      reset({
        country: "",
        pbrRequestDate: null,
        pbrGrantDate: null,
        pbrNumber: "",
        registrationDate: null,
        registrationNumber: "",
        pvpRequestDate: null,
        pvpNumber: "",
        pvpGrantDate: null,
        patentFileDate: null,
        patentGrantDate: null,
        patentNumber: "",
      });
      setEditData(null); // Clear previous data
      setSelected(""); // Clear selected country
    }
  }, [isCountryOpen, reset]);

  useEffect(() => {
    if (editData) {
      reset({
        country: editData.country || "",
        pbrRequestDate: editData.pbrRequestDate
          ? dayjs(editData.pbrRequestDate)
          : null,
        pbrGrantDate: editData.pbrGrantDate
          ? dayjs(editData.pbrGrantDate)
          : null,
        pbrNumber: editData.pbrNumber || null,
        registrationDate: editData.registrationDate
          ? dayjs(editData.registrationDate)
          : null,
        registrationNumber: editData.registrationNumber || null,
        pvpRequestDate: editData.pvpRequestDate
          ? dayjs(editData.pvpRequestDate)
          : null,
        pvpNumber: editData.pvpNumber || null,
        pvpGrantDate: editData.pvpGrantDate
          ? dayjs(editData.pvpGrantDate)
          : null,
        patentFileDate: editData.patentFileDate
          ? dayjs(editData.patentFileDate)
          : null,
        patentGrantDate: editData.patentGrantDate
          ? dayjs(editData.patentGrantDate)
          : null,
        patentNumber: editData.patentNumber || null,
      });
    }
  }, [editData, reset]);
  // Use `JSON.stringify(variety)` to compare based on content

  const { errors } = formState;
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    console.log(data, files, "--------");

    // Format all date fields
    const formattedDates = {
      pbrRequestDate: DateFormat(data.pbrRequestDate),
      registrationDate: DateFormat(data.registrationDate),
      pbrGrantDate: DateFormat(data.pbrGrantDate),
      pvpRequestDate: DateFormat(data.pvpRequestDate),
      patentFileDate: DateFormat(data.patentFileDate),
      patentGrantDate: DateFormat(data.patentGrantDate),
      pvpGrantDate: DateFormat(data.pvpGrantDate),
    };

    // Merge formatted dates with original data
    // const hasError = validateDates(formattedDates, setError);

    const validations = [
      validateDate(
        data.pbrRequestDate,
        data.pbrGrantDate,
        "pbrRequestDate",
        "pbrGrantDate",
        setError
      ),
      validateDate(
        data.patentFileDate,
        data.patentGrantDate,
        "patentFileDate",
        "patentGrantDate",
        setError
      ),
      validateDate(
        data.pvpRequestDate,
        data.pvpGrantDate,
        "pvpRequestDate",
        "pvpGrantDate",
        setError
      ),
    ];

    // Check if any validation failed
    if (validations.includes(false)) {
      return; // Do not proceed if there are validation errors
    }
    const newData = { ...data, ...formattedDates };

    // Create FormData and append files
    const formData = new FormData();
    console.log(files, "----sad-----");

    files?.forEach((file, index) => {
      formData.append(`file`, file); // Append each file with unique keys
    });
    console.log(newData, "-1-------------");

    if (isCountryEdit) {
      try {
        // Send the FormData payload
        const result = await axios.put(
          `${BASE_URL}/country-detail/variety/${id}/country/${editData?.id}`,
          newData,

          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if (result?.status === 200) {
          HandleClose();
          dispatch(fetchVariety(true));
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      try {
        let fileIds = [];

        // Check if formData has files
        if (files.length > 0) {
          const response = await axios.post(
            `https://flag-dev-c5gkcedqchceesa8.eastus-01.azurewebsites.net/fritolay/file-manager/upload?user=ADMIN`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response?.status === 200) {
            fileIds = [response?.data?.fileId];
          }
        }

        // Prepare data for the second API call
        const data1 = { ...newData, fileIds };

        const result = await postAPI(
          `${BASE_URL}/country-detail/variety/${id}`,
          data1,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );

        if (result?.status === 201) {
          HandleClose();
          dispatch(fetchVariety(true));
        }

        if (result?.status === 400) {
          showErrorToast("Country Name Cannot Be Repeated");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const onReset = () => {
    reset();
  };

  const HandleClose = () => {
    onReset();
    dispatch(openCountryDrawer(false));
    setEditData(" ");
    if (isCountryEdit === true) {
      dispatch(setCountryEdit(false));
    }
  };

  const options = useMemo(() => {
    if (isCountryEdit) {
      return data?.map((item) => {
        return item?.country;
      });
    } else {
      const options = country;
      return options;
    }
  }, [data, isCountryEdit, country]);

  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={isCountryOpen}
      PaperProps={{
        sx: {
          height: isMobile ? "80%" : "100%",
          width: isMobile ? "100%" : 436,
          padding: isMobile ? "16px 16px" : "16px 24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer3
            : lightTheme.backgroundColorLayer3,
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: isDarkTheme
              ? darkTheme.drawerOverlayColorLayer1
              : lightTheme.drawerOverlayColorLayer1,
          },
        },
      }}
    >
      <Header onClose={HandleClose} />

      <form
        className="w-full flex flex-col gap-3 h-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            ...commonStyles.container,
            flex: 1,
            padding: "var(--Spacing-spacing-16, 16px)",
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer1
              : lightTheme.backgroundColorLayer1,
          }}
        >
          {/* Variety Input */}
          <Box sx={commonStyles.container}>
            <Typography
              sx={{
                ...commonStyles.typographyLabel,
                color: isDarkTheme && darkTheme.textColorLayer1,
              }}
            >
              Country name
            </Typography>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field} // Spreads the field props
                  options={options}
                  getOptionLabel={(option) => option}
                  value={field.value || ""} // Value connected to the form's state
                  onChange={(e, newValue) => {
                    field.onChange(newValue); // Update form state
                    setSelected(newValue);
                  }}
                  sx={autoCompletStyles(
                    isDarkTheme,
                    isMobile,
                    darkTheme,
                    commonStyles
                  )}
                  ListboxProps={{
                    sx: {
                      backgroundColor: isDarkTheme
                        ? darkTheme.backgroundColorLayer1
                        : "white",
                      "& .MuiAutocomplete-option": {
                        color: isDarkTheme ? "white" : "black",
                        "&:hover": {
                          backgroundColor: isDarkTheme ? "#333" : "#f0f0f0",
                        },
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      error={!!errors.country}
                      required
                      helperText={errors.country ? errors.country.message : ""}
                    />
                  )}
                />
              )}
            />
          </Box>
          <DatePickerField
            label="PBR request date"
            name="pbrRequestDate"
            control={control}
            errors={errors}
            isMobile={isMobile}
          />
          {/* Date Picker */}
          <DatePickerField
            label="PBR Grant date"
            name="pbrGrantDate"
            control={control}
            errors={errors}
            isMobile={isMobile}
          />

          <TextInputField
            label="PBR number"
            isMobile={isMobile}
            errors={errors}
            register={register}
            name="pbrNumber"
            type="number"
          />
          {/* Country Autocomplete */}
          <DatePickerField
            label="Registration date"
            name="registrationDate"
            control={control}
            errors={errors}
            isMobile={isMobile}
          />
          <TextInputField
            label="Registeration number"
            isMobile={isMobile}
            errors={errors}
            register={register}
            name="registrationNumber"
            type="number"
          />

          {/* Comments Text Area */}
          <DatePickerField
            label="PVP request date"
            name="pvpRequestDate"
            control={control}
            errors={errors}
            isMobile={isMobile}
          />
          <DatePickerField
            label="PVP grant date"
            name="pvpGrantDate"
            control={control}
            errors={errors}
          />

          <TextInputField
            label="PVP number"
            isMobile={isMobile}
            errors={errors}
            register={register}
            name="pvpNumber"
            type="number"
          />
          <DatePickerField
            label="Patent file date"
            name="patentFileDate"
            control={control}
            errors={errors}
            isMobile={isMobile}
          />
          <DatePickerField
            label=" Patent grant date"
            name="patentGrantDate"
            control={control}
            errors={errors}
            isMobile={isMobile}
          />
          <TextInputField
            label="Patent number"
            isMobile={isMobile}
            errors={errors}
            register={register}
            name="patentNumber"
            type="number"
          />
          <FileUpload />
        </Box>

        <Footer onClose={HandleClose} />
      </form>
    </Drawer>
  );
}
