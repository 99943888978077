import {
  Box,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import useThemeRender from "../../../../theme/useThemeRender";
import "./SpecialProject.css";
import { useDispatch } from "react-redux";
import {
  setSpecialProjectDescription,
  setSpecialProjectStatus,
  setSpecialProjectTitle,
} from "../../../../slices/adminPageSlice";
function SpecialProjectInformation({ screen }) {
  const {
    specialProjectStatus,
    specialProjectTitle,
    specialProjectDescription,
  } = useSelector((store) => store.adminpage);
  const dispatch = useDispatch();
  const backgroundColorLayer1 = useThemeRender("backgroundColorLayer1");
  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");
  const handleChange = (event) => {
    let trimmedValue = event?.target?.value;
    trimmedValue = trimmedValue?.replace(/^\s+/, "");

    if (trimmedValue?.length > 0) {
      trimmedValue =
        trimmedValue?.charAt(0)?.toUpperCase() + trimmedValue?.slice(1);
    }
    dispatch(setSpecialProjectTitle(trimmedValue));
  };
  const handleDescriptionChange = (event) => {
    let trimmedValue = event?.target?.value;
    trimmedValue = trimmedValue?.replace(/^\s+/, "");

    if (trimmedValue?.length > 0) {
      trimmedValue =
        trimmedValue?.charAt(0)?.toUpperCase() + trimmedValue?.slice(1);
    }
    dispatch(setSpecialProjectDescription(trimmedValue));
  };
  const handleToggle = () => {
    dispatch(setSpecialProjectStatus(!specialProjectStatus));
  };
  return (
    <Box
      className="DetailCard cardStyle"
      sx={{
        background:
          screen === "add" ? backgroundColorLayer1 : backgroundColorLayer3,
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer1"),
        }}
        className="titleText"
        variant="h5"
      >
        Special project information
      </Typography>
      <Box>
        <Typography
          sx={{
            color: useThemeRender("textColorLayer2"),
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "Inter",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "32px",
          }}
          variant="h5"
        >
          Special project information
        </Typography>
        <TextField
          placeholder="Add Title"
          size="small"
          sx={{
            borderRadius: "4px",
            minWidth: "300px",
            width: "50%",
            background: useThemeRender("backgroundColorLayer1"),
            "& .MuiInputBase-input::placeholder": {
              color: useThemeRender("textColorLayer4"),
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: useThemeRender("borderColorLayer2"),
              },
              "&:hover fieldset": {
                borderColor: useThemeRender("borderColorLayer2"),
              },
              "&.Mui-focused fieldset": {
                borderColor: useThemeRender("borderColorLayer2"),
              },
            },
            "& .MuiInputBase-input": {
              color: useThemeRender("textColorLayer1"),
            },
          }}
          value={specialProjectTitle}
          onChange={handleChange}
        />
        <Box
          sx={{
            display: "flex",
            height: "32px",
            alignItems: "center",
            gap: "var(--Spacing-spacing-03, 8px)",
            mt: "5px",
            mb: "5px",
          }}
        >
          <Box
            sx={{
              color: useThemeRender("textColorLayer4"),
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-XSmall, 12px)",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "var(--Line-Height-XSmall, 18px)",
            }}
          >
            Special project is active
          </Box>
          <Switch checked={specialProjectStatus} onChange={handleToggle} />
        </Box>
        <Box
          sx={{
            color: useThemeRender("textColorLayer2"),
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Body, Inter)",
            fontSize: "var(--Font-Size-XSmall, 12px)",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "var(--Line-Height-XSmall, 18px)",
            mb: "8px",
          }}
        >
          Description
        </Box>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          placeholder="Type here"
          style={{
            padding: "5px",
            width: "100%",
            border: `1px solid ${useThemeRender("borderColorLayer2")}`,
            borderRadius: "3px",
            background: useThemeRender("backgroundColorLayer1"),
            color: useThemeRender("textColorLayer1"),
          }}
          onChange={handleDescriptionChange}
          value={specialProjectDescription}
        />
      </Box>
    </Box>
  );
}

export default SpecialProjectInformation;
