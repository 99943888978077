import React from "react";
import "./LoginHeader.css";
import LoginPepsiSvg from "./LoginPepsiSvg";

export default function LoginHeader() {
  return (
    <header className="header w-full">
      <div className="logo">
        <LoginPepsiSvg />
      </div>

      <div className="text-large-screen font-bold">
        <p>Frito Lay Agriculture</p>
      </div>

      <div className="text-small-screen font-bold">
        <p>Frito Lay Agriculture</p>
      </div>
    </header>
  );
}
