import React from 'react'
import SustainabilitySubpage from './SustainabilitySubpage';

function ClimateResientAgriculture() {

  return (
    <SustainabilitySubpage urlName={"Climate%20resilent%20agriculture"} header={"Climate reslient agriculture"} />
  )
}

export default ClimateResientAgriculture;