import React, { useState, useEffect, useRef } from "react";
import { Box, Drawer, List } from "@mui/material";
import MobileNavListItem from "./MobileNavListItem";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import PropTypes from "prop-types";

export default function MobileNav({ drawerOpen, handleDrawerClose }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const drawerRef = useRef(null);
  const isDarkTheme = useSelector((state) => state.theme);
  const handleItemClick = (item, parent) => {
    if (!parent) {
      handleDrawerClose();
    }

    setSelectedItem(item);
  };
  const { navigations } = useSelector((state) => state.nav);
  // Detect clicks outside the drawer content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        handleDrawerClose();
      }
    };

    if (drawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerOpen, handleDrawerClose]);

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={handleDrawerClose}
      disableScrollLock
      hideBackdrop
      ModalProps={{
        keepMounted: true, // Improve performance on mobile
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: 350,
          height: "calc(100vh - 56px)", // Adjust height to full screen minus header height (adjust 56px if your header height is different)
          top: "56px", // Position it below the header (adjust 56px based on your header height)
          position: "fixed", // Keeps the drawer fixed on the screen
          overflow: "auto", // Ensures content inside the drawer can scroll if necessary
          boxShadow: "none", // No shadow
          backgroundColor: isDarkTheme
            ? darkTheme.backgroundColorLayer3
            : lightTheme.backgroundColorLayer3,
        },
      }}
    >
      <Box
        ref={drawerRef}
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer3
            : lightTheme.backgroundColorLayer3,
        }}
      >
        <main style={{ flex: 1 }}>
          <List>
            {navigations.map((item, index) => (
              <MobileNavListItem
                key={item.navigationId}
                item={item}
                selectedItem={selectedItem}
                handleItemClick={handleItemClick}
                handleDrawerClose={handleDrawerClose}
              />
            ))}
          </List>
        </main>
      </Box>
    </Drawer>
  );
}

MobileNav.propTypes = {
  drawerOpen: PropTypes.bool.isRequired, // Ensures drawerOpen is a boolean and required
  handleDrawerClose: PropTypes.func.isRequired, // Ensures handleDrawerClose is a function and required
};
