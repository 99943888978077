import React, { useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../constant/apiUrl";
import { postAPI } from "../../../API/ApiService";

// Helper function to validate title type
const validateTitle = (title) => {
  // Check if title is a valid string
  if (typeof title !== "string") {
    return ""; // Return empty string if it's an invalid type (array, number, etc.)
  }
  return title; // Otherwise return the title itself
};

// Custom comparison function to determine if props have changed
const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.rfiData.title === nextProps.rfiData.title &&
    prevProps.id === nextProps.id
  );
};

const RequestTitle = ({ rfiData, id }) => {
  const isDarkTheme = useSelector((state) => state.theme);

  useEffect(() => {
    const increaseCount = async () => {
      try {
        await postAPI(`${BASE_URL}/rfi/${id}/view`);
      } catch (e) {
        console.log(e);
      }
    };
    increaseCount();
  }, [id]);

  return (
    <Box
      data-testid="request-title-box"
      sx={{
        display: "flex",
        width: "100%", // Set the width of the container
        padding: "32px 0px", // Top and bottom padding: 32px, left and right: 0px
        alignItems: "flex-start", // Align items at the start of the container
      }}
    >
      <Typography
        sx={{
          color: isDarkTheme ? "white" : "var(--Tokens-Text-Primary, #3A3A3A)", // Text color
          fontFeatureSettings: "'liga' off, 'clig' off", // Disable ligatures and contextual ligatures
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)", // Font family
          fontSize: "var(--Font-Size-H4, 28px)", // Font size
          fontStyle: "normal", // Normal font style
          fontWeight: 700, // Font weight
          lineHeight: "var(--Line-Height-H4, 40px)", // Line height
          letterSpacing: "1px", // Letter spacing
        }}
      >
        {validateTitle(rfiData?.title)}
      </Typography>
    </Box>
  );
};

// PropTypes validation
RequestTitle.propTypes = {
  rfiData: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // Accept string or node for title (string or valid renderable content)
  }), // Ensure rfiData is an object and is required
  id: PropTypes.number,
};

// Memoized version of the component with custom comparison function
export default React.memo(RequestTitle, areEqual);
