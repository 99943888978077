import { Box, Divider } from '@mui/material'
import React from 'react'
import CustomPagination from '../../../PotatoProtection/LargeScreen/Landing/CustomPagination'
import { darkTheme } from "../../../../theme/Theme";
import { useSelector } from 'react-redux';
function DocumentBottom({page,setPage,Data,rowsPerPage}) {
  const isDarkTheme = useSelector((state) => state.theme);
  console.log(Data,'isDarkTheme')
  return (
    <>
        <Divider
          sx={{
            background: isDarkTheme && darkTheme.textColorLayer1,
            width: "100%",
            mt: "5px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CustomPagination
            page={page}
            setPage={setPage}
            totalPages={Math.ceil(Data?.length / rowsPerPage)}
          />
        </Box>
        </>
  )
}

export default DocumentBottom