import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import useThemeRender from "../../../theme/useThemeRender";

function DeniedDetailFirstContainer({ detailData }) {
  return (
    <Box
      sx={{
        background: useThemeRender("cardBackgroundColorLayer2"),
      }}
      className="custom-container"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Content title
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.title}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Visibility
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.visibility}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Projects
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.projects}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Subjects
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.subject}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Sectors
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.sector}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Countries
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.country}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Language
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.language}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Subject matter expert
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.sme}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Content level
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.level}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DeniedDetailFirstContainer;