import React from "react";
import { Box, Typography } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import LikeButton from "../LikeButton";
import PropTypes from "prop-types"; // Import PropTypes
const typeStyle = {
  color: "var(--Tokens-Button-Borderless, #005CBC)", // Text color
  fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family
  fontSize: "var(--Font-Size-Small, 14px)", // Font size
  fontStyle: "normal", // Font style
  fontWeight: 700, // Font weight
  lineHeight: "var(--Line-Height-Small, 20px)", // Line height (142.857% of font size)
  letterSpacing: "0.14px", // Letter spacing
};

const boxStyle = {
  display: "flex", // Make this container a flexbox
  height: "var(--Spacing-spacing-32, 32px)", // Set the height of the container
  flexDirection: "row", // Arrange children in a row
  justifyContent: "center", // Center children vertically
  alignItems: "center", // Center children horizontally
  gap: "4px",
  cursor: "pointer",
};

export default function ActionButtons({ card }) {
  const handleShare = () => {
    const urlToShare = window.location.href; // You can replace this with the specific URL you want to copy
    navigator.clipboard
      .writeText(urlToShare)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
        alert("Failed to copy the link. Please try again.");
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex", // Makes the container a flexbox
          alignItems: "center", // Vertically aligns children in the center
          gap: "var(--Spacing-spacing-16, 16px)", // Adds spacing between the children
        }}
      >
        <Box sx={boxStyle} onClick={handleShare} style={{ cursor: "pointer" }}>
          <Typography sx={typeStyle}>Share</Typography>
          <ShareOutlinedIcon
            sx={{ fill: "#005CBC", height: "20px", width: "20px" }}
          />
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typeStyle}>Like</Typography>
          <LikeButton cardId={card?.id} initialLiked={card?.liked} />
        </Box>
      </Box>
    </Box>
  );
}
ActionButtons.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string, // ID is required and should be a string
    liked: PropTypes.bool, // liked is optional and should be a boolean
  }), // Ensure card is required and follows the shape
};
