import { Box, Divider } from "@mui/material";
import React from "react";
import useThemeRender from "../../../../theme/useThemeRender";
import { useSelector } from "react-redux";
import { darkTheme } from "../../../../theme/Theme";
import AccordionDetail from "./AccordionDetail";
import DocumentHeader from "./DocumentHeader";

function SpecialProjectDocument({paginatedData}) {
  const isDarkTheme = useSelector((state) => state.theme);
  const  insideCardBackgroundColorLayer1 = useThemeRender("insideCardBackgroundColorLayer1")
  const  textColorLayer1 = useThemeRender("textColorLayer1")
  return (
    paginatedData?.map((item)=>(
      <Box
      sx={{
        background: insideCardBackgroundColorLayer1,
        maxWidth: "370px",
      }}
      className="specialProjectDocument"
    >
      <DocumentHeader title={item.title} name={"document"} popupContent={`Remove document from special project?`} data={item}/>
      <Divider
        sx={{
          background: isDarkTheme && darkTheme.textColorLayer1,
          width: "100%",
        }}
      />
      <Box
        sx={{
          color: textColorLayer1,
          fontWeight: 500,
        }}
        className="textStyle"
      >
        {item?.description}
      </Box>
      <AccordionDetail header={"Subject"} data={item?.subjects} />
    </Box>
    ))
  );
}

export default SpecialProjectDocument;
