import React from "react";
import { Box, Typography } from "@mui/material";
import DividerComponent from "../../Administration/Screens/CommonComponent/DividerComponent";
import {
  containerStyles,
  boxStyles,
  typographyBoldStyles,
  typographyStyles,
} from "./menulistStyle"; // Assuming these styles are imported
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setFilter } from "../../../slices/rfiSlice";

const menuItems = [
  { label: "Number of likes: high to low", id: 1 },
  { label: "Number of likes: low to high", id: 2 },
  { label: "Number of views: high to low", id: 3 },
  { label: "Number of views: low to high", id: 4 },
  { label: "Requested date, oldest first", id: 5 },
  { label: "Requested date, newest first", id: 6 },
];

export default function MenuList({ setMenuOpen }) {
  const handleSelect = () => {
    setMenuOpen(false);
  };
  const dispatch = useDispatch();

  const handleFilter = (item) => {
    console.log(item, "---itemss-------");
    dispatch(setFilter(item));
  };
  return (
    <Box sx={containerStyles}>
      <Box sx={boxStyles}>
        <Typography sx={typographyBoldStyles}>Sort BY</Typography>
      </Box>
      <DividerComponent />
      {menuItems.map((item, index) => (
        <React.Fragment key={item?.id}>
          <Box sx={boxStyles} onClick={handleSelect}>
            <Typography
              sx={typographyStyles}
              onClick={() => handleFilter(item?.id)}
            >
              {item?.label}
            </Typography>
          </Box>
          {index < menuItems.length - 1 && <DividerComponent />}
        </React.Fragment>
      ))}
    </Box>
  );
}

MenuList.propTypes = {
  setMenuOpen: PropTypes.func.isRequired,
};
