export const formatDate = (timestamp) => {
  if (!timestamp) return "";
  const date = new Date(timestamp);
  const day = String(date.getDate());
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

/**
 * Convert the Incoming array to dropdown option array
 * @param {*} inputOption
 * @returns
 */
export const getDropDownOptionValues = (inputOption = []) => {
  let dropDownOptions = [];
  inputOption?.forEach((item) => {
    if (item?.active) {
      dropDownOptions.push({
        id: item?.id,
        label: item?.name,
        name: item?.name,
      });
    }
  });

  return dropDownOptions;
};

export const getPepsicoDropdownOptions = (optionData) => {
  const optionArray = [];
  optionData?.forEach((item) => {
    optionArray.push({ id: item?.id, displayText: item?.name });
  });

  return optionArray;
};

/**
 * Convert the Incoming array to dropdown option array
 * @param {*} inputOption
 * @returns
 */
export const getDropDownOptionValuesForCuratorFilters = (inputOption = []) => {
  let dropDownOptions = [];
  inputOption?.forEach((item) => {
    dropDownOptions.push({
      id: item?.id,
      label: item?.label,
      description: item?.description,
      active: item?.active,
    });
  });
  return dropDownOptions;
};

/**
 * Format date with dd-mmm-yyyy and return
 * @returns date string
 */
export function getFormattedDate() {
  const date = new Date();

  const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero if needed
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()]; // Get month as abbreviated name
  const year = date.getFullYear(); // Get full year

  return `${day}-${month}-${year}`;
}

/**
 * Return the accordian content
 * @param {*} accordion
 * @returns
 */

export const accordionContent = (accordion) => {
  return [
    {
      label: "Content type",
      content: accordion?.contentType,
    },
    {
      label: "Sector",
      content: accordion?.sector,
    },
    {
      label: "Country",
      content: accordion?.country?.length > 0 ? accordion?.country?.join() : "",
    },
    {
      label: "Uploaded by",
      content: accordion?.uploadedBy,
    },
    {
      label: "Language",
      content: accordion?.language,
    },
    {
      label: "Visibility",
      content: accordion?.visibility,
    },
    {
      label: "Version Number",
      content: accordion?.versionNumber,
    },
    {
      label: "Updated Date",
      content: accordion?.versionNumber,
    },
    {
      label: "Content Level",
      content: accordion?.contentLevel,
    },
    {
      label: "Legal Review",
      content: accordion?.legalReview,
    },
    {
      label: "Special Projects",
      content:
        accordion?.specialProjects?.length > 0
          ? accordion?.specialProjects?.join()
          : "",
    },
    {
      label: "Crop Type",
      content: accordion?.cropType,
    },
  ];
};

/**
 * Return the section content
 * @param {*} accordion
 * @returns
 */
export const generateSectionData = (accordion) => {
  const sectionData = [
    {
      label: "Subjects",
      content: accordion?.subjects?.length > 0 ? [accordion?.subjects] : [],
    },
    {
      label: "Keywords",
      content: accordion?.keywords?.length > 0 ? [accordion?.keywords] : [],
    },
  ];
  return sectionData;
};

/**
 * Convert string and make first letter capital
 * @param {*} string
 * @returns
 */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const prepareCurtatorTableData = (tableData = []) => {
  const formatedTableData = [];

  tableData.forEach((item) => {
    formatedTableData.push({
      contentId: item?.contentId,
      contentGuid: item?.contentGuid,
      title: item?.title,
      type: item?.type,
      status: item?.status,
      sector:
        item?.sector === null || item?.sector === undefined ? [] : item?.sector,
      country:
        item?.country === null || item?.country === undefined
          ? []
          : item?.country,
      language:
        item?.language === null || item?.language === undefined
          ? []
          : [...item?.language],
      subject:
        item?.subject === null || item?.subject === undefined
          ? []
          : item?.country,
    });
  });

  return formatedTableData;
};

const getTheCsvArrayValue = (csvArrayData) => {
  if (csvArrayData?.length > 0) {
    return csvArrayData?.join(",");
  } else {
    return "";
  }
};

export const curatorCSVFileDownload = (csvData, filename) => {
  const csvRows = [];
  const headers = [
    "Content Title",
    "Content Type",
    "Status",
    "Sector",
    "Country",
    "Language",
    "Subject",
  ];
  csvRows.push(headers.join(","));
  csvData?.forEach((row) => {
    const values = [
      row?.title,
      row?.type,
      row?.status,
      getTheCsvArrayValue(row?.sector),
      getTheCsvArrayValue(row?.country),
      getTheCsvArrayValue(row?.language),
      getTheCsvArrayValue(row?.subject),
    ];
    csvRows.push(values.join(","));
  });
  const csvContent = csvRows.join("\n");
  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.csv`;
  a.click();
  URL.revokeObjectURL(url);
};

export const getActiveItems = (items) => {
  return items.filter((item) => item.active);
};

export const sortTableData = (data, params) => {
  return data.sort((a, b) => {
    const nameA = a[params]?.toLowerCase() || ""; // Handle case insensitivity and null values
    const nameB = b[params]?.toLowerCase() || "";
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};
