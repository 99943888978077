import { darkTheme, lightTheme } from "../../../theme/Theme";

const boxStyles = (theme) => ({
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  flex: "1 0 0",
  minWidth: "20%",

  // background: theme.insideCardBackgroundColorLayer3,
});

const labelStyles = (isDarkTheme) => ({
  color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2,
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "20px", // Adjust line height based on your design tokens
});

const contentStyles = (isDarkTheme) => ({
  color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2,
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
});

export { contentStyles, labelStyles, boxStyles };
