import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import { BASE_URL } from "../../../../constant/apiUrl";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";
import axios from "axios";
import DividerComponent from "../CommonComponent/DividerComponent";
import InvitationHistoryHeader from "./InvitationHistoryHeader";
import InvitationTable from "./InvitationTable";
import ApproveModal from "./ApproveModal";
import EditRequestSlider from "./EditRequestSlider";
import Loading from "../../../../utils/Loader";
import { getAPI } from "./../../../../API/ApiService";
import { ToastContainer } from "pepsico-ds";
import { showErrorToast } from "../../../../utils/Toast/toast";
import useLookupData from "../../../../hooks/useLookupData";
import { sortTableData } from "../../../../utils/function";
const InvitationHistory = ({ header }) => {
  console.log(header, "---main-");
  const { approveModal, editSlider, fetch } = useSelector(
    (store) => store.invitation
  );
  const isDarkTheme = useSelector((state) => state.theme);
  const [searchText, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState(null);
  const [sponsor, setSponsor] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionData, setActionData] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [access, setAccess] = useState(null);
  const formatData = (data, header) => {
    let newData;
    if (header === "Access:Invitation history") {
      newData = data
        .map((item) => {
          return { ...item, country: item?.countryName };
        })
        .filter((item) => {
          return item.status !== "FAILURE";
        });
    } else {
      newData = data.map((item) => ({
        ...item,
        country: item?.country?.name,
        countryId: item?.country?.id,
      }));
    }
    const sortData = sortTableData(newData, "firstName");

    return sortData;
  };
  const { user } = useLookupData("REASON_FOR_ACCESS");
  useEffect(() => {
    setLoading(true);
    setFilter(null);
    setSponsor(null);
    setOrganization(null);
    if (header === "Access:Invitation history") {
      fetchHistory().then((data) => {
        if (data?.status === 200) {
          const format = formatData(data?.data?.response, header);
          setRows(format);
          setFilterList(format);
        }
        if (
          data?.status === 401 ||
          data?.status === 500 ||
          data.status === 400
        ) {
          showErrorToast("Fail To Load Data");
        }
      });
    } else if (header === "Access history") {
      fetchAccessHistory().then((data) => {
        if (data?.status === 200) {
          const AccessHistoryData = formatData(data?.data, header);

          const formattedData = AccessHistoryData.map((item) => ({
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            email: item.email,
            accessType: item.accessType,
            organization: item.company,
            contactNo: item.contactNo,
            sponsorEmail: item.sponsorEmail,
            lastAccess: item.lastAccess,
          }));

          setRows(formattedData);
          setFilterList(formattedData);
        }
      });
    } else {
      fetchApproval().then((data) => {
        if (data?.status === 200) {
          const fetch = formatData(data?.data?.response, header);
          setRows(fetch);
          setFilterList(fetch);
        }
        if (
          data?.status === 401 ||
          data?.status === 500 ||
          data.status === 400
        ) {
          showErrorToast("Fail To Load Data");
        }
      });
    }
  }, [header, fetch]);

  useEffect(() => {
    let updatedRows = rows;
    if (filter) {
      updatedRows = updatedRows.filter((row) => {
        console.log(row.status, filter.value);
        return row.status === filter.value;
      });
    }
    if (sponsor) {
      updatedRows = updatedRows.filter(
        (row) => row.sponsorEmail === sponsor.label
      );
    }
    if (organization) {
      updatedRows = updatedRows.filter(
        (row) => row.organization === organization.label
      );
    }
    if (access) {
      updatedRows = updatedRows.filter(
        (row) => row.accessType === access.label
      );
    }
    setFilterList(updatedRows);
  }, [filter, rows, sponsor, organization, access]);

  console.log(access, "access-------");

  const getUniqueOptions = (field) => {
    return [...new Set(rows.map((row) => row[field]))].map((value, index) => ({
      id: index + 1,
      label: value,
    }));
  };

  const fetchHistory = async () => {
    try {
      const response = await getAPI(`${BASE_URL}/invitations/find-all`);
      return response;
    } catch (e) {
      showErrorToast("Fail To Load Data");
    } finally {
      setLoading(false);
    }
  };
  const fetchApproval = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/admin/access-request-form/find-all-pending-requests`
      );
      return response;
    } catch (e) {
      showErrorToast("Fail To Load Data");
    } finally {
      setLoading(false);
    }
  };
  const fetchAccessHistory = async () => {
    try {
      const response = await getAPI(`${BASE_URL}/admin/access-history`);
      return response;
    } catch (e) {
      console.log();
    } finally {
      setLoading(false);
    }
  };
  console.log(filterList, "--data--------");
  const handleExportCsv = async () => {
    if (header === "Access history") {
      const file = await getAPI(`${BASE_URL}/admin/export-access-history`);
      console.log(file);
      const blob = new Blob([file.data], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "AccessHistory.csv";
      a.click();
      URL.revokeObjectURL(url);
      return;
    }
    const csvRows = [];
    const headers = [
      "FirstName",
      "LastName",
      "Email",
      "Company",
      "Country",
      "SponsorMail",
      "InvitationStatus",
    ];
    csvRows.push(headers.join(","));
    rows.forEach((row) => {
      const values = [
        row.firstName,
        row.lastName,
        row.email,
        row.company,
        row.country,
        row.sponsorEmail,
        row.status,
      ];
      csvRows.push(values.join(","));
    });
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "invitation_history.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  if (loading) return <Loading />;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "auto",
        flexDirection: "column",
        flex: "1 0 0",
        // gap: "16px",
      }}
    >
      <DividerComponent />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer1
            : lightTheme.backgroundColorLayer2,
        }}
      >
        <InvitationHistoryHeader
          user={user}
          setSearch={setSearch}
          handleExportCsv={handleExportCsv}
          header={header}
          getUniqueOptions={getUniqueOptions}
          setFilter={setFilter}
          setSponsor={setSponsor}
          setOrganization={setOrganization}
          setAccess={setAccess}
        />
      </Box>
      <InvitationTable
        searchText={searchText}
        rows={filterList}
        header={header}
        setActionData={setActionData}
        user={user}
      />
      {approveModal && <ApproveModal actionData={actionData} />}
      {editSlider && <EditRequestSlider actionData={actionData} />}
    </Box>
  );
};

export default InvitationHistory;
