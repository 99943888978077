import React, { useEffect, useState } from "react";
import useFetchInternal from "../hooks/useFetchInternal";
import LibraryPageLayout from "../components/LibraryPage/LibraryPageLayout";
import { BASE_URL } from "../constant/apiUrl";
import {getAPI} from "../API/ApiService";
import Loading from "../utils/Loader";
import { useSelector } from "react-redux";
export default function Library() {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [error, setError] = useState(null);
  const { favourite } = useSelector((store) => store.internalPage);

// TODO : WE HAVE USE CONSTANT API URL, WILL IMPLEMENT ENV CONFIGURATION ONCE IT WILL CONFIGURE
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
       const response = await getAPI(
          `${BASE_URL}/internal-pages/page-info/find-by-page-id?pageId=15`
        );
        setPageData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
  loading ?
    <Loading/>
    :
   <LibraryPageLayout pageData={pageData} header={"Library"} />
  ) 
}
