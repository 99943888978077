import { Box, Typography } from "@mui/material";
import React from "react";
import useThemeRender from "../../../../theme/useThemeRender";

function InsideCardUser({ cardName, value }) {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "var(--Spacing-spacing-4, 4px)",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "var(--Spacing-spacing-2, 2px)",
        alignSelf: "stretch",
        borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
        background: useThemeRender("insideCardBackgroundColorLayer5"),
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer4"),
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "var(--Font-Size-Small, 14px)",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-Small, 20px)",
        }}
      >
        {cardName}
      </Typography>
      <Typography
        sx={{
          color: useThemeRender("textColorLayer4"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "var(--Font-Size-XSmall, 12px)",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "var(--Line-Height-XSmall, 18px)",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          cursor: "pointer",
        }}
        title={value}
      >
        {value?.length > 35 ? `${value.slice(0, 35)}...` : value}
      </Typography>
    </Box>
  );
}

export default InsideCardUser;
