import React, { useEffect, useState } from "react";
import ArtifactHistory from ".";
import { Box } from "@mui/material";
import { BASE_URL } from "../../../constant/apiUrl";
import { getAPI } from "../../../API/ApiService";
import Loading from "../../../utils/Loader";
import { prepareCurtatorTableData } from "../../../utils/function";
import {
  setDeniedHistoryContentLevel,
  setDeniedHistoryContentType,
  setDeniedHistoryVisibility,
} from "../../../slices/curatorPageSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function ApprovalHistroy() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { selectedCuratorPage } = useSelector((state) => state.curatorpage);
  useEffect(() => {
    const fetchDeniedHistory = async () => {
      await getAPI(`${BASE_URL}/curator/approved-list`)
        .then((response) => {
          setData(prepareCurtatorTableData(response?.data));
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    };

    fetchDeniedHistory();
  }, []);

  useEffect(() => {
    dispatch(setDeniedHistoryContentType(""));
    dispatch(setDeniedHistoryVisibility(""));
    dispatch(setDeniedHistoryContentLevel(""));
  }, [selectedCuratorPage]);

  return (
    <Box>
      {loading && <Loading/>}
      <ArtifactHistory pageName={"artifcatsHistory"} Tabledata={data} />
    </Box>
  );
}

export default ApprovalHistroy;
