import { Box } from "@mui/material";
import HomeSpotlightCarousal from "./HomeSpotlightCarousal";
import { BASE_URL } from "../constant/apiUrl";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAPI } from "../API/ApiService";
import Loading from "../utils/Loader";
import InternalCarousal from "./InternalPages/Carousal-ExpandedView/InternalCarousal";
import useThemeRender from "../theme/useThemeRender";
const SpotLight = () => {
  const [loading, setLoading] = useState(false);
  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");
  const [pageData, setPageData] = useState(null);
  const [error, setError] = useState(null);
  const { favourite } = useSelector((store) => store?.internalPage);
  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/spotlights`);
        if (response?.status === 200) {
          setPageData(response?.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [favourite]);
  if (loading) return <Loading />;

  return (
    <Box
      sx={{
        backgroundColor: backgroundColorLayer3,
        marginBottom: "16px",
      }}
      className="curosalBackground"
    >
      {/* <HomeSpotlightCarousal data={pageData} content={"In the Spotlight"} /> */}
      <InternalCarousal
        data={pageData}
        content={"Documents"}
        flexDirection={"column"}
        heading={"In the Spotlight"}
        isHeadingBig={true}
      />
    </Box>
  );
};

export default SpotLight;
