import React from "react";
import { Box, Typography, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import accordionStyles from "./CustomAccordianStyles";
import { useSelector } from "react-redux";
import { darkTheme } from "../../theme/Theme";

export default function CustomAccordion({
  headerText,
  children,
  footerText,
  expanded,
  onToggle,
}) {
  const isDarkTheme = useSelector((store) => store.theme);
  return (
    <Box sx={accordionStyles.container}>
      {/* Accordion Summary */}
      <Box
        onClick={onToggle}
        sx={accordionStyles.summaryBox(expanded, isDarkTheme)}
      >
        <Box sx={accordionStyles.summaryContent}>
          <Typography
            sx={{
              ...accordionStyles.headerText,
              color: isDarkTheme
                ? "white"
                : "var(--Tokens-Text-Secondary, #616161)",
            }}
          >
            {headerText}
          </Typography>
          <Box sx={accordionStyles.expandBox}>
            {/* Toggle the text and icon based on the expanded state */}
            <Typography sx={accordionStyles.expandText}>
              {expanded ? "Collapse" : "Expand"}
            </Typography>
            {expanded ? (
              <ExpandLessIcon sx={{ color: "#005CBC" }} /> // Show CollapseIcon when expanded
            ) : (
              <ExpandMoreIcon sx={{ color: "#005CBC" }} /> // Show ExpandMoreIcon when collapsed
            )}
          </Box>
        </Box>

        {/* Optional footer */}
        {!expanded && (
          <Box sx={accordionStyles.footerBox}>
            <Typography sx={accordionStyles.footerText}>Uploaded by</Typography>
            <Typography sx={accordionStyles.footerSubText}>
              {footerText}
            </Typography>
          </Box>
        )}
      </Box>

      {/* Accordion Details (Content Area) */}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            ...accordionStyles.detailsBox,
            background: isDarkTheme
              ? darkTheme.insideCardBackgroundColorLayer3
              : "var(--Tokens-Background-Layer-01, #FBFCFE)",
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
}
