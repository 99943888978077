import React from "react";
import { Button, Filter } from "pepsico-ds";
import { Box } from "@mui/material";

import { useSelector } from "react-redux";
import { darkTheme } from "../../../theme/Theme";
import PropTypes from "prop-types";

export default function MainBodyFilter({
  requested,
  setFilterRequest,
  rfiRecords,
}) {
  const isDarkTheme = useSelector((state) => state.theme);

  const { flagUser } = useSelector((state) => state.user);

  const handleExport = () => {
    const csvRows = [];
    const headers = [
      "Title",
      "Description",
      "Requested Date",
      "Requested By",
      "Views",
      "Counts",
    ];
    csvRows.push(headers.join(","));
    rfiRecords.forEach((row) => {
      const values = [
        row.title,
        row.description,
        row.requestedDate,
        row.displayName,
        row.views,
        row.likes,
      ];
      csvRows.push(values.join(","));
    });
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "RFI.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  const handle = (value) => {
    setFilterRequest(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: "8px",
        gap: "8px",
        alignItems: "center",
        alignSelf: "stretch",
        background: isDarkTheme ? darkTheme.backgroundColorLayer3 : "#FBFCFE",
        boxShadow: "0px -2px 0px 0px #D7E5F8 inset",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          visibility: flagUser.roleName === "EXTERNAL" ? "hidden" : "",
        }}
      >
        <Filter
          label="Requested by"
          onChange={(val) => handle(val)}
          options={requested}
          selection="single"
          size="medium"
          isDark={isDarkTheme}
        />
      </Box>
      <Button
        text="Export"
        size="medium"
        onClick={handleExport}
        isDark={isDarkTheme}
      />
    </Box>
  );
}

MainBodyFilter.propTypes = {
  requested: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  rfiRecords: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      Title: PropTypes.string.isRequired,
    })
  ).isRequired,
  setFilterRequest: PropTypes.func.isRequired,
};
