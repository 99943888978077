// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  drawerOpen: false,
  globalSearchText: "",
  navigations: [],
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setGlobalSearchText: (state, action) => {
      state.globalSearchText = action.payload;
    },
    setUserNavigations: (state, action) => {
      state.navigations = action.payload;
    },
  },
});

export const {
  setOpen,
  setDrawerOpen,
  setGlobalSearchText,
  setUserNavigations,
} = navSlice.actions;

export default navSlice.reducer;
