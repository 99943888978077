import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme } from "../../../theme/Theme";
import {
  boxStyles,
  contentStyles,
} from "../../../utils/CommonAccordianChild/GridSection/gridStyle";
import { titleStyles } from "../../../utils/CommonAccordianChild/Notes/NoteStyle";

export default function Summary({ label, description }) {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        ...boxStyles,
        padding: "16px",
        background: isDarkTheme
          ? darkTheme.insideCardBackgroundColorLayer3
          : "var(--Tokens-Background-Layer-02, #EFF5FC)",
      }}
    >
      <Typography
        sx={{
          ...titleStyles,
          color: isDarkTheme
            ? "white"
            : "var(--Tokens-Text-Secondary, #616161)",
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          ...contentStyles,
          color: isDarkTheme
            ? "white"
            : "var(--Tokens-Text-Secondary, #616161)",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
}
