// src/components/SubjectSection/SubjectSection.js
import React from "react";
import { Box, Typography } from "@mui/material";
import {
  boxStyles,
  titleStyles,
  subjectBoxStyles,
  subjectChipStyles,
  subjectTypographyStyles,
} from "./subjectStyle"; // Import styles
import { useSelector } from "react-redux";
import { darkTheme } from "../../../theme/Theme";

export default function SubjectSection({ sectionData }) {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        ...boxStyles,
        background: isDarkTheme
          ? darkTheme.insideCardBackgroundColorLayer3
          : "var(--Tokens-Background-Layer-02, #EFF5FC)",
      }}
    >
      <Typography
        sx={{
          ...titleStyles,
          color: isDarkTheme
            ? "white"
            : "var(--Tokens-Text-Secondary, #616161)",
        }}
      >
        {sectionData?.label}
      </Typography>
      <Box sx={subjectBoxStyles}>
        {sectionData?.content?.map((i) => (
          <Box sx={subjectChipStyles}>
            <Typography sx={subjectTypographyStyles}>{i}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
