import { Box, styled, Typography } from "@mui/material";
import { darkTheme, lightTheme } from "../../theme/Theme";

export const Label = styled(Typography)(
  ({ theme, isDarkTheme, isDisabled }) => ({
    color: isDisabled
      ? "#A0A0A0"
      : isDarkTheme
      ? darkTheme.textColorLayer1
      : lightTheme.textColorLayer1,
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-XSmall, 12px)",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "var(--Line-Height-XSmall, 18px)",
  })
);

export const IsRequiredLabel = styled(Typography)(({ theme, isDarkTheme }) => ({
  color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
  fontFeatureSettings: "'liga' off, 'clig' off",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-XSmall, 12px)",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "var(--Line-Height-XSmall, 18px)",
}));

export const UploadImageWrapper = styled(Box)(
  ({ theme, isDarkTheme, isDisabled }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "1px dashed #D7E5F8",
    width: "100%",
    height: "160px",
    gap: "8px",
    cursor: "pointer",
    backgroundColor: isDisabled
      ? "#D0D0D0"
      : isDarkTheme
      ? darkTheme.insideCardBackgroundColorLayer4
      : lightTheme.insideCardBackgroundColorLayer4,
  })
);

export const UploadImageLabel = styled(Box)(({ theme, isDisabled }) => ({
  color: isDisabled ? "#A0A0A0" : "#005CBC",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "18px",
  textTransform: "none",
}));
