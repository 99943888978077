import { Box, Typography } from "@mui/material";
import React from "react";
import useThemeRender from "../../theme/useThemeRender";
import { formatDate } from "../../utils/function";

function CuratorApprovedStatus({ title, header1, header2, detailData }) {
  return (
    <>
      <Typography
        className="DeniedTitle"
        sx={{
          color: useThemeRender("textColorLayer2"),
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          padding: "var(--Spacing-spacing-16, 16px)",
          alignSelf: "stretch",
          background: useThemeRender("cardBackgroundColorLayer2"),
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: useThemeRender("insideCardBackgroundColorLayer3"),
            boxShadow: "0px -2px 0px 0px #D7E5F8 inset",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: useThemeRender("textColorLayer4"),
            }}
            className="text-style"
          >
            {header1}
          </Typography>
          <Typography
            sx={{
              color: useThemeRender("textColorLayer4"),
            }}
            className="text-style"
          >
            {header2}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: useThemeRender("insideCardBackgroundColorLayer3"),
            boxShadow: "0px -2px 0px 0px #D7E5F8 inset",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: useThemeRender("textColorLayer4"),
            }}
            className="text-style"
          >
            {detailData?.rejectedBy}
          </Typography>
          <Typography
            sx={{
              color: useThemeRender("textColorLayer4"),
            }}
            className="text-style"
          >
            {formatDate(detailData?.uploadedDate)}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default CuratorApprovedStatus;