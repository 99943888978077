import { Box, Typography, Card, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./VideoScreenStyle.css";
import Loading from "../../utils/Loader";
import useThemeRender from "../../theme/useThemeRender";
import VideoPlayer from "./VideoPlayer";
import { useLocation, useParams } from "react-router-dom";
import { BASE_URL } from "../../constant/apiUrl";
import VideoSummary from "./VideoSummary";
import VideoDetailSection from "./VideoDetailSection";
import { getAPI, postAPI } from "../../API/ApiService";

export default function VideoDetails() {
  const location = useLocation();
  const [videoDetails, setVideoDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = 1;
  const isMobile = useMediaQuery("(max-width: 600px)");
  let params = useParams();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await getAPI(
        `${BASE_URL}/content/${params?.contentId}/user/${userId}`
      ).then((response) => {
        setVideoDetails(response?.data);
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        padding: {
          xs: "0px",
          sm: "30px",
        },
        paddingTop: { xs: "16px" },
        paddingBottom: "24px",
        flexDirection: "column",
        gap: "var(--Spacing-spacing-24, 24px)",
        margin: 0,
        backgroundColor: useThemeRender("backgroundColorLayer1"),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", sm: "space-between" },
          alignItems: { xs: "flex-start", sm: "center" },
          gap: { xs: "var(--Spacing-spacing-24, 24px)", sm: "10px" },
          alignSelf: { xs: "stretch", sm: "auto" },
          flexDirection: { xs: "column", sm: "row" },
          padding: { xs: "16px", sm: "0" },
        }}
      >
        <Typography
          sx={{
            color: useThemeRender("textColorLayer2"),
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H4, 28px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H4, 40px)",
            width: { xs: "100%", sm: "70%" },
          }}
        >
          {videoDetails?.subjects}
        </Typography>
      </Box>
      {isLoading && <Loading />}
      <VideoPlayer />
      <VideoSummary videoDetails={videoDetails} contentId={params?.contentId}/>
      <VideoDetailSection videoDetails={videoDetails} />
      <Card
        className="SummaryCard"
        sx={{
          background: useThemeRender("backgroundColorLayer3"),
        }}
      >
        <Typography
          className="SummaryTitle"
          sx={{
            color: useThemeRender("textColorLayer2"),
          }}
        >
          Disclaimer
        </Typography>
        <Box
          className="SummaryContentBox"
          sx={{
            background: useThemeRender("backgroundColorLayer1"),
          }}
        >
          <Typography
            className="SummaryContents"
            sx={{
              color: useThemeRender("textColorLayer2"),
            }}
          >
            {videoDetails?.disclaimer}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}
