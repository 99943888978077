import { useEffect, useState } from "react";
import { BASE_URL } from "../constant/apiUrl";
import { getAPI } from "../API/ApiService";
import { toast } from "react-toastify";
import { showErrorToast, showSuccessToast } from "../utils/Toast/toast";
import { getActiveItems } from "../utils/function";
const useLookupData = (lookUpType, dependancy = null) => {
  const [sectorData, setSectorData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [varietyData, setVarietyData] = useState([]);
  const [fleshColorData, setFleshColorData] = useState([]);
  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("auth_token");
  useEffect(() => {
    const fetchLookupData = async () => {
      setLoading(true);
      setError(null); // Reset error for a new request
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=${lookUpType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          switch (lookUpType) {
            case "SECTOR":
              const activeSector = getActiveItems(response?.data);
              setSectorData(activeSector);
              break;
            case "COUNTRY":
              const activeCountry = getActiveItems(response?.data);
              setCountryData(activeCountry);
              break;
            case "VARIETY":
              const fecthVariety = response?.data;
              const varities = fecthVariety.map((item) => {
                return { id: item.id, label: item.name, description: item.description, active: item.active };
              });
              const activeVariety = getActiveItems(varities);
              setVarietyData(activeVariety);
              break;
            case "FLESH_COLOR":
              const activeFleshColor = getActiveItems(response?.data);
              setFleshColorData(activeFleshColor);
              break;
            case "REASON_FOR_ACCESS":
              const activeReasonForAccess = getActiveItems(response?.data);
              setUser(activeReasonForAccess);
              break;
            default:
              throw new Error("Invalid lookup type");
          }
        }
        if (response?.status === 500) {
          showErrorToast(`Failed to load  ${lookUpType}`);
        }
      } catch (err) {
        showErrorToast(`Something went wrong`);
      } finally {
        setLoading(false);
      }
    };
    fetchLookupData();
  }, [dependancy]);

  return {
    sectorData,
    countryData,
    varietyData,
    fleshColorData,
    loading,
    error,
    setLoading,
    user,
    // Expose fetch function to make individual API calls
  };
};

export default useLookupData;
