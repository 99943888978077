import React, { useEffect, useRef, useState } from "react";
import { Box, Card, useMediaQuery } from "@mui/material";
import axios from "axios";
import useThemeRender from "../../theme/useThemeRender";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";

function VideoPlayer() {
  const videoRef = useRef(null);
  const mediaSourceRef = useRef(new MediaSource());
  const token = localStorage.getItem("auth_token");
  const [sourceBuffer, setSourceBuffer] = useState(null);

  useEffect(() => {
    const fetchVideoChunks = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/file-detail/find-file-detail?fileId=5833`
        );
        if (response?.status === 200) {
          const { fileName, chunks } = response.data;

          for (const item of chunks) {
            try {
              const chunkResponse = await axios.get(
                `https://flag-dev-c5gkcedqchceesa8.eastus-01.azurewebsites.net/fritolay/streaming/stream/${fileName}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    Range: `bytes=0-${item?.chunkSize}`,
                    "Content-Type": "application/octet-stream",
                  },
                  responseType: "arraybuffer",
                }
              );

              // Append chunk to SourceBuffer
              if (sourceBuffer) {
                sourceBuffer.appendBuffer(chunkResponse.data);
              }
            } catch (error) {
              console.error(`Error fetching chunk: ${item?.chunkSize}`, error);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching video details:", error);
      }
    };

    if (mediaSourceRef.current && videoRef.current) {
      videoRef.current.src = URL.createObjectURL(mediaSourceRef.current);

      mediaSourceRef.current.addEventListener("sourceopen", () => {
        const buffer = mediaSourceRef.current.addSourceBuffer(
          'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        );
        setSourceBuffer(buffer);
        fetchVideoChunks();
      });
    }
  }, []);

  const isMobile = useMediaQuery("(max-width: 600px)");
  console.log(videoRef, "-----videos--------");

  return (
    <Card
      className="video-player"
      sx={{
        background: useThemeRender("backgroundColorLayer3"),
        display: "flex",
        width: "100%",
        height: isMobile ? "238px" : "731px",
        padding: "16px",
        gap: "16px",
        borderRadius: "8px",
      }}
    >
      <Box
        className="SummaryContentBox"
        sx={{
          background: useThemeRender("backgroundColorLayer1"),
        }}
      >
        <video
          ref={videoRef}
          controls
          style={{
            width: "100%",
            height: isMobile ? "205px" : "690px",
          }}
        >
          <p>
            Your browser doesn't support HTML video. Here is a{" "}
            <a href={videoRef.current?.src} download="video.mp4">
              link to the video
            </a>{" "}
            instead.
          </p>
        </video>
      </Box>
    </Card>
  );
}

export default VideoPlayer;
