export const boxStyles = {
  display: "flex",
  padding: "var(--Spacing-spacing-4, 4px)",
  alignItems: "center",
  gap: "var(--Spacing-spacing-4, 4px)",
  alignSelf: "stretch",
  backgroundColor: "var(--Tokens-Background-Layer-01, #FBFCFE)",
  width: "100%",
  cursor: "pointer",
};

export const typographyStyles = {
  overflow: "hidden",
  color: "var(--Tokens-Text-Secondary, #616161)",
  textOverflow: "ellipsis",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 14px)",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "var(--Line-Height-Small, 20px)",
};

export const typographyBoldStyles = {
  ...typographyStyles,
  fontWeight: 700,
  textTransform: "uppercase",
};

export const containerStyles = {
  display: "inline-flex",
  width: "300px",
  padding: "var(--Spacing-spacing-03, 8px) var(--Spacing-spacing-8, 8px)",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "var(--Spacing-spacing-03, 8px)",
  borderRadius: "var(--Corner-radius-corner-radius-xsmall, 4px)",
  border: "1px solid var(--Tokens-Border-Default, #D7E5F8)",
  backgroundColor: "var(--Tokens-Background-Layer-01, #FBFCFE)",
  boxShadow:
    "var(--Elevation-X-Elevation-02, 0px) var(--Elevation-Y-Elevation-02, 4px) var(--Elevation-Blur-Elevation-02, 24px) var(--Elevation-Spread-Elevation-02, 0px) var(--Elevation-Colors-Elevation-02, rgba(58, 58, 58, 0.08))",
};
