import React, { memo, useEffect, useState, useCallback } from "react";
import Hero from "./Hero";
import { BASE_URL } from "../../../../constant/apiUrl";
import Loading from "../../../../utils/Loader";
import { getAPI } from "../../../../API/ApiService";
import fetchAndCacheImage from "../../../../utils/fetchandCatchImages";
import { useDispatch, useSelector } from "react-redux";
import { setImage } from "../../../../slices/imageSlice";
function LandingSection() {
  const [loading, setLoading] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [heroData, setHeroData] = useState(
    Array(5)
      .fill(null)
      .map((_, i) => ({ images: [], formData: {}, slotNumber: i + 1 })) // Predefine slot numbers
  );
  const dispatch = useDispatch();
  const [populateData, setPopulateData] = useState([]);
  const token = localStorage.getItem("auth_token");
  const images = useSelector((state) => state.images.images);
  console.log(images, "storeImagesss");

  const fetchImages = useCallback(async (id) => {
    if (images[id]) return images[id];
    return await fetchAndCacheImage(id, setImage, dispatch);
  }, []);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/administrative_landing/getAllSlots`
        );
        if (!response?.data || !Array.isArray(response.data)) {
          console.error("Invalid response structure", response);
          return;
        }
        const fetched = response.data;

        const slotNumberMap = await Promise.all(
          fetched
            ?.filter((hero) => hero.slotNumber >= 1 && hero.slotNumber <= 5)
            ?.map(async (hero) => {
              const thumbnailIds = Array.isArray(hero?.thumbnailFileId)
                ? hero.thumbnailFileId
                : [hero?.thumbnailFileId].filter(Boolean);

              const images = await Promise.all(
                thumbnailIds.map(async (id) => {
                  const url = await fetchImages(id);
                  return url ? { preview: url } : null;
                })
              );
              return {
                ...hero,
                images: images.filter(Boolean),
              };
            })
        );

        const updatedSlotMap = slotNumberMap
          .filter(Boolean)
          .reduce((acc, hero) => {
            acc[hero.slotNumber] = hero;
            return acc;
          }, {});

        setHeroData((prevData) =>
          prevData.map((hero) => ({
            ...hero,
            images: updatedSlotMap[hero.slotNumber]?.images || [],
            formData:
              updatedSlotMap[hero.slotNumber]?.formData || hero.formData,
          }))
        );

        setPopulateData(
          fetched.filter((hero) => hero.slotNumber >= 1 && hero.slotNumber <= 5)
        );
      } catch (error) {
        console.error("Failed to fetch data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token, fetchData]);

  const updateHeroData = (slotNumber, images) => {
    setHeroData((prevData) =>
      prevData.map((hero) =>
        hero.slotNumber === slotNumber ? { ...hero, images } : hero
      )
    );
    images.forEach((image) => {
      if (image.id) {
        localStorage.setItem(`image_${image.id}`, image.preview);
      }
    });
  };

  if (loading) return <Loading />;

  return (
    <div className="flex flex-col gap-6">
      {heroData.map((hero) => (
        <Hero
          key={hero.slotNumber}
          images={hero.images}
          setHeroData={(images) => updateHeroData(hero.slotNumber, images)}
          populateData={populateData.find(
            (data) => data.slotNumber === hero.slotNumber
          )}
          slotNumber={hero.slotNumber}
          setFetchData={setFetchData}
          fetchData={fetchData}
        />
      ))}
    </div>
  );
}

// Custom comparison function for React.memo
const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.heroData === nextProps.heroData &&
    prevProps.updateHeroData === nextProps.updateHeroData
  );
};

export default memo(LandingSection, areEqual);
