import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  Chip,
  Button,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";

const CustomMultiSelect = ({ label, value, options, onChange,prevSelectedValues,setPrevSelectedValues }) => {
  const [open, setOpen] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const isDarkTheme = useSelector((state) => state.theme);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleToggle = (option) => {
    const exists = tempValue?.some((item) => item.name === option.name);
    if (exists) {
      setTempValue(tempValue?.filter((item) => item.name !== option.name));
    } else {
      setTempValue([
        ...tempValue,
        { id: option.id, name: option.name, mode: "PERSIST" },
      ]);
    }
  };

  const handleSelectAll = () => {
    if (tempValue?.length === options?.length) {
      setTempValue([]); 
    } else {
      setTempValue(
        options?.map((option) => ({
          id: option.id,
          name: option.name,
          mode: "PERSIST",
        }))
      );
    }
  };

  const handleRemove = (option) => {
    setTempValue(tempValue?.filter((item) => item?.name !== option?.name));
  };

  const handleSave = () => {
    // console.log(tempValue,'tempValue',prevSelectedValues)
    const uniqueToSecondArray = prevSelectedValues
    ?.filter(secondItem => !tempValue?.some(firstItem => firstItem.id === secondItem.id))
    .map(item => ({ ...item, mode: "PURGE" }));
    const newArray = [...tempValue, ...uniqueToSecondArray];
    // console.log(newArray,'uniqueToSecondArray')
    onChange(tempValue);
    setPrevSelectedValues(newArray)
    setOpen(false);
  };

  const handleCancel = () => {
    setTempValue(value);
    setOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: 2,
        color: isDarkTheme
          ? darkTheme.textColorLayer1
          : lightTheme.textColorLayer1,
      }}
    >
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        {label}
      </Typography>
      <Select
        multiple
        value={tempValue}
        open={open}
        onOpen={() => {
          setTempValue(value);
          setOpen(true);
        }}
        onClose={() => setOpen(false)}
        renderValue={() => {
          const selectedLabels = tempValue.map(
            (val) => options?.find((option) => option.name === val.name)?.name
          );

          const selectedCount = tempValue?.length;
          const totalOptions = options?.length;

          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                }}
              >
                {selectedLabels[0]}
              </Box>

              {selectedCount <= totalOptions && (
                <Typography
                  variant="body2"
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.textColorLayer1
                      : lightTheme.textColorLayer1,
                    borderRadius: "4px",
                    background: isDarkTheme
                      ? darkTheme.smallTextBackground
                      : lightTheme.smallTextBackground,
                    fontWeight: 400,
                    lineHeight: "18px",
                    padding: "2px",
                  }}
                >
                  {selectedCount > 1 ? `+${selectedCount - 1}` : null}
                </Typography>
              )}
            </Box>
          );
        }}
        sx={{
          width: "100%",
          maxWidth: "100%",
          "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            padding: "10px 14px",
            backgroundColor: isDarkTheme
              ? darkTheme.cardBackgroundColorLayer1
              : lightTheme.cardBackgroundColorLayer1,
            border: "1px solid",
            borderColor: isDarkTheme
              ? darkTheme.borderColorLayer1
              : lightTheme.borderColorLayer1,
            borderRadius: 1,
          },
          "& .MuiSelect-icon": {
            color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: isDarkTheme
                ? darkTheme.backgroundColorLayer3
                : lightTheme.backgroundColorLayer3,
              "& .MuiMenuItem-root": {
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
                "&:hover": {
                  backgroundColor: isDarkTheme
                    ? darkTheme.dropdownHoverColorLayer3
                    : lightTheme.dropdownHoverColorLayer3,
                },
              },
            },
          },
        }}
      >
        <Box
          sx={{
            maxHeight: 300,
            overflowY: "scroll",
            padding: 1,
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
            },
          }}
        >
          <MenuItem
            onClick={handleSelectAll}
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            <Checkbox
              checked={tempValue?.length === options?.length}
              sx={{
                color: isDarkTheme
                  ? darkTheme.checkBoxLayer1
                  : lightTheme.checkBoxLayer1,
              }}
            />
            <ListItemText primary="Select All" />
          </MenuItem>
          <Divider
            sx={{
              background: isDarkTheme && darkTheme.textColorLayer1,
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              alignItems: "center",
              flexWrap: "wrap",
              Maxwidth: "100%",
              overflow: "hidden",
            }}
          >
            {tempValue.map((val) => (
              <Chip
                key={val?.name}
                deleteIcon={<CloseIcon />}
                sx={{
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                  borderRadius: "4px",
                  background: isDarkTheme
                    ? darkTheme.smallTextBackground
                    : lightTheme.smallTextBackground,
                  fontWeight: 400,
                  lineHeight: "18px",
                  padding: "2px",
                  "& .MuiChip-deleteIcon": {
                    backgroundColor: isDarkTheme
                      ? darkTheme.smallTextBackground
                      : lightTheme.smallTextBackground,
                  },
                }}
                label={
                  options?.find((option) => option?.name === val?.name)?.name
                }
                onDelete={() => handleRemove(val)}
              />
            ))}
          </Box>

          <Divider
            sx={{ background: isDarkTheme && darkTheme.textColorLayer1 }}
          />
          {options.map((option) => (
            <MenuItem key={option?.name} onClick={() => handleToggle(option)}>
              <Checkbox
                checked={tempValue.some((item) => item?.name === option?.name)}
                sx={{
                  color: isDarkTheme
                    ? darkTheme.checkBoxLayer1
                    : lightTheme.checkBoxLayer1,
                }}
              />
              <ListItemText primary={option?.name} />
            </MenuItem>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            padding: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{
              color: "#005CBC",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "20px",
              borderRadius: "24px",
              border: "1px solid #005CBC",
              textTransform:"none"
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              color: "#FFFFFF",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "20px",
              borderRadius: "24px",
              backgroundColor: "#005CBC",
              textTransform:"none"
            }}
          >
            Save Options
          </Button>
        </Box>
      </Select>
    </Box>
  );
};

export default CustomMultiSelect;
