import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextArea } from "pepsico-ds";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import useThemeRender from "../../../theme/useThemeRender";
import "./FormatableTextArea.css";

const formattingOptions = [
  { title: "Bold", format: "bold", icon: <FormatBoldIcon /> },
  { title: "Italic", format: "italic", icon: <FormatItalicIcon /> },
  { title: "Underline", format: "underline", icon: <FormatUnderlinedIcon /> },
  { title: "Align Left", format: "justifyLeft", icon: <FormatAlignLeftIcon /> },
  {
    title: "Align Center",
    format: "justifyCenter",
    icon: <FormatAlignCenterIcon />,
  },
  {
    title: "Align Right",
    format: "justifyRight",
    icon: <FormatAlignRightIcon />,
  },
  {
    title: "Numbered List",
    format: "insertOrderedList",
    icon: <FormatListNumberedIcon />,
  },
  {
    title: "Bullet List",
    format: "insertUnorderedList",
    icon: <FormatListBulletedIcon />,
  },
];

const FormatableTextArea = ({
  label,
  editorRef,
  isDarkTheme,
  setDescription,
  isDisabled = false,
  changeFormatableText,
  showToolbar,
}) => {
  const [localContent, setLocalContent] = useState("");
  const textColorLayer1 = useThemeRender("textColorLayer1");

  useEffect(() => {
    setLocalContent(setDescription);
  }, [setDescription]);

  const applyFormatting = (command, value = null) => {
    if (editorRef.current) {
      document.execCommand(command, false, value);
    }
  };

  const handleInput = (e) => {
    setLocalContent(e.target.innerText);
  };

  const handleBlur = (e) => {
    changeFormatableText(localContent);
  };

  const updateLocalText = (e) => {
    setLocalContent(e);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: { sm: "100%", md: "360px" },
      }}
    >
      {/* <Typography
        sx={{
          fontWeight: 400,
          fontSize: "12px",
          color: useThemeRender("textColorLayer2"),
        }}
      >
        {label}
      </Typography> */}
      {/* <Box
        sx={{
          border: isDarkTheme ? "1px solid #4E4E4E" : "1px solid lightgray",
          borderRadius: 2,
          backgroundColor: isDarkTheme ? "#333" : "#fff",
        }}
      > */}
      {/* <Box
          ref={editorRef}
          contentEditable
          onInput={handleInput}
          onBlur={handleBlur}
          component={"adasdasdddsasa"}
          sx={{
            minHeight: 150,
            color: isDisabled ? "#A0A0A0" : textColorLayer1,
            borderRadius: 2,
            padding: 1,
            outline: "none",
            overflowY: "auto",
            fontSize: 14,
          }}
        /> */}

      <TextArea
        className="curator-text-area-summary"
        label={label}
        value={localContent}
        showCharacterCount
        showLabelIcon
        onUpdate={(e) => updateLocalText(e)}
        // onChange={(e) => updateLocalText(e)}
        onBlur={handleBlur}
        placeholder="Type here"
        showToolbar={showToolbar}
        style={{
          display: "flex",
          height: "122px",
          maxWidth: "460px",
          padding: "var(--Spacing-spacing-8, 8px);",
        }}
      />
      {/* <Box
          sx={{
            display: "flex",
            gap: "1px",
            mb: 1,
            flexWrap: "wrap",
          }}
        >
          {formattingOptions.map((option) => (
            <IconButton
              key={option.title}
              title={option.title}
              onClick={() => applyFormatting(option.format)}
              sx={{
                color: isDisabled ? "#A0A0A0" : isDarkTheme ? "white" : "black",
              }}
            >
              {option.icon}
            </IconButton>
          ))}
        </Box> */}
      {/* </Box> */}
    </Box>
  );
};

export default FormatableTextArea;
