import { Autocomplete, Box, Popper, TextField, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { autoCompletStyles } from '../../../../utils/FieldStyles/FieldStyles'
import { useDispatch } from 'react-redux';
import { setListOfValueSelectedCountry, setlistOfValueSelectedSector, setSelectedListOfValues } from '../../../../slices/adminPageSlice';
import { useSelector } from 'react-redux';
import useThemeRender from '../../../../theme/useThemeRender';
import { darkTheme } from '../../../../theme/Theme';

function ListOfDropDown({options,label,value}) {
    const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");
    const textColorLayer1 = useThemeRender("textColorLayer1");
    const dropdownHoverColorLayer3 = useThemeRender("dropdownHoverColorLayer3");
    const dispatch = useDispatch();

    const { selectedListOfValues, listOfValueSelectedSector , listOfValueSelectedCountry } = useSelector((store) => store.adminpage);
    const HandleChange = (event, newValue) => {
        switch(value) {
            case "valueType":
                dispatch(setSelectedListOfValues(newValue));
                break;
            case "ListofValueSector":
                dispatch(setlistOfValueSelectedSector(newValue));
                break;
            case "ListofValueCountry":
                dispatch(setListOfValueSelectedCountry(newValue));
                break;
            default:
                break;
        }
    };
    let selectedValue;

    switch(value) {
        case "valueType":
            selectedValue = selectedListOfValues;
            break;
        case "ListofValueSector":
            selectedValue = listOfValueSelectedSector;
            break;
        case "ListofValueCountry":
            selectedValue = listOfValueSelectedCountry;
            break;
        default:
            selectedValue = null; 
            break;
    }
  
    const isDarkTheme = useSelector((state) => state.theme);
    const isMobile = useMediaQuery("(max-width:600px)");
    const width = "368px";
  return (
    <Box
    sx={{
      display: "flex",
      width: "100%",
      maxWidth: "400px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "var(--Spacing-spacing-8, 8px)",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer2"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "var(--Font-Size-XSmall, 12px)",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "var(--Line-Height-XSmall, 18px)",
        }}
      >
        {label}
      </Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        width: "100%",
        maxWidth: "400px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "var(--Spacing-spacing-8, 8px)",
      }}
    >
      <Autocomplete
        onChange={HandleChange}
        value={selectedValue}
        options={options}
        getOptionLabel={(option) => option}
        PopperComponent={(props) => (
          <Popper
            {...props}
            sx={{
              "& .MuiAutocomplete-listbox": {
                background: backgroundColorLayer3,
                color: textColorLayer1,
                "& .MuiAutocomplete-option:hover": {
                  backgroundColor: dropdownHoverColorLayer3,
                },
              },
            }}
          />
        )}
        sx={autoCompletStyles(isDarkTheme, isMobile, darkTheme, width)}
        ListboxProps={{
          sx: {
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer1
              : "white",
            "& .MuiAutocomplete-option": {
              color: textColorLayer1,
              "&:hover": {
                backgroundColor: isDarkTheme ? "#333" : "#f0f0f0",
              },
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </Box>
  </Box>
  )
}

export default ListOfDropDown