import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
import CustomSelect from "./CustomSelect";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { useDispatch } from "react-redux";
import { setDisableNextButton } from "../../slices/adminPageSlice";
import { getActiveItems } from "../../utils/function";
const StepTwoForm = ({ internalData, setInternalData, handleDropDown }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [sectorData, setSectorData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [specialProjectData, setSpecialProjectData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const sectionStyles = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchSectorData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=SECTOR`
        );
        if (response?.status === 200) {
          const transformedData = response?.data?.map(item => {
            const { name, ...rest } = item; 
            return { ...rest, label: name }; 
          });
          const activeItems = getActiveItems(transformedData);
          setSectorData(activeItems);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchCountries = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=COUNTRY`
        );
        if (response?.status === 200) {
          const transformedData = response?.data?.map(item => {
            const { name, ...rest } = item; 
            return { ...rest, label: name }; 
          });
          const activeItems = getActiveItems(transformedData);
          setCountryData(activeItems);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchSubject = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=SUBJECT`
        );
        if (response?.status === 200) {
          const transformedData = response?.data?.map(item => {
            const { name, ...rest } = item; 
            return { ...rest, label: name }; 
          });
          const activeItems = getActiveItems(transformedData);
          setSubjectData(activeItems);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchSpecialProjectData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=SPECIAL_PROJECTS`
        );
        if (response?.status === 200) {
          const transformedData = response?.data?.map(item => {
            const { name, ...rest } = item; 
            return { ...rest, label: name }; 
          });
          const activeItems = getActiveItems(transformedData);
          setSpecialProjectData(activeItems);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSectorData();
    fetchCountries();
    fetchSubject();
    fetchSpecialProjectData();
  }, []);
  const handleSelect = (e,name) => {
    const ids = e.map(item => item.id);
      handleDropDown(name, ids);
  }
const [selectedSectorData,setSelectedSectorData] = useState([])
const [selectedCountryData,setSelectedCountryData] = useState([])
const [selectedSubjectData,setSelectedSubjectData] = useState([])
const [selectedSpecialProjectData,setSelectedSpecialProjectData] = useState([])

useEffect(()=>{
    const selectedData = sectorData?.filter(item => internalData?.sectorIds?.includes(item?.id));
    setSelectedSectorData(selectedData)
},[internalData?.sectorIds,sectorData])
useEffect(()=>{
    const selectedData = specialProjectData?.filter(item => internalData.specailProjects?.includes(item?.id));
    setSelectedSpecialProjectData(selectedData)
},[internalData.specailProjects,specialProjectData])
useEffect(()=>{
    const selectedData = subjectData?.filter(item => internalData?.subjectIds?.includes(item?.id));
    setSelectedSubjectData(selectedData)
},[internalData?.subjectIds,subjectData])
useEffect(()=>{
  const selectedData = countryData?.filter(item => internalData?.countryIds?.includes(item?.id));
  setSelectedCountryData(selectedData)
},[internalData?.countryIds,countryData])
  const options = {
    subject: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    country: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    sector: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    specialProject: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
  };
  const handleTitleChange = (e) => {
    const title = e.target.value;
    setInternalData((prevData) => ({
      ...prevData,
      contentTitle: title,
    }));
  };

  const handleVisibilityChange = (e) => {
    const newVisibility = e.target.value;
    setInternalData((prevData) => ({
      ...prevData,
      visibility: newVisibility,
    }));
  };
  const handleContentLevelChange = (e) => {
    const newContentLevel = e.target.value;
    setInternalData((prevData) => ({
      ...prevData,
      contentLevel: newContentLevel,
    }));
  };

  useEffect(() => {
    if (
      (internalData?.subjectIds?.length ?? 0) > 0 &&
      (internalData?.countryIds?.length ?? 0) > 0 &&
      (internalData?.sectorIds?.length ?? 0) > 0
    ) {
      dispatch(setDisableNextButton(false));
    } else {
      dispatch(setDisableNextButton(true));
    }
  }, [internalData?.subjectIds, internalData?.countryIds, internalData?.sectorIds]);

  useEffect(() => {
    if (
(internalData?.subjectIds?.length === 0 || !internalData?.subjectIds) &&
  (internalData?.countryIds?.length === 0 || !internalData?.countryIds) &&
  (internalData?.sectorIds?.length === 0 || !internalData?.sectorIds)
    ) {
      dispatch(setDisableNextButton(true));
    }
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        ...sectionStyles,
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer1"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "32px",
          mb: 4,
        }}
      >
        Step 2: Metadata
      </Typography>
      <Box>
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
          }}
        >
          Content title
        </Typography>
        <TextField
          placeholder="Add Title"
          value={internalData.contentTitle}
          onChange={handleTitleChange}
          size="small"
          sx={{
            borderRadius: "4px",
            width: { xs: "300px", md: "400px" },
            "& .MuiInputBase-input::placeholder": {
              color: isDarkTheme
                ? darkTheme.textColorLayer4
                : lightTheme.textColorLayer4,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&:hover fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&.Mui-focused fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
            },
            "& .MuiInputBase-input": {
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            },
          }}
        />
      </Box>

      <Box>
        <FormControl component="fieldset" fullWidth>
          <FormLabel
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            Visibility
          </FormLabel>
          <RadioGroup
            value={internalData.visibility}
            onChange={handleVisibilityChange}
            sx={{
              flexDirection: { xs: "column", md: "row", gap: 26 },
            }}
          >
            <FormControlLabel
              value="EXTERNAL"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="External"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value="INTERNAL"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Internal"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />

            <FormControlLabel
              value="SPECIAL_PROJECTS"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Special projects"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {internalData.visibility === "SPECIAL_PROJECTS" && (
        <CustomSelect
          label="Special projects"
          value={selectedSpecialProjectData}
          onChange={(e)=>handleSelect(e,'specailProjects')}
          options={specialProjectData}
          required={false}
        />
      )}

      <CustomSelect
        label="Subjects"
        value={selectedSubjectData}
        onChange={(e)=>handleSelect(e,'subjectIds')}
        options={subjectData}
        required={true}
      />

      <CustomSelect
        label="Sectors"
        value={selectedSectorData}
        onChange={(e)=>handleSelect(e,'sectorIds')}
        options={sectorData}
        required={true}
      />
      <CustomSelect
        label="Countries"
        value={selectedCountryData}
        onChange={(e)=>handleSelect(e,'countryIds')}
        options={countryData}
        required={true}
      />

      <Box>
        <FormControl component="fieldset" fullWidth>
          <FormLabel
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            Content Level
          </FormLabel>
          <RadioGroup
            value={internalData.contentLevel}
            onChange={handleContentLevelChange}
            sx={{
              flexDirection: { xs: "column", md: "row", gap: 64 },
            }}
          >
            <FormControlLabel
              value="LEVEL_1"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Level 1"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer4
                  : lightTheme.textColorLayer4,
              }}
            />
            <FormControlLabel
              value="LEVEL_2"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Level 2"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer4
                  : lightTheme.textColorLayer4,
              }}
            />
            <FormControlLabel
              value="LEVEL_3"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Level 3"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer4
                  : lightTheme.textColorLayer4,
              }}
            />
            <FormControlLabel
              value="ADVANCED"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Advanced"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer4
                  : lightTheme.textColorLayer4,
              }}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default StepTwoForm;
