import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import FormStepper from "./FormStepper";
import NavigationButtons from "./NavigationButtons";
import {BASE_URL, FILE_SERVICE_BASE_URL} from "../../constant/apiUrl.js";
import StepOneForm from "./StepOneForm";
import StepTwoForm from "./StepTwoForm";
import StepThreeForm from "./StepThreeForm";
import StepFourForm from "./StepFourForm";
import SuccessBox from "./SuccessBox";
import Loading from "../../utils/Loader.js";
import axios from "axios";
import { showSuccessToast } from "../../utils/Toast/toast";
import {
  setDisableNextButton,
  setExternalFileDocument,
  setFileUploaded,
  setInternalFileDocument,
} from "../../slices/adminPageSlice.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const steps = ["STEP 1", "STEP 2", "STEP 3", "STEP 4"];

const InternalUpload = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [internalData, setInternalData] = useState({
    contentType: "DOCUMENT",
    contentTitle: "",
    visibility: "EXTERNAL",
    // specialProject: [],
    // subjects: [],
    // sectors: [],
    // countries: [],
    contentLevel: "LEVEL_1",
    cropType: "",
    keywords: "",
    notes: "",
    summary: "",
    disclaimer: "",
    languageTranslationEnabled: false,
    // translationLanguage: "",
    translation: "",
    attachmentLinkUrl: "",
    attachmentFileId: "",
    thumbnailFileId: "",
    subjectIds: [],
    sectorIds: [],
    countryIds: [],
    specailProjects: [],
    languageIds: [],
  });
  const token = localStorage.getItem("auth_token");
  console.log("internal page ", internalData);
  const handleNext = () => {
    if (activeStep == 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep == 1) {
      if (
        internalData?.subjectIds?.length > 0 &&
        internalData?.countryIds.length > 0 &&
        internalData?.sectorIds?.length > 0
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep == 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const { fileUploadPageName } = useSelector((store) => store.adminpage);
  const handleSaveDraft = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/content/savedraft`,
        internalData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        showSuccessToast("Draft saved successfully!");
        console.log("data passed", internalData);
        console.log("Response:", response.data);
      } else {
        alert("Failed to save draft");
        console.error("Error:", response.data);
      }
    } catch (error) {
      console.error("Error saving draft:", error);
      alert("Failed to save draft");
    } finally {
      setLoading(false);
    }
  };

  const handleDropDown = (label, val) => {
    setInternalData((prev) => {
      return { ...prev, [label]: val };
    });
  };
  useEffect(() => {
    if (activeStep == 2) {
      dispatch(setDisableNextButton(false));
    }
  }, [activeStep]);
  useEffect(() => {
    dispatch(setExternalFileDocument(null));
    dispatch(setFileUploaded(false));
    dispatch(setInternalFileDocument(null));
  }, []);
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepOneForm
            internalData={internalData}
            setInternalData={setInternalData}
          />
        );
      case 1:
        return (
          <StepTwoForm
            internalData={internalData}
            setInternalData={setInternalData}
            handleDropDown={handleDropDown}
          />
        );
      case 2:
        return (
          <StepThreeForm
            internalData={internalData}
            setInternalData={setInternalData}
          />
        );
      case 3:
        return (
          <StepFourForm
            internalData={internalData}
            setInternalData={setInternalData}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      {!success ? (
        <>
          <Box sx={{ width: "90%", margin: "auto", mt: 2, mb: 2 }}>
            <FormStepper steps={steps} activeStep={activeStep} />
            <Box sx={{ mt: 4 }}>{renderStepContent(activeStep)}</Box>
          </Box>

          <NavigationButtons
            onSaveDraft={handleSaveDraft}
            activeStep={activeStep}
            totalSteps={steps.length}
            handleNext={handleNext}
            handleBack={handleBack}
            setSuccess={setSuccess}
            data={internalData}
            setData={setInternalData}
          />
        </>
      ) : (
        <>
          <SuccessBox setActiveStep={setActiveStep} setSuccess={setSuccess} />
        </>
      )}
      {loading && <Loading />}
    </>
  );
};

export default InternalUpload;
