import React, { useEffect, useState } from "react";
import { Filter, Search, Button } from "pepsico-ds";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { Box, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { buttonStyle, boxStyle } from "../InternalPages/InternalStyle";
import {
  setCountry,
  setSearch,
  setSector,
  setVariety,
} from "../../slices/internalPageSlice";
import useThemeRender from "../../theme/useThemeRender";
import { BASE_URL } from "../../constant/apiUrl";
import axios from "axios";
import { getAPI } from "../../API/ApiService";
import { getActiveItems } from "../../utils/function";

export default function LibraryFilter({
  setGrid,
  pageData,
  header,
  setSelection,
  grid,
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");
  const textColorLayer1 = useThemeRender("textColorLayer1");
  const dropdownHoverColorLayer3 = useThemeRender("dropdownHoverColorLayer3");
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const iconColor1 = useThemeRender("iconColor1");
  const borderColorLayer2 = useThemeRender("borderColorLayer2");
  const isDarkTheme = useSelector((state) => state.theme);
  const multiSelectColor = useThemeRender("textColorLayer1");

  const [sectorData, setSectorData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [varietyData, setVarietyData] = useState([]);
  const [flashColor, setFlashColor] = useState([]);
  const [filteredSector, setFilteredSector] = useState(sectorData);
  const [filteredCountries, setFilteredCountries] = useState(countryData);
  const [filteredVariety, setFilteredVariety] = useState(varietyData);
  const [filteredFlashColor, setFilterFlashColor] = useState(flashColor);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { sector, country, variety } = useSelector(
    (state) => state.internalPage
  );
  const handleSelectedSector = (sectorName) => {
    dispatch(setSector(sectorName));
  };

  const handleSelectedCountry = (countryName) => {
    dispatch(setCountry(countryName));
  };
  const handleSearch = (value) => {
    dispatch(setSearch(value));
  };

  const handleSelect = (view) => {
    setGrid(view);
  };

  const dropDown = pageData?.internalSubPages?.map((item) => {
    return {
      id: item.subPageId,
      label: item.subPageName,
    };
  });

  const handleScrollToSection = (event, value) => {
    const label = value?.label;

    if (label) {
      console.log(label, "qwerty");
      setSelection(label);
    }
  };

  const handleVarietyChange = (value) => {
    dispatch(setVariety(value));
  };

  useEffect(() => {
    setFilteredSector(sectorData);
  }, [sectorData]);
  useEffect(() => {
    setFilteredCountries(countryData);
  }, [countryData]);
  useEffect(() => {
    setFilteredVariety(varietyData);
  }, [varietyData]);
  useEffect(() => {
    setFilterFlashColor(flashColor);
  }, [flashColor]);

  useEffect(() => {
    const fetchSectorData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=CROP_TYPE`
        );
        const activeItems = getActiveItems(response?.data);
        setSectorData(activeItems);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchCountryData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=COUNTRY`
        );
        const activeItems = getActiveItems(response?.data);
        setCountryData(activeItems);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchVarietyData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=REGION`
        );
        const activeItems = getActiveItems(response?.data);
        setVarietyData(activeItems);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchFleshColoryData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=LANGUAGE`
        );
        const activeItems = getActiveItems(response?.data);
        setFlashColor(activeItems);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSectorData();
    fetchCountryData();
    fetchVarietyData();
    fetchFleshColoryData();
  }, []);

  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }
          : { display: "flex", justifyContent: "space-between", width: "100%" }
      }
    >
      <Box sx={boxStyle}>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Filter
            label="Crop Type"
            onChange={handleSelectedSector}
            options={[
              ...filteredSector?.map((item) => {
                return { id: item.id, label: item.name };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
          <>
            <Filter
              label="Region"
              onChange={handleSelectedCountry}
              options={[
                ...filteredVariety?.map((item) => {
                  return { id: item.id, label: item.name };
                }),
              ]}
              selection="single"
              size="medium"
              isDark={isDarkTheme}
            />
          </>
          <Filter
            label="Country"
            onChange={handleSelectedCountry}
            options={[
              ...filteredCountries?.map((item) => {
                return { id: item.id, label: item.name };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Box sx={{ display: "flex", gap: 2 }}>
            <Filter
              label="Language"
              onChange={handleVarietyChange}
              options={[
                ...filteredFlashColor?.map((item) => {
                  return { id: item.id, label: item.name };
                }),
              ]}
              selection="single"
              size="medium"
              isDark={isDarkTheme}
              value={variety}
              dropdownPosition="bottom"
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={
          isMobile
            ? { display: "flex", justifyContent: "space-between" }
            : { display: "flex", flexDirection: "row", gap: "16px" }
        }
      >
        <Search onUpdate={handleSearch} isDark={isDarkTheme} />
        {header !== "Library" && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: "2.5px" }}>
            <Button
              onClick={() => handleSelect("tile")}
              style={{
                ...buttonStyle,
                backgroundColor: grid === "tile" ? "#005CBC" : "#fff",
                color: grid === "tile" ? "#fff" : "#005CBC",
                border: grid === "tile" ? "none" : "1px solid #D7E5F8",
              }}
            >
              <GridOnIcon
                sx={{ color: grid === "tile" ? "#fff" : "#005CBC" }}
              />
            </Button>

            <Button
              onClick={() => handleSelect("list")}
              style={{
                ...buttonStyle,
                backgroundColor: grid !== "tile" ? "#005CBC" : "#fff",
                color: grid !== "tile" ? "#fff" : "#005CBC",
                border: grid !== "tile" ? "none" : "1px solid #D7E5F8",
              }}
            >
              <ViewListOutlinedIcon
                sx={{ color: grid !== "tile" ? "#fff" : "#005CBC" }}
              />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
