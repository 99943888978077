import { Box, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { TableWrapper } from "./style";
import useThemeRender from "../../../theme/useThemeRender";
import { useSelector } from "react-redux";

const columns = [
  {
    field: "reviewedBY",
    headerName: "Review BY",
  },
  {
    field: "reviewedDate",
    headerName: "Reviewed Date",
  },
];


const ApproverHistoryTable = ({approvarHistory}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isDarkTheme = useSelector((state) => state.theme);
  const [approvalHistory, setApprovalHistory] = useState([]);

  useEffect(() => {
    let historyList = [];
    approvarHistory?.filter(item => {
      historyList.push(
        { id: item?.id, reviewedBY: item?.reviewedBY, reviewedDate: item?.reviewedDate}
      );
    });
    setApprovalHistory(approvalHistory);
  }, [approvarHistory])

  return (
    <Box
      sx={{
        padding: "16px",
        background: useThemeRender("cardBackgroundColorLayer2"),
      }}
    >
      <TableWrapper isMobile={isMobile} isDarkTheme={isDarkTheme}>
        <DataGrid
          rows={approvalHistory}
          columns={columns}
          disableRowSelectionOnClick
          hideFooterPagination
          disableColumnFilter
          disableColumnMenu
        />
      </TableWrapper>
    </Box>
  );
};

export default ApproverHistoryTable;
