import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Tab, TabsNavigation } from "pepsico-ds";
import { useSelector } from "react-redux";
import PendingTabData from "./PendingTabData";
import CustomCuratorFilter from "../CustomCuratorFilter";
import { useDispatch } from "react-redux";
import { CURATOR_PAGE_API_CONSTANT } from "../../../constant/constant";
import { getCuratorApiListByPageName } from "../../../API/curatorApi";
import Loading from "../../../utils/Loader";
import { setPendingApprovalData } from "../../../slices/curatorPageSlice";

const PendingApproval = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [tab, setTab] = useState(0);
  const handleClick = (index) => setTab(index);
  const [tabLoading, setTabLoading] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [filteredRow, setFilteredRows] = useState([]);
  const [filterValue, setFilterValue] = useState({
    sector: null,
    country: null,
    subject: null,
  });
  const pendingApprovalListData = useSelector(
    (state) => state.curatorpage
  ).pendingApprovalData;
  const serachText = useSelector((state) => state.curatorPageLookUpData).search;
  const curatorPageLookUpData = useSelector(
    (state) => state.curatorPageLookUpData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getCuratorApiListByPageName(CURATOR_PAGE_API_CONSTANT.pending_approval)
      .then((response) => {
        dispatch(setPendingApprovalData(response));
        setPageData(response);
        setTabLoading(false);
      })
      .catch((error) => {
        console.log("Get Approval API Error:", error?.message);
        setTabLoading(false);
      });
  }, []);

  useEffect(() => {
    const filteredArray = getTabData(tab);
    const filteredRows = {
      documents: [],
      videos: [],
      links: [],
      documentCount: 0,
      videoCount: 0,
      linkCount: 0,
      totalCount: 0,
    };

    if (filterValue?.subject !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.subjects?.includes(filterValue?.subject)) {
          if (currentRow?.contentType === "DOCUMENT") {
            filteredRows.documents = [...filteredRows.documents, currentRow];
            filteredRows.documentCount = filteredRows.documentCount + 1;
          }
          if (currentRow?.contentType === "LINK") {
            filteredRows.links = [...filteredRows.links, currentRow];
            filteredRows.linkCount = filteredRows.linkCount + 1;
          }
          if (currentRow?.contentType === "VIDEO") {
            filteredRows.videos = [...filteredRows.videos, currentRow];
            filteredRows.videoCount = filteredRows.videoCount + 1;
          }
        }
      });
    }

    if (filterValue?.country !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.country?.includes(filterValue?.country)) {
          if (currentRow?.contentType === "DOCUMENT") {
            filteredRows.documents = [...filteredRows.documents, currentRow];
            filteredRows.documentCount = filteredRows.documentCount + 1;
          }
          if (currentRow?.contentType === "LINK") {
            filteredRows.links = [...filteredRows.links, currentRow];
            filteredRows.linkCount = filteredRows.linkCount + 1;
          }
          if (currentRow?.contentType === "VIDEO") {
            filteredRows.videos = [...filteredRows.videos, currentRow];
            filteredRows.videoCount = filteredRows.videoCount + 1;
          }
        }
      });
    }
    if (filterValue?.sector !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.sector?.includes(filterValue?.sector)) {
          if (currentRow?.contentType === "DOCUMENT") {
            filteredRows.documents = [...filteredRows.documents, currentRow];
            filteredRows.documentCount = filteredRows.documentCount + 1;
          }
          if (currentRow?.contentType === "LINK") {
            filteredRows.links = [...filteredRows.links, currentRow];
            filteredRows.linkCount = filteredRows.linkCount + 1;
          }
          if (currentRow?.contentType === "VIDEO") {
            filteredRows.videos = [...filteredRows.videos, currentRow];
            filteredRows.videoCount = filteredRows.videoCount + 1;
          }
        }
      });
    }
    filteredRows.totalCount =
    filteredRows.videoCount +
    filteredRows.linkCount +
    filteredRows.documentCount;

    if (
      filterValue?.subject === null &&
      filterValue?.country === null &&
      filterValue?.sector === null
    ) {
      setFilteredRows([]);
      dispatch(setPendingApprovalData(pageData));
    } else {
      dispatch(setPendingApprovalData(filteredRows));
      setFilteredRows(filteredRows);
    }
  }, [filterValue]);

  useEffect(() => {
    const filteredArray = getTabData(tab);
    const searchedRow = {
      documents: [],
      videos: [],
      links: [],
      documentCount: 0,
      videoCount: 0,
      linkCount: 0,
      totalCount: 0,
    };
    let timer = null;
    if (serachText !== "" && filteredArray?.length > 0) {
      timer = setTimeout(() => {
        filteredArray?.filter((item) => {
          const matchesSearch = Object?.values(item)?.some((value) =>
            String(value)
              ?.toLowerCase()
              ?.includes(serachText?.toLowerCase() || "")
          );
          if (matchesSearch) {
            if (item?.contentType === "DOCUMENT") {
              searchedRow.documents = [...searchedRow.documents, item];
              searchedRow.documentCount = searchedRow.documentCount + 1;
            }
            if (item?.contentType === "LINK") {
              searchedRow.links = [...searchedRow.links, item];
              searchedRow.linkCount = searchedRow.linkCount + 1;
            }
            if (item?.contentType === "VIDEO") {
              searchedRow.videos = [...searchedRow.videos, item];
              searchedRow.videoCount = searchedRow.videoCount + 1;
            }
          }
        });
        searchedRow.totalCount =
          searchedRow.videoCount +
          searchedRow.linkCount +
          searchedRow.documentCount;
        if (serachText === "") {
          dispatch(setPendingApprovalData(pageData));
          setFilteredRows(pageData);
        } else {
          dispatch(setPendingApprovalData(searchedRow));
          setFilteredRows(searchedRow);
        }
      }, 250);
    } else if (serachText === '') {
      dispatch(setPendingApprovalData(pageData));
      setFilteredRows(pageData);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [serachText]);

  const updatedFilterValue = (value, type) => {
    if (type === "subject") {
      setFilterValue((preState) => {
        return {
          ...preState,
          subject: value === undefined ? null : value,
        };
      });
    }

    if (type === "sector") {
      setFilterValue((preState) => {
        return {
          ...preState,
          sector: value === undefined ? null : value,
        };
      });
    }

    if (type === "country") {
      setFilterValue((preState) => {
        return {
          ...preState,
          country: value === undefined ? null : value,
        };
      });
    }
  };

  // FUNCTION THAT RETURNS DATA BASED ON CURRENT TAB
  const getTabData = (currentTab) => {
    switch (currentTab) {
      case 0:
        return [
          pendingApprovalListData.documents,
          pendingApprovalListData.videos,
          pendingApprovalListData.links,
        ].flat();
      case 1:
        return pendingApprovalListData.documents;
      case 2:
        return pendingApprovalListData.videos;
      case 3:
        return pendingApprovalListData.links;
      default:
        return [];
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingBottom: "16px",
      }}
    >
      <CustomCuratorFilter
        pageName={CURATOR_PAGE_API_CONSTANT.pending_approval}
        filters={curatorPageLookUpData}
        updatedFilterValue={updatedFilterValue}
        filterValue={filterValue}
      />
      {tabLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-6">
          <TabsNavigation selectedIndex={tab} isDark={isDarkTheme}>
            <Tab
              iconTrailing={false}
              label={`All ${
                pendingApprovalListData?.totalCount === undefined
                  ? 0
                  : pendingApprovalListData?.totalCount
              } `}
              onClick={() => handleClick(0)}
              orientation="horizontal"
              isDark={isDarkTheme}
            />
            <Tab
              iconTrailing={false}
              label={`Documents ${
                pendingApprovalListData?.documentCount === undefined
                  ? 0
                  : pendingApprovalListData?.documentCount
              } `}
              onClick={() => handleClick(1)}
              orientation="horizontal"
              isDark={isDarkTheme}
            />
            <Tab
              label={`Videos ${
                pendingApprovalListData?.videoCount === undefined
                  ? 0
                  : pendingApprovalListData?.videoCount
              }`}
              iconTrailing={false}
              onClick={() => handleClick(2)}
              isDark={isDarkTheme}
            />
            <Tab
              label={`Links ${
                pendingApprovalListData?.linkCount === undefined
                  ? 0
                  : pendingApprovalListData?.linkCount
              }`}
              iconTrailing={false}
              onClick={() => handleClick(3)}
              isDark={isDarkTheme}
            />
          </TabsNavigation>
          {Object.keys(pendingApprovalListData).length > 0 && (
            <PendingTabData currentTabData={getTabData(tab)} />
          )}
        </div>
      )}
    </Box>
  );
};

export default PendingApproval;
