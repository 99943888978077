import React, { useEffect } from "react";
import DynamicTable from "../../../../utils/Table/DynamicTable";
import { useState } from "react";
import { Typography } from "@mui/material";
import PendingRequest from "./PendingRequest";
import PendingApproval from "./PendingApproval";
import AccessHistory from "./AccessHistory";
const getColumns = (headerName) => {
  const baseColumns = [
    { id: "actions", label: " ", width: "80px" },
    {
      id: "firstName",
      label: "First Name",
      width: "200px",
    },
    {
      id: "lastName",
      label: "LAST NAME",
      width: "200px",
    },
    { id: "email", label: "EMAIL", width: "200px" },
    { id: "company", label: "COMPANY/ORGANIZATION", width: "200px" },
    { id: "country", label: "COUNTRY", width: "200px" },
    {
      id: "sponsorEmail",
      label: "SPONSOR'S EMAIL",
      width: "200px",
      headerWidth: "70%",
    },
  ];

  // Add "Invitation Status" column only for "Invitation History" header
  if (headerName === "Access:Invitation history") {
    baseColumns.push({
      id: "status",
      label: "INVITATION STATUS",
      width: "250px",
      headerWidth: "75%",
      cellStyle: "cellStyle",
    });
  }
  if (headerName === "Access history") {
    return [
      { id: "actions", label: " ", width: "80px" },
      { id: "name", label: "Name", width: "200px" },
      { id: "email", label: "Email Address", width: "200px" },
      { id: "accessType", label: "Access Type", width: "200px" },
      { id: "organization", label: "Organization", width: "200px" },
      { id: "contactNo", label: "Contact’s phone", width: "200px" },
      { id: "sponsorEmail", label: "Pepsico sponsor’s email", width: "200px" },
    ];
  }
  return baseColumns;
};

export default function InvitationTable({
  searchText,
  rows,
  header,
  user,
  setActionData,
}) {
  console.log(rows, "----invitation-----");

  const [expandedRow, setExpandedRow] = useState(null);
  useEffect(() => {
    setExpandedRow(null);
  }, [header]);

  const toggleRowExpansion = (rowId) => {
    console.log(rowId, "-rowID--------");

    setExpandedRow((prev) => (prev === rowId ? null : rowId));
  };

  const columns = getColumns(header);
  const invitationStatusRenderer = (status, row) => {
    let color, text, backgroundColor;
    switch (status) {
      case "SENT":
        backgroundColor = "#AAD2E2";

        text = "SENT";
        break;
      case "ACCEPTED":
        backgroundColor = "#B3DACB";
        text = "ACCEPTED";
        break;

      default:
        color = "black";
        text = status;
        break;
    }

    return (
      <Typography
        sx={{
          display: "inline-block", // Ensure the background wraps the text only
          backgroundColor,
          color: "var(--Colors-Black, #000)", // Color
          fontFeatureSettings: "'liga' off, 'clig' off", // Font feature settings
          fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family
          fontSize: "var(--Font-Size-XSmall, 12px)", // Font size
          fontStyle: "normal", // Font style
          fontWeight: 400, // Font weight
          lineHeight: "var(--Line-Height-XSmall, 18px)", // Line height
          padding: "2px 8px", // Optional: Add some padding for better appearance
          borderRadius: "4px", // Optional: Add rounded corners
        }}
      >
        {text}
      </Typography>
    );
  };

  const renderActions = (row) =>
    row.status !== "ACCEPTED" &&
    (expandedRow ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 12 13"
        fill="none"
      >
        <path
          d="M3.705 8.07121L6 5.78121L8.295 8.07121L9 7.36621L6 4.36621L3 7.36621L3.705 8.07121Z"
          fill="#005CBC"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M4.94 5.72656L8 8.7799L11.06 5.72656L12 6.66656L8 10.6666L4 6.66656L4.94 5.72656Z"
          fill="#005CBC"
        />
      </svg>
    ));

  const ChildComponent = React.memo(({ data, header }) => {
    setActionData(data);
    const renderHistoyChildPage = () => {
      switch (data.status) {
        case "SENT":
          return <PendingRequest data={data} />;

        default:
          return null;
      }
    };

    const pendingApprovalChild = () => {
      return <PendingApproval data={data} />;
    };

    const AccessHistoryChild = () => {
      return <AccessHistory data={data} user={user} />;
    };

    return (
      <div className="w-full">
        {header === "Access:Invitation history"
          ? renderHistoyChildPage()
          : header === "Access history"
          ? AccessHistoryChild()
          : pendingApprovalChild()}
      </div>
    );
  }, areEqual);

  // Custom comparison function for React.memo
  function areEqual(prevProps, nextProps) {
    // Compare `data` and `header` props
    return (
      prevProps.data === nextProps.data && prevProps.header === nextProps.header
    );
  }

  const renderChild = (row) => <ChildComponent data={row} header={header} />;

  return (
    <DynamicTable
      columns={columns}
      rows={rows}
      searchText={searchText}
      renderActions={(row) => renderActions(row)}
      onRowClick={(row) => {
        if (row.status !== "ACCEPTED") {
          toggleRowExpansion(row.id);
        }
      }}
      expandedRow={expandedRow}
      setExpandedRow={setExpandedRow}
      showColumnFilters={false}
      rowsPerPage={10}
      renderChildComponent={renderChild}
      cellRenderer={invitationStatusRenderer}
    />
  );
}
