import { Box } from "@mui/material";
import ListComponent from "./ListComponent";

const Popover = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: {
          xs: "column",
          md: "row",
        },
        gap: {
          xs: "16px",
          md: "0px",
        },
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            md: "100%",
          },
        }}
      >
        <ListComponent title={"Forums"} />
      </Box>
    </Box>
  );
};

export default Popover;
