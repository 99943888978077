import { Box, Typography, Card, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./MobileScreenDocument.css";
import Button from "@mui/material/Button";
import useThemeRender from "./../../theme/useThemeRender";
import { postAPI, getAPI } from "../../API/ApiService";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../constant/apiUrl";
import { Link } from "react-router-dom";
import DocumentSummary from "./DocumentSummary";
import DocumentDetails from "./DocumentDetails";
import { setFavourite } from "../../slices/internalPageSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Disclaimer from "./Disclaimer";
import Loading from "../../utils/Loader";
import { showErrorToast } from "../../utils/Toast/toast";
export default function Document() {
  const location = useLocation();
  const documentCardData = location.state;
  const [documentData, setDocumentData] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { favourite } = useSelector((store) => store.internalPage);

  const backgroundColor = useThemeRender("backgroundColorLayer1");
  const textColor = useThemeRender("textColorLayer2");
  const userId = 1;
  const contentId = documentCardData?.contentId
    ? documentCardData?.contentId
    : documentCardData?.id;
  const isMobile = useMediaQuery("(max-width: 600px)");
  const handleAddFavourite = () => {
    const addFavorite = async () => {
      try {
        const response = await postAPI(
          `${BASE_URL}/favorites/save?contentId=${contentId}&userId=1&favorite=${!documentData?.isFavorite}`
        );
        fetchData();
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    addFavorite();
  };

  const handleLike = () => {
    const newData = {
      contentId: contentId,
      likes: !documentData?.isLiked,
      dislikes: documentData?.isDisliked,
    };
    const addLike = async () => {
      try {
        const response = await postAPI(
          `${BASE_URL}/content-metrics/save`,
          newData
        );
        if (response.status == 200) {
          dispatch(setFavourite(favourite + 1));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    addLike();
  };
  const token = localStorage.getItem("auth_token");
  const handleDownload = async() => {
    setLoading(true)
    console.log(contentId,'contentId')
    try {
      const downloadResponse = await axios.get(
        `https://flag-dev-c5gkcedqchceesa8.eastus-01.azurewebsites.net/fritolay/file-manager/download-by-content-id?contentId=${contentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/octet-stream",
          },
          responseType: "arraybuffer",
        }
      );
      if (downloadResponse?.data) {
        const blob = new Blob([downloadResponse.data], {
          type: "application/pdf", 
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${documentCardData?.title}.pdf`; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false)
      }
    } catch (error) {
      showErrorToast("Failed to download Pdf");
      setLoading(false)
      return null;
    }
  }

  const handleDisLike = () => {
    const newData = {
      contentId: contentId,
      likes: documentData?.isLiked,
      dislikes: !documentData?.isDisliked,
    };
    const addDisLike = async () => {
      try {
        const response = await postAPI(
          `${BASE_URL}/content-metrics/save`,
          newData
        );
        if (response.status == 200) {
          dispatch(setFavourite(favourite + 1));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    addDisLike();
  };
  const fetchData = async () => {
    setLoading(true);
    if (contentId !== undefined) {
      try {
        const response = await getAPI(
          `${BASE_URL}/content/${contentId}/user/${userId}`
        );
        if (response.status === 200) {
          setDocumentData(response.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [documentCardData, favourite]);

  if (loading) return <Loading />;
  return (
    <Box
      sx={{
        display: "flex",
        padding: {
          xs: "0px",
          sm: "30px",
        },
        paddingTop: { xs: "16px" },
        paddingBottom: "24px",
        flexDirection: "column",
        gap: "var(--Spacing-spacing-24, 24px)",
        margin: 0,
        backgroundColor: backgroundColor,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", sm: "space-between" },
          alignItems: { xs: "flex-start", sm: "center" },
          gap: { xs: "var(--Spacing-spacing-24, 24px)", sm: "10px" },
          alignSelf: { xs: "stretch", sm: "auto" },
          flexDirection: { xs: "column", sm: "row" },
          padding: { xs: "16px", sm: "0" },
        }}
      >
        <Typography
          sx={{
            color: textColor,
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H4, 28px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H4, 40px)",
            width: { xs: "100%", sm: "70%" },
          }}
        >
          {documentCardData?.title}
        </Typography>
        <div
          style={{ width: isMobile ? "100%" : "250px" }}
        >
          <Button
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "stretch",
              borderRadius: "var(--Corner-radius-effect-corner-3, 24px)",
              background: "var(--Tokens-Button-Primary, #005CBC)",
              width: "100%",
            }}
            onClick={handleDownload}
          >
            <Box
              sx={{
                display: "flex",
                height: "var(--Spacing-spacing-40, 40px)",
                padding:
                  "var(--Spacing-spacing-03, 8px) var(--Spacing-spacing-05, 24px)",
                justifyContent: "center",
                alignItems: "center",
                gap: "var(--Spacing-spacing-03, 8px)",
              }}
            >
              <Typography
                sx={{
                  color: "var(--Colors-White, #FFF)",
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  fontSize: "var(--Font-Size-Body, 16px)",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "var(--Line-Height-Body, 22px)",
                  textTransform: "none",
                }}
              >
                Open document
              </Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                  fill="white"
                />
              </svg>
            </Box>
          </Button>
        </div>
      </Box>
      <DocumentSummary
        handleAddFavourite={handleAddFavourite}
        documentData={documentData}
        handleLike={handleLike}
        handleDisLike={handleDisLike}
      />
      <DocumentDetails documentData={documentData} />
      <Disclaimer documentData={documentData} />
    </Box>
  );
}
