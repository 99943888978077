import React, { useEffect, useState } from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { BASE_URL } from "../constant/apiUrl";
import {getAPI} from "../API/ApiService";
import Loading from "../utils/Loader";
import { useDispatch } from "react-redux";
import { setCountry, setFlashColor, setIpOwnerShip, setSearch, setSector, setVariety } from "../slices/internalPageSlice";

export default function Favorites() {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(null);
  const [error, setError] = useState(null);
  const userId = 1
  const dispatch = useDispatch();
// TODO : WE HAVE USE CONSTANT API URL, WILL IMPLEMENT ENV CONFIGURATION ONCE IT WILL CONFIGURE
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const
         response = await getAPI( `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=Potato%20Variety%20Information&userId=${userId}`);
       // const response = await getAPI(
        //   `${BASE_URL}/internal-pages/page-info/find-by-id/{pageId}?pageId=3`
        // );
        setPageData(response.data);
        dispatch(setSector(""));
        dispatch(setCountry(""));
        dispatch(setSearch(""));
        dispatch(setVariety(""));
        dispatch(setFlashColor(""));
        dispatch(setIpOwnerShip(""));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
  loading ?
    <Loading/>
    :
   <InternalPageLayout pageData={pageData} header={"My favorites"} />
  ) 
}
