import React from "react";
import { Button } from "pepsico-ds";
import "./HomePageCarousal.css";
import { Box } from "@mui/material";

const OverlayContent = React.memo(
  ({ data }) => {
    const handleNavigation = (link) => {
      window.open(link, "_blank");
    };

    return (
      <div className="relative text-white rounded w-full md:w-[493px] md:h-[216px] flex flex-col gap-6 items-start justify-start">
        {/* Text centered for small screens, left-aligned for larger screens */}
        <span className="font-bold text-3xl flex flex-wrap md:text-left">
          {data?.headline}
        </span>
        <span className="font-serif text-[17px] flex flex-wrap md:text-left">
          {data?.description}
        </span>
        <Box sx={{ mt: "auto" }}>
          <Button
            style={{ background: "white" }}
            className="px-12 md:absolute md:bottom-0"
            size="large"
            text={`${data?.buttonText}`}
            variant="secondary"
            iconTrailing="arrow_right_alt"
            onClick={() => handleNavigation(data?.descriptionLink)}
          />
        </Box>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if the data prop changes
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default OverlayContent;
