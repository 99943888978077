import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useThemeRender from "../../../theme/useThemeRender";
import DeniedDetailFirstContainer from "./DeniedDetailFirstContainer";
import DeniedDetailLastContainer from "./DeniedDetailLastContainer";
import DeniedDetailArtifactManagement from "./DeniedDetailArtifactManagement";
import "./DeniedDetail.css";
import CuratorApprovedStatus from "../CuratorApprovedStatus";
import { BASE_URL } from "../../../constant/apiUrl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  setDeniedHistoryContentLevel,
  setDeniedHistoryContentType,
  setDeniedHistoryVisibility,
  setSelectedCuratorPage,
} from "../../../slices/curatorPageSlice";
import { useDispatch } from "react-redux";
import { getAPI } from "../../../API/ApiService";
import { formatDate } from "../../../utils/function";
function DeniedArtifactHistoryDetails() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [detailData, setDetailData] = useState();
  const queryParams = useParams();
  const cardBackgroundColorLayer1 = useThemeRender("cardBackgroundColorLayer1");
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const insideCardBackgroundColorLayer3 = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/curator");
    dispatch(setDeniedHistoryContentType(""));
    dispatch(setDeniedHistoryVisibility(""));
    dispatch(setDeniedHistoryContentLevel(""));
    // if (pageName == "approvedHistory") {
    //   dispatch(setSelectedCuratorPage("Artifact history"));
    // } else if (pageName == "deniedHistory") {
      dispatch(setSelectedCuratorPage("Denied history"));
  //  }
  };

  useEffect(() => {
    const fetchDeniedHistory = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/curator/${queryParams?.id}/denied-history`
        );
        if (response?.status == "200") {
          setDetailData(response.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

   fetchDeniedHistory();
  }, [queryParams]);
  return (
    <Box
      sx={{
        display: "flex",
        padding: {
          xs: "16px",
          sm: "24px",
        },
        flexDirection: "column",
        gap: "var(--Spacing-spacing-24, 24px)",
        paddingTop: "30px",
        backgroundColor: useThemeRender("backgroundColorLayer1"),
      }}
    >
      <Box onClick={() => handleNavigate()} sx={{ cursor: "pointer" }}>
        <Box
          sx={{
            display: "flex",
            padding: "var(--Spacing-spacing-03, 8px) 0px",
            gap: "var(--Spacing-spacing-03, 8px)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
              fill="#005CBC"
            />
          </svg>
          <Typography
            sx={{
              color: "var(--Tokens-Button-Borderless, #005CBC)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Body, 16px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Body, 22px)",
              mt: "2px",
            }}
          >
            Back to denied history
          </Typography>
        </Box>
      </Box>
      {/* {pageName == "deniedHistory" && ( */}
        <Box
          sx={{
            background: cardBackgroundColorLayer1,
          }}
          className="custom-box"
        >
          <Typography
            sx={{
              color: textColorLayer2,
              fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
              fontSize: "var(--Font-Size-H6, 18px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-H6, 28px)",
            }}
          >
            Denied History
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
              padding: "16px",
              background: cardBackgroundColorLayer1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box
                  className="DetailCardBox"
                  sx={{
                    background: insideCardBackgroundColorLayer3,
                  }}
                >
                  <Typography
                    className="DetailCardGridFirstContent"
                    sx={{
                      color: textColorLayer4,
                    }}
                  >
                    Name
                  </Typography>
                  <Typography
                    className="DetailCardGridSecondContent"
                    sx={{
                      color: textColorLayer4,
                    }}
                  >
                    {detailData?.rejectedBy}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  className="DetailCardBox"
                  sx={{
                    background: insideCardBackgroundColorLayer3,
                  }}
                >
                  <Typography
                    className="DetailCardGridFirstContent"
                    sx={{
                      color: textColorLayer4,
                    }}
                  >
                    Date
                  </Typography>
                  <Typography
                    className="DetailCardGridSecondContent"
                    sx={{
                      color: textColorLayer4,
                    }}
                  >
                    {formatDate(detailData?.uploadedDate)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  className="DetailCardBox"
                  sx={{
                    background: insideCardBackgroundColorLayer3,
                  }}
                >
                  <Typography
                    className="DetailCardGridFirstContent"
                    sx={{
                      color: textColorLayer4,
                    }}
                  >
                    Explanation
                  </Typography>
                  <Typography
                    className="DetailCardGridSecondContent"
                    sx={{
                      color: textColorLayer4,
                    }}
                  >
                    {detailData?.rejectedReason}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      {/* )} */}
      <Box
        sx={{
          background: useThemeRender("cardBackgroundColorLayer1"),
        }}
        className="custom-box"
      >
        <DeniedDetailFirstContainer detailData={detailData} />
        <Box
          sx={{
            background: useThemeRender("cardBackgroundColorLayer2"),
          }}
          className="custom-container"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box
                className="DetailCardBox"
                sx={{
                  background: useThemeRender("insideCardBackgroundColorLayer3"),
                }}
              >
                <Typography
                  className="DetailCardGridFirstContent"
                  sx={{
                    color: useThemeRender("textColorLayer4"),
                  }}
                >
                  Crop type
                </Typography>
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: useThemeRender("textColorLayer4"),
                  }}
                >
                  {detailData?.cropType}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                className="DetailCardBox"
                sx={{
                  background: useThemeRender("insideCardBackgroundColorLayer3"),
                }}
              >
                <Typography
                  className="DetailCardGridFirstContent"
                  sx={{
                    color: useThemeRender("textColorLayer4"),
                  }}
                >
                  Keywords
                </Typography>
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: useThemeRender("textColorLayer4"),
                  }}
                >
                  {detailData?.keywords}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            background: useThemeRender("cardBackgroundColorLayer2"),
          }}
          className="custom-container"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box
                className="DetailCardBox"
                sx={{
                  background: useThemeRender("insideCardBackgroundColorLayer3"),
                }}
              >
                <Typography
                  className="DetailCardGridFirstContent"
                  sx={{
                    color: useThemeRender("textColorLayer4"),
                  }}
                >
                  Have you received legal approval?
                </Typography>
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: useThemeRender("textColorLayer4"),
                  }}
                >
                  {detailData?.isLegalReview}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <DeniedDetailLastContainer detailData={detailData} />
        <Typography
          sx={{
            color: useThemeRender("textColorLayer2"),
          }}
          className="DeniedTitle"
        >
          Artifact management
        </Typography>
        <DeniedDetailArtifactManagement detailData={detailData} />
        {/* {pageName == "deniedHistory" ? ( */}
          <CuratorApprovedStatus
            title="Rejection history"
            header1="Rejected by"
            header2="Rejected data"
            detailData={detailData}
          />
        {/* ) : (
          <CuratorApprovedStatus
            title="Approver "
            header1="Reviewed by"
            header2="Reviewed date"
            detailData={detailData}
          />
        )} */}
      </Box>
    </Box>
  );
}

export default DeniedArtifactHistoryDetails;
