import React from "react";
import { Box, Button, Typography } from "@mui/material";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { darkTheme } from "../../theme/Theme";
import {
  IsRequiredLabel,
  Label,
  UploadImageLabel,
  UploadImageWrapper,
} from "./dropzoneStyle";
import FileFormat from "./FileFormat";

const DropZone = ({
  label,
  getRootProps,
  getInputProps,
  isDarkTheme,
  isRequired = false,
  informativeText = "Drag and drop your file here",
  uploadImageLabel = "Upload image",
  isDisabled = false,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Label isDarkTheme={isDarkTheme} isDisabled={isDisabled}>
          {label}
        </Label>
        {isRequired && <IsRequiredLabel>Required *</IsRequiredLabel>}
      </Box>
      <UploadImageWrapper {...getRootProps()} isDisabled={isDisabled}>
        <input {...getInputProps()} />
        <FileFormat />

        <Typography
          variant="body1"
          sx={{
            textAlign: { xs: "center" },
            width: { xs: "80%" },
            color: isDisabled
              ? "#A0A0A0"
              : isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          {informativeText}
        </Typography>
        <Button
          variant="text"
          sx={{
            mt: 1,
            borderRadius: "24px",
            border: isDisabled ? "none" : "1px solid #005CBC",
            px: 2,
            color: isDisabled && "#A0A0A0",
          }}
          startIcon={<UploadOutlinedIcon />}
        >
          <UploadImageLabel variant="body2" isDisabled={isDisabled}>
            {uploadImageLabel}
          </UploadImageLabel>
        </Button>
      </UploadImageWrapper>
    </Box>
  );
};

export default DropZone;
