import {
  Autocomplete,
  Box,
  Popper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import useThemeRender from "../../theme/useThemeRender";
import { useSelector } from "react-redux";
import { setSelectedCuratorPage } from "../../slices/curatorPageSlice";
import { setSearchText } from "../../slices/curatorPageLookupSlice";
import { useDispatch } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import { autoCompletStyles } from "../../utils/FieldStyles/FieldStyles";
import { Search } from "pepsico-ds";

const Header = () => {
  const { selectedCuratorPage } = useSelector((store) => store.curatorpage);
  const isDarkTheme = useSelector((state) => state.theme);
  const options = ["Pending approvals", "Yearly review", "Artifact history","Denied history"];
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedCuratorPage) dispatch(setSelectedCuratorPage(options[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    dispatch(setSelectedCuratorPage(newValue));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          justifyContent: "start",
        }}
      >
        <Typography
          sx={{
            color: useThemeRender("textColorLayer2"),
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H4, 28px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H4, 40px)",
          }}
        >
          Curator
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            maxWidth: "300px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--Spacing-spacing-8, 8px)",
          }}
        >
          <Autocomplete
            onChange={handleChange}
            value={selectedCuratorPage}
            options={options}
            getOptionLabel={(option) => option}
            PopperComponent={(props) => (
              <Popper
                {...props}
                sx={{
                  "& .MuiAutocomplete-listbox": {
                    background: isDarkTheme
                      ? darkTheme.backgroundColorLayer3
                      : lightTheme.backgroundColorLayer3,
                    color: isDarkTheme
                      ? darkTheme.textColorLayer1
                      : lightTheme.textColorLayer1,
                    "& .MuiAutocomplete-option:hover": {
                      backgroundColor: isDarkTheme
                        ? darkTheme.dropdownHoverColorLayer3
                        : lightTheme.dropdownHoverColorLayer3,
                    },
                  },
                }}
              />
            )}
            sx={{
              ...autoCompletStyles(isDarkTheme, isMobile, darkTheme),
              width: isMobile ? "100%" : "220px",
            }}
            ListboxProps={{
              sx: {
                backgroundColor: isDarkTheme
                  ? darkTheme.backgroundColorLayer1
                  : "white",
                "& .MuiAutocomplete-option": {
                  color: isDarkTheme ? "white" : "black",
                  "&:hover": {
                    backgroundColor: isDarkTheme ? "#333" : "#f0f0f0",
                  },
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Box>
        <Search
          className="flex justify-center"
          onSearch={(e) => {
            console.log('onSearch', e);
          }}
          onSelectOption={() => {}}
          onUpdate={(e) => {
            console.log('onUpdate', e);
            dispatch(setSearchText(e));
          }}
          placeholderText="Type a search keyword"
          size="medium"
          isDark={isDarkTheme}
        />
      </Box>
    </Box>
  );
};

export default Header;
