import React from "react";

const FileFormat = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9752_16743)">
        <path
          d="M6.45 0C4.69 0 3.266 1.44 3.266 3.2L3.25 28.8C3.25 30.56 4.674 32 6.434 32H25.65C27.41 32 28.85 30.56 28.85 28.8V9.6L19.25 0H6.45ZM17.65 11.2V2.4L26.45 11.2H17.65Z"
          fill="#A0A0A0"
        />
      </g>
      <defs>
        <clipPath id="clip0_9752_16743">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileFormat;
