import React, { useState } from "react";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import { Box, Typography, useMediaQuery } from "@mui/material";
import UploadDropDown from "./UploadDropDown";
import CustomSearch from "../../utils/CustomSearch/CustomSearch";
import UploadRenderPage from "./UploadRenderPage";

export default function UploadLayout() {
  const [search, setSearch] = useState("");
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const { select } = useSelector((state) => state.uploadHistory);
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  return (
    <div
      className={`${isMobile ? "" : "px-[83px]"} py-4`}
      style={{
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer1
          : lightTheme.backgroundColorLayer1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          padding: "var(--Spacing-spacing-24, 24px)",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "var(--Spacing-spacing-24, 24px)",
          flexShrink: 0,
          boxSizing: "border-box",
          overflowY: "auto",
        }}
      >
        <Typography
          sx={{
            alignSelf: "stretch",
            color: isDarkTheme
              ? darkTheme.textColorLayer2
              : lightTheme.textColorLayer2,
            fontFeatureSettings: "'liga' off, 'clig' off", // Disabling specific font features
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)", // Font family
            fontSize: "var(--Font-Size-H5, 28px)", // Font size for H5
            fontStyle: "normal", // Normal style
            fontWeight: 700, // Bold font weight
            lineHeight: "var(--Line-Height-H5, 32px)", // Line height
          }}
        >
          Upload content
        </Typography>
        <Box
          sx={
            isMobile
              ? {
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  width: "100%",
                }
              : {
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }
          }
        >
          <UploadDropDown />
          {select !== "History" && <CustomSearch handleSearch={handleSearch} />}
        </Box>
        <UploadRenderPage search={search} />
      </Box>
    </div>
  );
}
