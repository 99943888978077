import { Box, Typography } from "@mui/material";

import LoginPepsiSvg from "./LoginPepsiSvg";

const LoginFooter = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Column on small screens, row on medium and larger
        gap: { xs: "16px" }, // Gap for small screens
        justifyContent: "space-between",
        bgcolor: "black", // Background color
        width: "100%", // Set width responsively
        padding: 2, // Responsive padding
        height: { xs: "139px", md: "69px" }, // Responsive height
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "131px",
          height: "37px",
        }}
      >
        <LoginPepsiSvg />
      </Box>

      <Box sx={{ color: "white", textAlign: { xs: "left", md: "right" } }}>
        <Typography
          sx={{
            color: "var(--PepsiCo-White, #FFF)", // Text color
            fontFeatureSettings: "'liga' off, 'clig' off", // Font feature settings
            fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family
            fontSize: "var(--Font-Size-XSmall, 12px)", // Font size
            fontStyle: "normal", // Font style
            fontWeight: 400, // Font weight
            lineHeight: "var(--Line-Height-XSmall, 18px)", // Line height
          }}
        >
          Disclaimer: The contents of this site are for the private use of the
          Frito-Lay, Inc., their growers and partners.
        </Typography>
        <Typography
          sx={{
            color: "var(--PepsiCo-White, #FFF)", // Text color
            fontFeatureSettings: "'liga' off, 'clig' off", // Font feature settings
            fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family
            fontSize: "var(--Font-Size-XSmall, 12px)", // Font size
            fontStyle: "normal", // Font style
            fontWeight: 400, // Font weight
            lineHeight: "var(--Line-Height-XSmall, 18px)", // Line height
          }}
        >
          <span style={{ display: "inline-block", textAlign: "right" }}>
            ©2024 Frito-Lay Agricultural. All Rights Reserved.
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginFooter;
