import React from "react";
import { Box, Button, Card, Typography, useMediaQuery } from "@mui/material";
import useThemeRender from "../../theme/useThemeRender";
import { useNavigate } from "react-router-dom";
import forum from "../../assets/forum.png";
function ForumMainPageListView({ data }) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const textColorLayer1 = useThemeRender("textColorLayer1");
  const textColorLayer5 = useThemeRender("textColorLayer5");
  const boxShadowlayerColor1 = useThemeRender("boxShadowlayerColor1");
  const insideCardBackgroundColorLayer6 = useThemeRender(
    "insideCardBackgroundColorLayer6"
  );
  const backgroundColorLayer2 = useThemeRender("backgroundColorLayer2");
  const navigate = useNavigate();
  const handleNavigate = (item) => {
    navigate("/frito-forum-detail-page", { state: item });
  };
  return (
    <Card
      sx={{
        display: "flex",
        padding: "var(--Spacing-spacing-24, 24px)",
        alignItems: "flex-start",
        alignContent: "flex-start",
        gap: "24px var(--Spacing-spacing-24, 24px)",
        borderRadius: "var(--Corner-radius-effect-corner-2, 8px)",
        background: backgroundColorLayer2,
        boxShadow:
          "var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
        flexWrap: "wrap",
      }}
    >
      {data?.map((item) => (
        <Box
          key={item?.id}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "24px",
            background: insideCardBackgroundColorLayer1,
            borderRadius: "8px",
            boxShadow: `0px 0px 24px 0px ${boxShadowlayerColor1}`,
            width: "100%",
          }}
        >
          <div className="flex flex-row gap-3">
            <img
              src={forum}
              style={{
                height: "auto",
                width: "194px",
                display: isMobile ? "none" : "block",
              }}
            />
            <div className="flex flex-col" style={{ flex: 1 }}>
              <Typography
                sx={{
                  color: textColorLayer5,
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
                  fontSize: "var(--Font-Size-H5, 24px)",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "var(--Line-Height-H5, 32px)",
                  alignSelf: "stretch",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  color: textColorLayer5,
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  fontSize: "var(--Font-Size-Body, 16px)",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "var(--Line-Height-Body, 22px)",
                }}
              >
                {item.description}
              </Typography>

              <div className="flex flex-row gap-2">
                <Typography
                  style={{
                    color: textColorLayer5,
                    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                    fontSize: "var(--Font-Size-Body, 16px)",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "var(--Line-Height-Body, 22px)",
                  }}
                >
                  Subjects
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {item?.subjects?.map((val, index) => (
                    <Box
                      key={Math.random()}
                      sx={{
                        display: "flex",
                        height: "24px",
                        padding:
                          "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-02, 4px)",
                        alignItems: "center",
                        gap: "2px",
                        borderRadius:
                          "var(--Corner-radius-effect-corner-1, 4px)",
                        background: insideCardBackgroundColorLayer6,
                      }}
                    >
                      <Typography
                        sx={{
                          color: textColorLayer1,
                          fontFeatureSettings: "'liga' off, 'clig' off",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "133%",
                        }}
                      >
                        {val}
                      </Typography>
                    </Box>
                  ))}
                  {item?.subjects?.length > 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        height: "24px",
                        padding:
                          "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-02, 4px)",
                        alignItems: "center",
                        gap: "2px",
                        borderRadius:
                          "var(--Corner-radius-effect-corner-1, 4px)",
                        background: insideCardBackgroundColorLayer6,
                      }}
                    >
                      <Typography
                        sx={{
                          color: textColorLayer1,
                          fontFeatureSettings: "'liga' off, 'clig' off",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "133%",
                        }}
                      >
                        +{item?.subjects?.length - 3} more
                      </Typography>
                    </Box>
                  )}
                </Box>
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
                onClick={() => handleNavigate(item)}
              >
                <Button
                  sx={{
                    display: "flex",
                    height: "var(--Spacing-spacing-32, 32px)",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "4px",
                    borderRadius:
                      "var(--Corner-radius-corner-radius-large, 24px)",
                    border:
                      "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#005CBC",
                      fontWeight: 700,
                      fontSize: "14px",
                      textTransform: "none",
                    }}
                  >
                    View Details
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M6.99998 0.333008L5.82498 1.50801L10.475 6.16634H0.333313V7.83301H10.475L5.82498 12.4913L6.99998 13.6663L13.6666 6.99967L6.99998 0.333008Z"
                      fill="#005CBC"
                    />
                  </svg>
                </Button>
              </Box>
            </div>
          </div>
        </Box>
      ))}
    </Card>
  );
}

export default ForumMainPageListView;
