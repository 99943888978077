import React, { useState } from "react";
import { Box, Typography, Card, useMediaQuery, Button } from "@mui/material";
import {
  cardContainer,
  linkContainer,
  linkTypography,
  buttonStyle,
} from "./carousalStyle";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import image from "../../../assets/spotlight3.png";
import useThemeRender from "../../../theme/useThemeRender";
import { formatDate } from "../../../utils/function";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { postAPI, patchAPI } from "../../../API/ApiService";
import { BASE_URL } from "../../../constant/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { setFavourite } from "../../../slices/internalPageSlice";
import {
  setSummaryDetails,
  setViewPageName,
  setViewSummaryDrawer,
} from "../../../slices/viewSummarySlice";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { showErrorToast } from "../../../utils/Toast/toast";
export default function Expanded({ data, content, flexDirection, heading }) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const textColorLayer1 = useThemeRender("textColorLayer1");
  const boxShadowlayerColor1 = useThemeRender("boxShadowlayerColor1");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { favourite } = useSelector((store) => store.internalPage);
  const addViewCount = async (contentId,goto,card) => {
    try {
      const response = await patchAPI(
        `${BASE_URL}/content/${contentId}`,
        {}
      );
      if (response?.status === 200) {
        navigate(goto, { state: card });
      }
    } catch (err) {
      showErrorToast(err.message);
    } finally {
      setLoading(false);
    }
  };
  const handleNavigate = (card, pageName) => {
    const contentId = card?.contentId ? card?.contentId : card?.id;
    if (pageName == "Link") {
      addViewCount(contentId,"/link",card);
    } else if (pageName == "Document") {
      addViewCount(contentId,"/document",card);
    } else if (pageName == "Video") {
      console.log(pageName);
    }
  };

  const handleAddFavourite = (card) => {
    const contentId = card?.contentId ? card?.contentId : card?.id;
    const fetchData = async () => {
      try {
        const response = await postAPI(
          `${BASE_URL}/favorites/save?contentId=${contentId}&userId=1&favorite=${!card.favorite}`
        );
        dispatch(setFavourite(favourite + 1));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };
  const handleOpenSummary = (details) => {
    dispatch(setViewSummaryDrawer(true));
    dispatch(setViewPageName(content));
    dispatch(setSummaryDetails(details));
  };
  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
          padding: "15px 0px",
        }}
      >
        {/* Slide Track */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            flexWrap: "wrap",
          }}
        >
          {/* Cards */}
          {data?.map((card) => (
            <Card
              key={card.id}
              sx={{
                width: "100%",
                maxWidth: "580px",
                flex: "0 0 auto",
                height: "auto",
                boxShadow: `0px 0px 24px 0px ${boxShadowlayerColor1}`,
                borderRadius: "8px",
                background: "000",
              }}
            >
              <Box
                sx={{
                  ...cardContainer,
                  background: insideCardBackgroundColorLayer1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    padding: "var(--Spacing-spacing-0, 0px)",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "var(--Spacing-spacing-12, 12px)",
                    alignSelf: "stretch",
                  }}
                >
                  <img src={image} alt="image" className="h-[198px] w-full" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "var(--Spacing-spacing-8, 8px)",
                    alignSelf: "stretch",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: textColorLayer2,
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily:
                        "var(--Typography-Font-Family-Heading, Inter)",
                      fontSize: "var(--Font-Size-H5, 24px)",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "var(--Line-Height-H5, 32px)", // 133.333%
                      alignSelf: "stretch",
                      minHeight: "64px", // 2 lines * lineHeight (32px)
                      maxHeight: "64px",
                      overflow: "hidden", // Hides overflowing content
                      textOverflow: "ellipsis", // Adds ellipsis
                      display: "-webkit-box", // Required for multiline ellipsis
                      WebkitBoxOrient: "vertical", // Required for multiline ellipsis
                      WebkitLineClamp: 2, // Limit to 2 lines
                    }}
                  >
                    {card?.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: textColorLayer2,
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                      fontSize: "var(--Font-Size-Body, 16px)",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "var(--Line-Height-Body, 22px)", // 137.5%
                      minHeight: "64px", // 2 lines * lineHeight (32px)
                      maxHeight: "64px",
                      overflow: "hidden", // Hides overflowing content
                      textOverflow: "ellipsis", // Adds ellipsis
                      display: "-webkit-box", // Required for multiline ellipsis
                      WebkitBoxOrient: "vertical", // Required for multiline ellipsis
                      WebkitLineClamp: 2, // Limit to 2 lines
                    }}
                  >
                    {card?.description}
                  </Typography>
                  <Box
                    sx={
                      isMobile
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }
                        : {
                            display: "flex",
                            width: "100%",
                            alignItems: "flex-start",
                            gap: "var(--Spacing-spacing-12, 12px)",
                            flexDirection: flexDirection,
                          }
                    }
                  >
                    <Box
                      sx={{
                        width: isMobile ? "100%" : "auto",
                        flexShrink: 0,
                        display: "flex",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: textColorLayer1,
                          fontFamily:
                            "var(--Typography-Font-Family-Body, Poppins)",
                          fontSize: "var(--Font-Size-Body, 14px)",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "var(--Line-Height-Body, 18px)", // 128.571%
                        }}
                      >
                        Date:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: textColorLayer1,
                          fontFamily:
                            "var(--Typography-Font-Family-Body, Poppins)",
                          fontSize: "var(--Font-Size-Body, 14px)",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "var(--Line-Height-Body, 18px)",
                        }}
                      >
                        {heading == "In the Spotlight"
                          ? formatDate(card?.uploadedDate)
                          : card?.uploadedOn
                          ? formatDate(card.uploadedOn)
                          : formatDate(card?.date)}
                      </Typography>
                    </Box>
                    {/* {content === "Documents" && (
                      <Box
                        sx={{
                          width: isMobile ? "100%" : "auto",
                          flexShrink: 0,
                          display: "flex",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: textColorLayer1,
                            fontFamily:
                              "var(--Typography-Font-Family-Body, Poppins)",
                            fontSize: "var(--Font-Size-Body, 14px)",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "var(--Line-Height-Body, 18px)", // 128.571%
                          }}
                        >
                          File Type:
                        </Typography>

                        <Typography
                          variant="body2"
                          sx={{
                            color: textColorLayer1,
                            fontFamily:
                              "var(--Typography-Font-Family-Body, Poppins)",
                            fontSize: "var(--Font-Size-Body, 14px)",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "var(--Line-Height-Body, 18px)",
                          }}
                        >
                          {card?.fileType ? card?.fileType : ".pdf"}
                        </Typography>
                      </Box>
                    )} */}
                    <Box
                      sx={{
                        width: isMobile ? "100%" : "20%",
                        flexShrink: 0,
                        display: "flex",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: textColorLayer1,
                          fontFamily:
                            "var(--Typography-Font-Family-Body, Poppins)",
                          fontSize: "var(--Font-Size-Body, 14px)",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "var(--Line-Height-Body, 18px)", // 128.571%
                        }}
                      >
                        View Count:
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{
                          color: textColorLayer1,
                          fontFamily:
                            "var(--Typography-Font-Family-Body, Poppins)",
                          fontSize: "var(--Font-Size-Body, 14px)",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "var(--Line-Height-Body, 18px)",
                        }}
                      >
                        {heading == "In the Spotlight"
                          ? card.viewCount
                          : (card?.counts || card?.views) !== null
                          ? card?.counts
                            ? card?.counts
                            : card?.views
                          : 0}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: isMobile ? "100%" : "20%",
                        flexShrink: 0,
                        display: "flex",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: textColorLayer1,
                          fontFamily:
                            "var(--Typography-Font-Family-Body, Poppins)",
                          fontSize: "var(--Font-Size-Body, 14px)",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "var(--Line-Height-Body, 18px)", // 128.571%
                        }}
                      >
                        Likes:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: textColorLayer1,
                          fontFamily:
                            "var(--Typography-Font-Family-Body, Poppins)",
                          fontSize: "var(--Font-Size-Body, 14px)",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "var(--Line-Height-Body, 18px)",
                        }}
                      >
                        {card?.likes !== null ? card?.likes : 0}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                  }}
                >
                  <div className="flex flex-row gap-3">
                    <Box
                      sx={linkContainer}
                      onClick={() => handleAddFavourite(card)}
                    >
                      {card.favorite ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 18 17"
                          fill="none"
                        >
                          <path
                            d="M11.3417 6.18366L17.3334 6.70033L12.7917 10.642L14.15 16.5003L9.00002 13.392L3.85002 16.5003L5.21669 10.642L0.666687 6.70033L6.65835 6.19199L9.00002 0.666992L11.3417 6.18366Z"
                            fill="#005CBC"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.3334 7.70033L12.3417 7.18366L10 1.66699L7.65835 7.19199L1.66669 7.70033L6.21669 11.642L4.85002 17.5003L10 14.392L15.15 17.5003L13.7917 11.642L18.3334 7.70033ZM10 12.8337L6.86669 14.7253L7.70002 11.1587L4.93335 8.75866L8.58335 8.44199L10 5.08366L11.425 8.45033L15.075 8.76699L12.3084 11.167L13.1417 14.7337L10 12.8337Z"
                            fill="#005CBC"
                          />
                        </svg>
                      )}
                      <Typography
                        sx={isMobile ? { display: "none" } : linkTypography}
                      >
                        Favorite
                      </Typography>
                    </Box>
                    <Box
                      sx={linkContainer}
                      onClick={() => handleOpenSummary(card)}
                    >
                      <RemoveRedEyeOutlinedIcon
                        sx={{
                          fill: "#005CBC",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                      <Typography
                        sx={isMobile ? { display: "none" } : linkTypography}
                      >
                        View Summary
                      </Typography>
                    </Box>
                  </div>
                  <Button
                    sx={buttonStyle}
                    onClick={() =>
                      handleNavigate(card, content.replace(/s$/, ""))
                    }
                  >
                    <Typography
                      sx={{
                        color: "var(--Tokens-Button-Borderless, #005CBC)",
                        fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                        fontSize: "var(--Font-Size-Small, 14px)",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "var(--Line-Height-Small, 20px)",
                        textTransform: "none",
                      }}
                    >
                      View {content.replace(/s$/, "")}
                    </Typography>
                    {content === "Links" && (
                      <LaunchOutlinedIcon
                        sx={{
                          fill: "#005CBC",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    )}
                    {content !== "Links" && (
                      <ArrowForwardIcon
                        sx={{
                          fill: "#005CBC",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    )}
                  </Button>
                </Box>
              </Box>
            </Card>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        <Typography
          sx={{
            color: "var(--button-color-button-primary, #005CBC)",
            textAlign: "center",
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Body, Inter)",
            fontSize: "var(--Font-Size-Body, 16px)",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "var(--Line-Height-Body, 22px)",
          }}
        >
          {` ${Math.min(data?.length)} of ${data?.length}`}
        </Typography>
      </Box>
    </>
  );
}
