import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import AdminDrawer from "../../AdminDrawer/AdminDrawer";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";
import axios from "axios";
import useThemeRender from "./../../../../theme/useThemeRender";
import { postAPI, getAPI } from "../../../../API/ApiService";
import { BASE_URL } from "./../../../../constant/apiUrl";
import Loading from "../../../../utils/Loader";
import CustomSearch from "../../../../utils/CustomSearch/CustomSearch";
import CustomPagination from "../../../PotatoProtection/LargeScreen/Landing/CustomPagination";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/Toast/toast";

const SME = () => {
  const [page, setPage] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState("add");
  const [currentSME, setCurrentSME] = useState({});
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const isDarkTheme = useSelector((state) => state.theme);
  const rowsPerPage = 10;
  const token = localStorage.getItem("auth_token");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAPI(`${BASE_URL}/sme/list-sme`);
      setRows(response?.data);
      console.log("data length", rows?.length);
      setFilteredRows(response?.data);
    } catch (error) {
      showErrorToast("Failed To Load Data");
    } finally {
      setLoading(false);
    }
  };
  const openDrawer = (mode, sme = null) => {
    setDrawerMode(mode);
    setCurrentSME(sme);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setCurrentSME(null);
  };

  const handleAddSME = async (newSME) => {
    setLoading(true);
    try {
      const response = await postAPI(`${BASE_URL}/sme/add-sme`, newSME);
      if (response?.status === 200 || response?.status === 201) {
        showSuccessToast("SME Added Successfully");
        setRows([...rows, response.data]);
        setFilteredRows([...rows, response.data]);
        setLoading(false);
        closeDrawer();
      } else {
        showErrorToast(response?.message);
      }
    } catch (error) {
      console.log(error, "------errror----");
    } finally {
      setLoading(false);
    }
  };

  const handleEditSME = async (updatedSME) => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${BASE_URL}/sme/update-sme/${updatedSME.id}`,
        updatedSME,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response, "--responseday------");

      if (response?.status === 200) {
        const updatedRows = rows.map((row) =>
          row.id === updatedSME.id ? response.data : row
        );
        showSuccessToast("SME Updated Successfully");
        setRows(updatedRows);
        setFilteredRows(updatedRows);
        setLoading(false);
        closeDrawer();
      }
    } catch (error) {
      showErrorToast(`${error?.response?.data?.message}`);
    }
    setLoading(false);
  };
  console.log(filteredRows);

  const handleRemoveSME = async (id) => {
    setLoading(true);
    try {
      const response = await axios.delete(`${BASE_URL}/sme/remove/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (response.status === 204 || response.status === 200) {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
        setFilteredRows(updatedRows);
        setLoading(false);
      } else {
        console.error(
          "Failed to remove SME: ",
          response.data.message || "Unknown error"
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error removing SME: ", error.message);
    }
    setLoading(false);
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.trim().toLowerCase();

    if (!searchQuery) {
      setFilteredRows(rows);
      return;
    }

    const filtered = rows?.filter((row) => {
      return Object.values(row).some((value) => {
        if (value == null) return false;

        const valueString = value.toString().toLowerCase();

        return valueString.includes(searchQuery);
      });
    });

    setFilteredRows(filtered);
  };
  const handleNavigate = (item) => {
    window.open(item, "_blank");
  };
  const totalPages = Math.ceil(filteredRows.length / 10);
  return (
    <Box sx={{ padding: -2 }}>
      <Box
        sx={{
          display: "flex",
          padding: "24px 8px 16px 8px",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "8px",
          alignSelf: "stretch",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer1
            : "var(--Tokens-Background-Layer-01, #FBFCFE)",
          boxShadow: "0px -2px 0px 0px #D7E5F8 inset",
        }}
      >
        <Box
          sx={{
            height: "32px",
            maxWidth: "400px",
            padding: "8px 8px",
            alignItems: "center",
            borderBottom: "1px solid #C0C0C0",
            backgroundColor: useThemeRender("insideCardBackgroundColorLayer3"),
            display: "flex",
            justifyContent: "space-between",
            width: {
              xs: "100%",
              sm: "auto",
            },
          }}
        >
          <CustomSearch handleSearch={handleSearch} />
        </Box>
        <Button
          variant="contained"
          color="primary"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => openDrawer("add")}
          sx={{
            ml: {
              xs: 0,
              sm: 2,
            },
            mt: {
              xs: 4,
              sm: 0,
            },
            textTransform: "none",
            borderRadius: "24px",
            height: "32px",
            backgroundColor: "#005CBC",
            fontWeight: 700,
            color: "white",
            width: {
              xs: "100%",
              sm: "auto",
            },
            "&:hover": {
              backgroundColor: "#004a99",
            },
          }}
        >
          Add new SME
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          minHeight: 500,
          backgroundColor: useThemeRender("cardBackgroundColorLayer1"),
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {[
                "Name",
                "Role",
                "Organization",
                "Phone Number",
                "Email Address",
                "LinkedIn Link",
                "Action",
              ].map((header) => (
                <TableCell
                  key={header}
                  s
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: "12px",

                    color: isDarkTheme
                      ? darkTheme.textColorLayer4
                      : lightTheme.textColorLayer4,
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              height: "100%",
            }}
          >
            {Array.isArray(filteredRows) &&
              filteredRows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      sx={{
                        color: isDarkTheme
                          ? darkTheme.textColorLayer4
                          : lightTheme.textColorLayer4,
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isDarkTheme
                          ? darkTheme.textColorLayer4
                          : lightTheme.textColorLayer4,
                      }}
                    >
                      {row.role}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isDarkTheme
                          ? darkTheme.textColorLayer4
                          : lightTheme.textColorLayer4,
                      }}
                    >
                      {row.organization}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isDarkTheme
                          ? darkTheme.textColorLayer4
                          : lightTheme.textColorLayer4,
                      }}
                    >
                      {row.phone}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isDarkTheme
                          ? darkTheme.textColorLayer4
                          : lightTheme.textColorLayer4,
                      }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        endIcon={<OpenInNewOutlinedIcon />}
                        sx={{ textTransform: "none" }}
                        disabled={row.linkedinLink === ""}
                        onClick={() => handleNavigate(row.linkedinLink)}
                      >
                        Link
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        endIcon={<EditOutlinedIcon />}
                        onClick={() => openDrawer("edit", row)}
                        sx={{ textTransform: "none" }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            {Array.isArray(filteredRows) &&
              filteredRows?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                    sx={{
                      color: isDarkTheme
                        ? darkTheme.textColorLayer4
                        : lightTheme.textColorLayer4,
                    }}
                  >
                    No data available
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>

      {filteredRows?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
            width: "100%",
          }}
        >
          <CustomPagination
            page={page} // Display one-based page number to the user
            setPage={setPage} // Convert one-based back to zero-based
            totalPages={totalPages}
          />
        </Box>
      )}
      <AdminDrawer
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        drawerMode={drawerMode}
        currentSME={currentSME}
        onAddSME={handleAddSME}
        onEditSME={handleEditSME}
        onRemoveSME={handleRemoveSME}
      />
      {loading && <Loading />}
    </Box>
  );
};

export default SME;
