export const filters = [
  {
    label: "Subject",
    options: [
      {
        id: 1,
        label: "Pending",
      },
      {
        id: 2,
        label: "Denied",
      },
      {
        id: 3,
        label: "Approved",
      },
    ],
  },
  {
    label: "Sectors",
    options: [
      {
        id: 1,
        label: "Pending",
      },
      {
        id: 2,
        label: "Denied",
      },
      {
        id: 3,
        label: "Approved",
      },
    ],
  },
  {
    label: "Country",
    options: [
      {
        id: 1,
        label: "Pending",
      },
      {
        id: 2,
        label: "Denied",
      },
      {
        id: 3,
        label: "Approved",
      },
    ],
  },
];

export const options = [
  { value: "0", label: "Option 1" },
  { value: "1", label: "Option 2" },
  { value: "2", label: "Option 3" },
  { value: "3", label: "Option 4" },
];

export const cropTypeOptions = [
  {
    displayText: "First",
    id: "1",
    isBadge: false,
  },
  {
    displayText: "Second",
    id: "2",
    isBadge: false,
  },
];

export const artifactManagementData = [
  {
    versionNumber: "",
    uploadedBy: "",
    uploadedDate: "",
    imageInfo: "",
  },
];
