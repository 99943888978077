import { useNavigate } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { useSelector } from "react-redux"; // Import useSelector
import { externalConfig } from "./oktaConfig"; // Adjust the import path
import AppRoutes from "./routes/AppRoutes";
import { ToastContainer } from "react-toastify";

function App() {
  const navigate = useNavigate();
  const { External } = useSelector((store) => store.config); // Get the authentication state
  // Determine the configuration based on the state
  const config = externalConfig;
  const oktaAuth = new OktaAuth(config); // Create an instance of OktaAuth based on the current config

  // Correctly defining restoreOriginalUri callback
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeButton={true}
        style={{ zIndex: 1300 }}
      />
      <AppRoutes />
    </Security>
  );
}

export default App;
