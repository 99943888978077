export const styles = {
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  filterWrapper: {
    display: "flex",
    gap: 2,
    flexWrap: "wrap",
  },
  searchBox: {
    height: "32px",
    maxWidth: "400px",
    padding: "8px 8px",
    justifyContent: "space-between",
    borderBottom: "1px solid #C0C0C0",
    display: "flex",
    width: {
      xs: "100%",
      sm: "auto",
    },
  },
  inputBase: (theme) => ({
    ml: 1,
    borderColor: theme("borderColorLayer1"),
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme("borderColorLayer2"),
      },
      "&:hover fieldset": {
        borderColor: theme("borderColorLayer2"),
      },
      "&.Mui-focused fieldset": {
        borderColor: theme("borderColorLayer2"),
      },
    },
    "& .MuiInputBase-input": {
      color: theme("textColorLayer1"),
    },
  }),
  iconButton: {
    p: "8px",
  },
  viewButton: (isActive) => ({
    backgroundColor: isActive ? "#005CBC" : "#fff",
    color: isActive ? "#fff" : "#005CBC",
    border: isActive ? "none" : "1px solid #D7E5F8",
  }),
  resultBox: {
    display: "flex",
    width: "135px",
    maxWidth: "400px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "var(--Spacing-spacing-8, 8px)",
  },
  typography: {
    color: "var(--Tokens-Text-Primary, #3A3A3A)",
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-XSmall, 12px)",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "var(--Line-Height-XSmall, 18px)",
  },
  select: (isDarkTheme) => ({
    height: "32px",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-XSmall, 12px)",
    color: isDarkTheme ? "white" : "var(--Tokens-Text-Primary, #3A3A3A)",
    backgroundColor: isDarkTheme ? "black" : "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: isDarkTheme
        ? "#616161"
        : "var(--Tokens-Button-Outlined, #D7E5F8)",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: isDarkTheme ? "#BBBBBB" : "#333333",
    },
    "& .MuiSelect-icon": {
      color: isDarkTheme ? "white" : "#3A3A3A",
    },
  }),
  menuItem: (isDarkTheme) => ({
    color: isDarkTheme ? "white" : "#3A3A3A",
    backgroundColor: isDarkTheme ? "black" : "white",
    "&:hover": {
      backgroundColor: isDarkTheme ? "#333333" : "#f0f0f0",
    },
    "&.Mui-selected": {
      backgroundColor: isDarkTheme ? "#555555" : "#dfe6fd",
      color: isDarkTheme ? "white" : "#005CBC",
    },
    "&.Mui-selected:hover": {
      backgroundColor: isDarkTheme ? "#444444" : "#c5d7fa",
    },
  }),
};
