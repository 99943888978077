const formValidations = (
  firstName,
  lastName,
  email,
  company,
  countryId,
  sponsorEmail
) => {
  const errors = {};

  if (!firstName || firstName.trim() === "") {
    errors.firstName = "FirstName cannot be empty.";
  }

  if (!lastName || lastName.trim() === "") {
    errors.lastName = "LastName  cannot be empty.";
  }
  if (!email || email.trim() === "") {
    errors.emailaddress = "Email Address cannot be empty.";
  }
  if (!company || company.trim() === "") {
    errors.company = "Company cannot be empty.";
  }
  if (!countryId) {
    errors.countryId = "Country cannot be empty.";
  }
  if (!sponsorEmail || sponsorEmail.trim() === "") {
    errors.sponsorEmail = "Sponsor Email cannot be empty.";
  }

  return errors;
};

export default formValidations;
