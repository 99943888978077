import React, { useState, useEffect, useMemo } from "react";
import { Box, useMediaQuery } from "@mui/material";
import MainBodyHeader from "./MainBodyHeader";
import MainBodyFilter from "./MainBodyFilter";
import MainBodyCard from "./MainBodyCard/MainBodyCard";
import CustomPagination from "../../PotatoProtection/LargeScreen/Landing/CustomPagination";
import { useSelector } from "react-redux";
import { darkTheme } from "../../../theme/Theme";
import { getAPI } from "../../../API/ApiService";
import { BASE_URL } from "../../../constant/apiUrl";
import Loading from "../../../utils/Loader";

export default function RequestInformationBody() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const itemsPerPage = 5;
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [rfiRecords, setRfiRecords] = useState([]);
  const [requested, setRequested] = useState(null);
  const [filterRequest, setFilterRequest] = useState(null);
  const [loading, setLoading] = useState(false);
  const { filter } = useSelector((store) => store.rfi);

  useEffect(() => {
    setLoading(true);
    const fetchRfi = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/rfi`);
        if (response?.status === 200) {
          console.log(response, "----response----");

          const fetch = response?.data;
          const data = fetch.map((item) => ({
            id: item.id,
            label: item.displayName,
          }));
          setRequested(data);
          setRfiRecords(fetch);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    fetchRfi();
  }, []);

  // Memoize filtered and sorted records
  const filterRecords = useMemo(() => {
    let updatedRows = [...rfiRecords];

    if (filterRequest) {
      updatedRows = updatedRows.filter(
        (item) => item.displayName === filterRequest.label
      );
    }

    if (search) {
      updatedRows = updatedRows.filter((record) =>
        record.title.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (filter) {
      updatedRows.sort((a, b) => {
        switch (filter) {
          case 1:
            return b.likes - a.likes;
          case 2:
            return a.likes - b.likes;
          case 3:
            return b.views - a.views;
          case 4:
            return a.views - b.views;
          case 5:
            return new Date(a.requestedDate) - new Date(b.requestedDate);
          case 6:
            return new Date(b.requestedDate) - new Date(a.requestedDate);
          default:
            return 0;
        }
      });
    }

    return updatedRows;
  }, [rfiRecords, filterRequest, search, filter]);

  const totalPages = useMemo(
    () => Math.ceil(filterRecords.length / itemsPerPage),
    [filterRecords.length]
  );

  const paginatedRows = useMemo(() => {
    return filterRecords.slice(page * itemsPerPage, (page + 1) * itemsPerPage);
  }, [filterRecords, page, itemsPerPage]);

  console.log(filterRecords, "aditya");

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  console.log(filterRecords, "-------filterRecords------");

  if (loading) return <Loading />;

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        padding: isMobile ? "16px" : "var(--Spacing-spacing-24, 24px)",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "var(--Spacing-spacing-16, 16px)",
        borderRadius: "var(--Corner-radius-effect-corner-2, 8px)",
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer2
          : "var(--Tokens-Background-Layer-01, #FBFCFE)",
        boxShadow:
          "var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
      }}
    >
      <MainBodyHeader handleSearch={handleSearch} />
      <MainBodyFilter
        requested={requested}
        setFilterRequest={setFilterRequest}
        rfiRecords={rfiRecords}
      />

      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {paginatedRows.map((item) => (
          <MainBodyCard key={item.id} card={item} />
        ))}
        {filterRecords?.length === 0 && (
          <Box sx={{ textAlign: "center", marginTop: "16px" }}>
            No records found.
          </Box>
        )}
      </Box>

      {filterRecords?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
            width: "100%",
          }}
        >
          <CustomPagination
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        </Box>
      )}
    </Box>
  );
}
