import React from "react";

const PlusIcon = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33268 4.83342H7.66602V8.16675H4.33268V9.83342H7.66602V13.1667H9.33268V9.83342H12.666V8.16675H9.33268V4.83342ZM8.49935 0.666748C3.89935 0.666748 0.166016 4.40008 0.166016 9.00008C0.166016 13.6001 3.89935 17.3334 8.49935 17.3334C13.0993 17.3334 16.8327 13.6001 16.8327 9.00008C16.8327 4.40008 13.0993 0.666748 8.49935 0.666748ZM8.49935 15.6667C4.82435 15.6667 1.83268 12.6751 1.83268 9.00008C1.83268 5.32508 4.82435 2.33341 8.49935 2.33341C12.1743 2.33341 15.166 5.32508 15.166 9.00008C15.166 12.6751 12.1743 15.6667 8.49935 15.6667Z"
        fill="#FBFCFE"
      />
    </svg>
  );
};

export default PlusIcon;
