import React, { useState, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, useMediaQuery } from "@mui/material";
import SearchFilter from "./SearchFilter";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { EditButton } from "./style";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../PotatoProtection/LargeScreen/Landing/CustomPagination";
import { useSelector } from "react-redux";

const DataTable = ({ pageName, Tabledata }) => {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const navigate = useNavigate();
  const [contentTitle, setContentTitle] = useState("");
  const [contentType, setContentType] = useState("");
  const [status, setStatus] = useState("");
  const [sector, setSector] = useState("");
  const [country, setCountry] = useState("");
  const [language, setLanguage] = useState("");
  const [subject, setSubject] = useState("");
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const {
    deniedHistoryContentType,
    deniedHistoryVisibility,
    dedeniedHistoryContentLevel,
  } = useSelector((state) => state.curatorpage);
  const handleNavigate = (item) => {
    if (pageName === 'artifcatsHistory') {
      navigate(`/curator/artifact-history/${item?.row?.contentGuid}`);
    } else if (pageName === 'deniedHistory') {
      navigate(`/curator/denied-artificats-history/${item?.row?.contentGuid}`);
    }
  
  };

  const columns = [
    {
      field: "contentTitle",
      headerName: "CONTENT TITLE",
      minWidth: isMobile ? 80 : 160,
      sortable: false,
      renderCell: (params) =>
        params.id === 1 ? (
          <SearchFilter
            name={"title"}
            setContentTitle={setContentTitle}
            contentTitle={contentTitle}
          />
        ) : (
          <span>{params.value}</span>
        ),
    },
    {
      field: "contentType",
      headerName: "CONTENT TYPE",
      minWidth: isMobile ? 80 : 160,
      sortable: false,
      renderCell: (params) =>
        params.id === 1 ? (
          <SearchFilter
            name={"type"}
            setContentType={setContentType}
            contentType={contentType}
          />
        ) : (
          <span>{params.value}</span>
        ),
    },
    {
      field: "status",
      headerName: "STATUS",
      minWidth: isMobile ? 80 : 160,
      sortable: false,
      renderCell: (params) =>
        params.id === 1 ? (
          <SearchFilter name={"status"} setStatus={setStatus} status={status} />
        ) : (
          <span>{params.value}</span>
        ),
    },
    {
      field: "sector",
      headerName: "SECTOR",
      minWidth: isMobile ? 80 : 160,
      sortable: false,
      renderCell: (params) =>
        params.id === 1 ? (
          <SearchFilter name={"sector"} setSector={setSector} sector={sector} />
        ) : (
          <span>{params.value}</span>
        ),
    },
    {
      field: "country",
      headerName: "COUNTRY",
      minWidth: isMobile ? 80 : 160,
      sortable: false,
      renderCell: (params) =>
        params.id === 1 ? (
          <SearchFilter
            name={"country"}
            setCountry={setCountry}
            country={country}
          />
        ) : (
          <span>{params.value}</span>
        ),
    },
    {
      field: "language",
      headerName: "LANGUAGE",
      minWidth: isMobile ? 80 : 160,
      sortable: false,
      renderCell: (params) =>
        params.id === 1 ? (
          <SearchFilter
            name={"language"}
            setLanguage={setLanguage}
            language={language}
          />
        ) : (
          <span>{params.value}</span>
        ),
    },
    {
      field: "subject",
      headerName: "SUBJECT",
      minWidth: isMobile ? 80 : 160,
      sortable: false,
      renderCell: (params) =>
        params.id === 1 ? (
          <SearchFilter
            name={"subject"}
            setSubject={setSubject}
            subject={subject}
          />
        ) : (
          <span>{params.value}</span>
        ),
    },
    {
      field: "action",
      headerName: "ACTION",
      minWidth: isMobile ? 80 : 160,
      sortable: false,
      renderCell: (params) =>
        params.id === 1 ? (
          <></>
        ) : (
          <EditButton onClick={() => handleNavigate(params)}>
            Edit <EditOutlinedIcon />
          </EditButton>
        ),
    },
  ];

  const originalData = useMemo(() => {
    if (Array.isArray(Tabledata)) {
      return [
        ...Tabledata?.map((item, index) => ({
          id: index + 2,
          contentTitle: item?.title || "",
          contentType: item?.type || "",
          status: item?.status || "",
          sector: item?.sector?.join(", ") || "N/A",
          country: item?.country?.join(", ") || "N/A",
          language: item?.language?.join(", ") || "N/A",
          subject: item?.subject?.join(", ") || "N/A",
          contentGuid: item?.contentGuid,
          contentId: item?.contentId,
        })),
      ];
    }
    return [];
  }, [Tabledata]);

  const filterContent = (
    documents,
    contentTitle = null,
    contentType = null,
    status = null,
    sector = null,
    country = null,
    language = null,
    subject = null
  ) => {
    return documents.filter((item) => {
      const matchesTitle =
        !contentTitle ||
        item.contentTitle.toLowerCase().includes(contentTitle.toLowerCase());
      const matchesType =
        !contentType ||
        item.contentType.toLowerCase().includes(contentType.toLowerCase());
      const matchesStatus =
        !status || item.status.toLowerCase().includes(status.toLowerCase());
      const matchesLanguage =
        !language ||
        item.language.toLowerCase().includes(language.toLowerCase());
      const matchesSector =
        !sector ||
        (Array.isArray(item.sector) &&
          item?.sector?.length > 0 &&
          item.sector?.some(
            (name) => name.toLowerCase() === sector.toLowerCase()
          ));
      const matchesCountry =
        !country ||
        (Array.isArray(item.country) &&
          item?.country?.length > 0 &&
          item.country?.some(
            (name) => name.toLowerCase() === country.toLowerCase()
          ));
      const matchesSubject =
        !subject ||
        (Array.isArray(item.subject) &&
          item?.subject?.length > 0 &&
          item.subject?.some(
            (name) => name.toLowerCase() === subject.toLowerCase()
          ));
      return (
        matchesTitle &&
        matchesType &&
        matchesStatus &&
        matchesLanguage &&
        matchesSector &&
        matchesCountry &&
        matchesSubject
      );
    });
  };

  const filteredData = useMemo(() => {
    return filterContent(
      originalData,
      contentTitle,
      contentType,
      status,
      sector,
      country,
      language,
      subject
    );
  }, [
    originalData,
    contentTitle,
    contentType,
    status,
    sector,
    country,
    language,
    subject,
  ]);

  const paginatedData = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    const dataSlice = filteredData.slice(start, end);
    return [
      {
        id: 1,
        contentTitle: "hehehhe",
        contentType: "Type A",
        status: "Status A",
        sector: "Sector A",
        country: "Country A",
        language: "Language A",
        subject: "Subject A",
      },
      ...dataSlice,
    ];
  }, [filteredData, page, rowsPerPage]);

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        "& .MuiDataGrid-filler": { display: "none" },
        "& .MuiDataGrid-footerContainer": { display: "none" },
        "& .MuiDataGrid-virtualScroller": {
          overflowY: "hidden",
        },
      }}
    >
      <DataGrid
        rows={paginatedData}
        columns={columns}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
        disableSelectionOnClick
        disableColumnSelector
        disableRowSelectionOnClick
        hideFooterPagination
        disableColumnFilter
        disableColumnMenu
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={Math.ceil(filteredData.length / rowsPerPage)}
        />
      </Box>
    </Box>
  );
};

export default DataTable;
