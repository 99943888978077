// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  External: null,
};
export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig(state, action) {
      console.log("____store___", action.payload);

      state.External = action.payload;
    },
  },
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
