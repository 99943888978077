import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  Autocomplete,
  Button,
  useMediaQuery,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import commonStyles from "./addVariteyDrawerStyle";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import {
  setEdit,
  fetchVariety,
} from "../../../../slices/potatoProtectionSlice";
import { lightTheme, darkTheme } from "../../../../theme/Theme";
import {
  getTextFieldStyles,
  datePickerStyles,
  autoCompletStyles,
} from "../../../../utils/FieldStyles/FieldStyles";
import { useParams } from "react-router-dom";
import axios from "axios";
import { postAPI } from "../../../../API/ApiService";
import DateFormat from "../../../../utils/DateFormat";
import { BASE_URL } from "../../../../constant/apiUrl";
import { getAPI } from "../../../../API/ApiService";

import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/Toast/toast";
export default function AddVariteyDrawer({ open, Close, variety = {} }) {
  const { register, handleSubmit, formState, reset, control } = useForm({
    defaultValues: {
      varietyId: "",
      firstSaleCountry: "",
      comments: "",
      firstSaleDate: null,
    },
  });
  const [country, setCountryData] = useState([]);
  const { isEdit } = useSelector((store) => store.protectionPotato);

  const { errors } = formState;
  const dispatch = useDispatch();

  console.log("drawer rerender");

  const { id } = useParams();
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=COUNTRY`
        );
        const fecthData = response?.data;
        // const countries = fecthData?.map((item) => {
        //   return { id: item.id, label: item.name };
        // });

        setCountryData(fecthData);
      } catch (err) {
        // setError(err.message);
      } finally {
        // setLoading(false);
      }
    };

    fetchCountryData();
  }, []);
  useEffect(() => {
    if (id && variety) {
      // Auto-populate fields when `id` and `variety` data are available
      reset({
        varietyId: variety.varietyId || "",
        firstSaleCountry: variety.firstSaleCountry || "",
        comments: variety.comments || "",
        firstSaleDate: variety.firstSaleDate
          ? dayjs(variety.firstSaleDate)
          : null,
      });
    } else {
      // Reset to default values when `id` is not present
      reset({
        varietyId: "",
        firstSaleCountry: "",
        comments: "",
        firstSaleDate: null,
      });
    }
  }, [id, JSON.stringify(variety), reset]); // Use `JSON.stringify(variety)` to compare based on content
  const onReset = () => {
    reset();
  };

  const HandleClose = () => {
    onReset();
    Close();
    dispatch(setEdit(false));
  };
  const token = localStorage.getItem("auth_token");
  const onSubmit = async (data) => {
    const formattedDate = DateFormat(data.firstSaleDate);
    const payload = { ...data, firstSaleDate: formattedDate };
    console.log(payload, "-----data-------");

    if (id) {
      try {
        const result = await axios.put(`${BASE_URL}/variety/${id}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
        if (result.status === 200) {
          dispatch(fetchVariety(true));
          HandleClose();
        }
      } catch (error) {
        console.log("Something went wrong", error);
        HandleClose();
      }
    } else {
      try {
        const result = await postAPI(`${BASE_URL}/variety`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
        if (result.status === 201) {
          showSuccessToast("Variety Added Successfully");
          dispatch(fetchVariety(true));
          HandleClose();
        }
        if (result?.status === 400) {
          showErrorToast("Variety Cannot be Repeated");
          console.log("something went wrong");
        }
      } catch (error) {
        showErrorToast("Something went wrong", error);
        HandleClose();
      }
    }
  };

  // const options = ["India", "USA", "Mexico"];

  // Detect mobile view using useMediaQuery
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={open}
      on
      PaperProps={{
        sx: {
          height: isMobile ? "80%" : "100%",
          width: isMobile ? "100%" : 436,
          padding: isMobile ? "16px 16px" : "16px 24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer3
            : lightTheme.backgroundColorLayer3,
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: isDarkTheme
              ? darkTheme.drawerOverlayColorLayer1
              : lightTheme.drawerOverlayColorLayer1,
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "var(--Spacing-spacing-8, 8px)",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: isDarkTheme && darkTheme.textColorLayer1,
            fontSize: "var(--Font-Size-H6, 18px)",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H6, 28px)",
          }}
        >
          {isEdit ? "Edit variety" : " Add variety"}
        </Typography>
        <IconButton onClick={HandleClose}>
          <CloseIcon
            style={{
              color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
            }}
          />
        </IconButton>
      </Box>

      <form
        className="w-full flex flex-col gap-3 h-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            ...commonStyles.container,
            flex: 1,
            padding: "var(--Spacing-spacing-16, 16px)",
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer1
              : lightTheme.backgroundColorLayer1,
            overflowY: "auto",
          }}
        >
          {/* Variety Input */}
          <Box sx={commonStyles.container}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: isDarkTheme && darkTheme.textColorLayer1,
              }}
            >
              Variety
            </Typography>
            <Controller
              name="varietyId"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field} // Connects to the Controller
                  id="outlined-size-small"
                  size="small"
                  sx={getTextFieldStyles(isMobile, isDarkTheme, darkTheme)}
                  required
                  placeholder="Add Variety"
                  error={!!errors.varietyId}
                  helperText={errors.varietyId ? errors.varietyId.message : ""}
                  value={field.value || ""} // Autopopulates value for edit mode
                  onChange={(e) => field.onChange(e.target.value)} // Updates value on edit
                  autoComplete="off"
                />
              )}
            />
          </Box>

          {/* Date Picker */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={commonStyles.container}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: isDarkTheme && darkTheme.textColorLayer1,
                }}
              >
                Date of first worldwide sale
              </Typography>
              <Controller
                name="firstSaleDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    slotProps={{
                      textField: {
                        id: "outlined-size-small",
                        size: "small",
                        sx: datePickerStyles(
                          isDarkTheme,
                          isMobile,
                          darkTheme,
                          commonStyles
                        ),
                      },
                    }}
                    format="DD-MM-YYYY"
                    maxDate={dayjs()}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>

          {/* Country Autocomplete */}
          <Box sx={commonStyles.container}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: isDarkTheme && darkTheme.textColorLayer1,
              }}
            >
              Country of first worldwide sale
            </Typography>
            <Controller
              name="firstSaleCountry"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field} // Spreads the field props
                  options={country.map((item) => item.name)}
                  getOptionLabel={(option) => option}
                  value={field.value || ""} // Value connected to the form's state
                  onChange={(e, newValue) => field.onChange(newValue)} // Update form state
                  sx={autoCompletStyles(isDarkTheme, isMobile, darkTheme)}
                  ListboxProps={{
                    sx: {
                      backgroundColor: isDarkTheme
                        ? darkTheme.backgroundColorLayer1
                        : "white",
                      "& .MuiAutocomplete-option": {
                        color: isDarkTheme ? "white" : "black",
                        "&:hover": {
                          backgroundColor: isDarkTheme ? "#333" : "#f0f0f0",
                        },
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      error={!!errors.firstSaleCountry}
                      helperText={
                        errors.firstSaleCountry
                          ? errors.firstSaleCountry.message
                          : ""
                      }
                    />
                  )}
                />
              )}
            />
          </Box>

          {/* Comments Text Area */}
          <Box sx={commonStyles.container}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: isDarkTheme && darkTheme.textColorLayer1,
              }}
            >
              Comments
            </Typography>

            <TextField
              placeholder="Type here"
              multiline
              minRows={4} // Controls the initial height
              maxRows={isMobile ? 6 : 8}
              fullWidth={isMobile} // Adjust width for mobile view
              style={{
                width: isMobile ? "100%" : "338px",
                backgroundColor: isDarkTheme
                  ? darkTheme.cardBackgroundColorLayer1
                  : lightTheme.cardBackgroundColorLayer1,
                border: "1px solid white",
              }}
              InputProps={{
                style: {
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                },
              }}
              {...register("comments")}
              error={!!errors.comments}
              helperText={errors.comments ? errors.comments.message : ""}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 200, // Restrict to 200 characters
              }}
            />
          </Box>
        </Box>

        {/* Button Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "16px",
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer3
              : lightTheme.backgroundColorLayer3,
            boxShadow: "0px 1px 0px 0px #DFDFDF inset",
            p: "24px",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              ...commonStyles.buttonBase,
              ...commonStyles.outlinedButton,
            }}
            type="button"
            onClick={HandleClose}
          >
            <Typography sx={commonStyles.typographyButton}>Cancel</Typography>
          </Button>

          <Button
            variant="contained"
            sx={{
              ...commonStyles.buttonBase,
              ...commonStyles.containedButton,
            }}
            type="submit"
          >
            <Typography sx={commonStyles.typographyButton}>
              {isEdit ? "Save" : "Add variety"}
            </Typography>
          </Button>
        </Box>
      </form>
    </Drawer>
  );
}
