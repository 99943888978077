import React from "react";
import { Box } from "@mui/material";
import image1 from "../../assets/egypt.jpg";
import image2 from "../../assets/elma.jpg";
import image3 from "../../assets/Frenchitas.jpg";
import image4 from "../../assets/Lays.jpg";
import image5 from "../../assets/Leishi.jpg";
import image6 from "../../assets/Margarita.jpg";
import image7 from "../../assets/Sabritas.jpg";
import image8 from "../../assets/SaudiArabia.jpg";
import image9 from "../../assets/Smiths.jpg";
import image10 from "../../assets/Taiwan.jpg";
import image11 from "../../assets/Tapuchips.jpg";
import image12 from "../../assets/Thailand.jpg";
import image13 from "../../assets/Walkers.jpg";

const imageArray = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
];
export default function LoginBanner() {
  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: 520,
        padding: "17.778px",
        justifyContent: "center",
        alignItems: "flex-start",
        alignContent: "flex-start",
        gap: { xs: "16px", sm: "17.778px" }, // Adjusting gap for responsiveness
        flexWrap: "wrap",
        borderRadius: "17.778px",
        backgroundColor: "#FFF",
        boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)", // Using direct values for shadow
      }}
    >
      {imageArray.map((el, index) => {
        return (
          <Box
            key={index}
            sx={{
              width: "132.222px", // Set width
              height: "132.222px", // Set height
              flexShrink: 0, // Prevent the box from shrinking
              backgroundColor: "lightgray", // Set fallback background color
              backgroundPosition: "-4.278px -4.624px", // Set background position
              backgroundSize: "104.916% 104.916%", // Set background size
              backgroundRepeat: "no-repeat", // Prevent background image repetition
              backgroundImage: `url(${el})`,
            }}
          ></Box>
        );
      })}
    </Box>
  );
}
