import { Box, Button, styled } from "@mui/material";
import { darkTheme, lightTheme } from "../../../theme/Theme";

export const TableWrapper = styled(Box)(({ theme, isMobile, isDarkTheme }) => ({
  // width: "100%",

  "& .MuiDataGrid-root": {
    border: 0,
  },

  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },

  "& .MuiDataGrid-columnSeparator--sideRight": {
    display: "none !important",
  },

  "& .MuiDataGrid-row--borderBottom": {
    background: `${
      isDarkTheme
        ? darkTheme.tableRowBackgroundColor
        : lightTheme.tableRowBackgroundColor
    } !important`,
  },

  "& .MuiDataGrid-row": {
    background: isDarkTheme
      ? darkTheme.tableRowBackgroundColor
      : lightTheme.tableRowBackgroundColor,
    borderBottom: `1px solid ${
      isDarkTheme ? darkTheme.borderColorLayer3 : lightTheme.borderColorLayer3
    }`,
  },

  "& .MuiDataGrid-cell": {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    color: isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4,
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "14px",
    fontWeight: "400 !important",
  },

  "& .MuiDataGrid-columnHeader": {
    borderBottom: `1px solid ${
      isDarkTheme ? darkTheme.borderColorLayer3 : lightTheme.borderColorLayer3
    }`,
  },

  "& .MuiDataGrid-columnHeaderTitleContainer": {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },

  "& .MuiDataGrid-columnHeaderTitle": {
    textTransform: "uppercase",
    color: isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4,
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "12px",
    fontWeight: "700 !important",
  },
}));

export const EditButton = styled(Button)({
  color: "#005CBC",
  fontSize: 16,
  fontWeight: 400,
  display: "flex",
  alignItems: "center",
  gap: "4px",

  "& svg": {
    height: 16,
    width: 16,
  },
});
