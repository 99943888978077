import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../../constant/apiUrl";
import { formatDate } from "../../../../utils/function";
import Loading from "../../../../utils/Loader";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/Toast/toast";
import { useDispatch, useSelector } from "react-redux";
import { setFetchPending } from "../../../../slices/invitationSlice";
const styles = {
  section: {
    display: "flex",
    padding: "var(--Spacing-spacing-16, 16px)",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "var(--Spacing-spacing-16, 16px)",
    flex: "1 0 0",
    borderTop: "1px solid var(--Colors-Border-Gray-150, #D0D0D0)",
  },
  card: {
    height: "100%",
    display: "flex",
    minWidth: "280px",
    padding: "8px 16px",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "8px",
    alignSelf: "stretch",
    flexWrap: "wrap",
    borderRadius: "4px",
    background: "var(--Tokens-Feedback-Info-background, #CCEAF6)",
  },
  typography: {
    color: "var(--Tokens-Text-Black, #000)",
    fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
    fontSize: "var(--Font-Size-H6, 18px)",
    fontWeight: 700,
    lineHeight: "var(--Line-Height-H6, 28px)",
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    gap: "2px",
    paddingY: "4px",
    cursor: "pointer",
  },
  buttonText: {
    color: "var(--Tokens-Button-Borderless, #005CBC)",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-Small, 14px)",
    fontWeight: 700,
    lineHeight: "var(--Line-Height-Small, 20px)",
    textTransform: "none",
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: "32px",
    marginY: "auto",
  },
};
const token = localStorage.getItem("auth_token");
const AccessHistory = React.memo(
  ({ data, user }) => {
    const dispatch = useDispatch();
    const [access, setAccess] = useState(false);
    const { fetch } = useSelector((state) => state.invitation);
    const [accessType, setAccessType] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      // Auto-populate dropdown if `data` has accessType
      if (data?.accessType) {
        setAccessType(data.accessType);
      }
    }, [data]);

    const handleSave = async () => {
      setLoading(true);
      try {
        const response = await axios.put(
          `${BASE_URL}/admin/profile/${data.id}`,
          {
            requestId: data?.id,
            accessType,
            lastAccess: data?.lastAccess,
            grantedAccess: access,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.status === 200) {
          showSuccessToast("Save successfull");
          dispatch(setFetchPending(!fetch));
        }

        console.log("Save successful:", response);
      } catch (error) {
        console.error(
          "Error saving data:",
          error.response ? error.response.data : error.message
        );
        showErrorToast(
          `${error.response ? error.response.data : error.message}`
        );
      } finally {
        setLoading(false);
      }
    };
    console.log(user, "---userdd---");

    return (
      <Box sx={styles.container}>
        <Box sx={styles.section}>
          <Box sx={styles.card}>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Box sx={{ width: "100%", padding: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "700", marginBottom: 2 }}
                >
                  Access
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Typography variant="body1" sx={{ marginRight: 2 }}>
                    Grant access?
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={access}
                        onChange={() => setAccess((prevAccess) => !prevAccess)}
                        color="primary"
                      />
                    }
                    label={access ? "Yes" : "No"}
                  />
                </Box>

                <Box display="flex" sx={{ marginBottom: 2, gap: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: "700" }}>
                    Last access
                  </Typography>
                  <Typography variant="body1">
                    {data.lastAccess === null
                      ? null
                      : formatDate(data?.lastAccess)}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Box sx={{ marginRight: 2 }}>
                    <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                      Access type
                    </Typography>
                    <FormControl sx={{ minWidth: 400 }}>
                      <Select
                        value={accessType}
                        onChange={(event) => setAccessType(event.target.value)}
                        sx={{ height: "32px", backgroundColor: "#FBFCFE" }}
                      >
                        <MenuItem value={null}> Select</MenuItem>
                        {user?.map((item, index) => (
                          <MenuItem key={item.id} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={accessType === null}
                    sx={{
                      bgcolor: "#005CBC",
                      fontWeight: 700,
                      borderRadius: "24px",
                      textTransform: "none",
                      marginTop: 3,
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
      prevProps.user === nextProps.user
    );
  }
);

export default AccessHistory;
