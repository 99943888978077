// components/AvatarComponent.js
import Box from "@mui/material/Box";
import { Icon, Avatar } from "pepsico-ds";
import AvatarImg from "./../../../assets/Avatar.png";
import { useSelector } from "react-redux";
const AvatarComponent = () => {
  const { flagUser } = useSelector((store) => store.user);

  return (
    <Box>
      <Icon
        alt="User avatar"
        svg={<Avatar imageUrl={AvatarImg} name="Jon Do" size="large" />}
      />
    </Box>
  );
};

export default AvatarComponent;
