import { Box, Typography, Card, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../DocumentScreen/MobileScreenDocument.css";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import useThemeRender from "./../../theme/useThemeRender";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../constant/apiUrl";
import axios from "axios";
import { postAPI, getAPI } from "../../API/ApiService";
import LinkSummary from "./LinkSummary";
import LinkDetails from "./LinkDetails";
import DocumentSummary from "../DocumentScreen/DocumentSummary";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setFavourite } from "../../slices/internalPageSlice";
import Disclaimer from "../DocumentScreen/Disclaimer";
export default function LinkScreen() {
  const location = useLocation();
  const LinkCardData = location.state;
  const [linkData, setLinkData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = 1;
  const contentId = LinkCardData?.id;
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { favourite } = useSelector((store) => store.internalPage);
  const dispatch = useDispatch();
  const handleAddFavourite = () => {
    const addFavorite = async () => {
      try {
        const response = await postAPI(
          `${BASE_URL}/favorites/save?contentId=${contentId}&userId=1&favorite=${!linkData?.isFavorite}`
        );
        fetchData();
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    addFavorite();
  };
  const fetchData = async () => {
    if (contentId !== undefined) {
      try {
        const response = await getAPI(
          `${BASE_URL}/content/${contentId}/user/${userId}`
        );
        if (response.status == "200") {
          setLinkData(response.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleLike = () => {
    const newData = {
      contentId: contentId,
      likes: !linkData?.isLiked,
      dislikes: linkData?.isDisliked,
    };
    const addDisLike = async () => {
      try {
        const response = await postAPI(
          `${BASE_URL}/content-metrics/save`,
          newData
        );
        if (response.status == 200) {
          dispatch(setFavourite(favourite + 1));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    addDisLike();
  };
  const handleDisLike = () => {
    const newData = {
      contentId: contentId,
      likes: linkData?.isLiked,
      dislikes: !linkData?.isDisliked,
    };
    const addDisLike = async () => {
      try {
        const response = await postAPI(
          `${BASE_URL}/content-metrics/save`,
          newData
        );
        if (response.status == 200) {
          dispatch(setFavourite(favourite + 1));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    addDisLike();
  };
  useEffect(() => {
    fetchData();
  }, [LinkCardData, favourite]);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          padding: {
            xs: "0px",
            sm: "30px",
          },
          paddingTop: { xs: "16px" },
          flexDirection: "column",
          paddingBottom: "24px",
          margin: 0,
          gap: "var(--Spacing-spacing-24, 24px)",
          backgroundColor: useThemeRender("backgroundColorLayer1"),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: { xs: "flex-start", sm: "space-between" },
            alignSelf: { xs: "stretch", sm: "auto" },
            gap: { xs: "var(--Spacing-spacing-24, 24px)", sm: "10px" },
            flexDirection: { xs: "column", sm: "row" },
            padding: { xs: "16px", sm: "0" },
          }}
        >
          <Typography
            sx={{
              color: useThemeRender("textColorLayer2"),
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontSize: "var(--Font-Size-H4, 28px)",
              fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
              fontStyle: "normal",
              lineHeight: "var(--Line-Height-H4, 40px)",
              fontWeight: 700,
              width: { xs: "100%", sm: "60%" },
            }}
          >
            {LinkCardData?.title}
          </Typography>
          <Link
            target="_blank"
            to={linkData?.url}
            style={{ width: isMobile ? "100%" : "250px" }}
          >
            <Button
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "var(--Corner-radius-effect-corner-3, 24px)",
                background: "var(--Tokens-Button-Primary, #005CBC)",
                alignSelf: "stretch",
                width: { xs: "100%" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-40, 40px)",
                  padding:
                    "var(--Spacing-spacing-03, 8px) var(--Spacing-spacing-05, 24px)",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "var(--Spacing-spacing-03, 8px)",
                  textDecoration: "none",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                    color: "var(--Colors-White, #FFF)",
                    fontStyle: "normal",
                    fontSize: "var(--Font-Size-Body, 16px)",
                    fontWeight: 700,
                    textTransform: "none",
                    lineHeight: "var(--Line-Height-Body, 22px)",
                  }}
                >
                  Open link
                </Typography>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M19.5 19H5.5V5H12.5V3H5.5C4.39 3 3.5 3.9 3.5 5V19C3.5 20.1 4.39 21 5.5 21H19.5C20.6 21 21.5 20.1 21.5 19V12H19.5V19ZM14.5 3V5H18.09L8.26 14.83L9.67 16.24L19.5 6.41V10H21.5V3H14.5Z"
                    fill="white"
                  />
                </svg>
              </Box>
            </Button>
          </Link>
        </Box>
        <DocumentSummary
          handleAddFavourite={handleAddFavourite}
          documentData={linkData}
          handleLike={handleLike}
          handleDisLike={handleDisLike}
        />
        <LinkDetails linkData={linkData} />
        <Disclaimer documentData={linkData} />
      </Box>
    </div>
  );
}
