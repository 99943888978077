import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { FooterButton } from "./curatorApprovalStyle";
import useThemeRender from "../../../theme/useThemeRender";

const Footer = ({ onConfirm, onCancel, btnStyle = null }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  

  return (
    <Box
      sx={{
        padding: "16px 24px",
        background: useThemeRender("backgroundColorLayer3"),
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          justifyContent: isMobile ? "space-between" : "end",
        }}
      >
        <FooterButton
          sx={btnStyle}
          onClick={onCancel.handleCancelEvent}
        >
          {onCancel.label}
        </FooterButton>
        <FooterButton
          sx={{ background: "#005CBC", flex: isMobile && 1 }}
          onClick={onConfirm.handConfirmEvent}
        >
          {onConfirm.label}
        </FooterButton>
      
      </Box>
    </Box>
  );
};

export default Footer;
