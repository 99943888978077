import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import useThemeRender from '../../theme/useThemeRender'

function Disclaimer({documentData}) {
  return (
    <Card
    className="SummaryCard"
    sx={{
      background: useThemeRender("backgroundColorLayer3"),
    }}
  >
    <Typography
      className="SummaryTitle"
      sx={{
        color: useThemeRender("textColorLayer2"),
      }}
    >
      Disclaimer
    </Typography>
    <Box
      className="SummaryContentBox"
      sx={{
        background: useThemeRender("backgroundColorLayer1"),
      }}
    >
      <Typography
        className="SummaryContents"
        sx={{
          color: useThemeRender("textColorLayer2"),
        }}
      >
        {documentData?.disclaimer}
      </Typography>
    </Box>
  </Card>
  )
}

export default Disclaimer