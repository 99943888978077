import React, { useEffect, useState } from "react";
import useThemeRender from "./../../../../theme/useThemeRender";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { BASE_URL } from "./../../../../constant/apiUrl";
import { getAPI } from "../../../../API/ApiService";

const DeletePopUp = ({
  options,
  selectedSubject,
  open,
  handleCloseDialog,
  handleConfirmClick,
  artifact,
  setArtifact,
}) => {
  const [artifactCount, setArtifactCount] = useState(0);

  useEffect(() => {
    if (selectedSubject && selectedSubject.id) {
      const fetchArtifactCount = async () => {
        try {
          const response = await getAPI(
            `${BASE_URL}/administrative-subject/checkContentSubjectExists/${selectedSubject.id}`
          );
          console.log("artifact", response);
          if (
            response &&
            response?.data &&
            typeof response?.data === "number"
          ) {
            setArtifactCount(response?.data);
          } else {
            console.warn("Unexpected response format:", response);
            setArtifactCount(0);
          }
        } catch (error) {
          console.error("Error fetching artifact count:", error.message);
          setArtifactCount(0);
        }
      };

      fetchArtifactCount();
    } else {
      setArtifactCount(0);
    }
  }, [selectedSubject]);
  const handleArtifactChange = (event, newValue) => {
    setArtifact(newValue);
  };
  const textColorLayer2 = useThemeRender("textColorLayer2");
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          border: `10px solid ${useThemeRender("borderColorLayer3")}`,
          background: useThemeRender("backgroundColorLayer3"),
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          padding: { sx: "8px", md: "40px" },
          width: "700px",
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: useThemeRender("drawerOverlayColorLayer1"),
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: useThemeRender("textColorLayer2"),
        }}
      >
        Remove Subject
      </DialogTitle>
      <DialogContent
        sx={{
          fontWeight: 400,
          lineHeight: "20px",
          color: useThemeRender("textColorLayer2"),
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={2}
          sx={{ backgroundColor: "#CCEAF6" }}
        >
          <IconButton>
            <InfoOutlinedIcon
              sx={{
                color: useThemeRender("iconColor2"),
                marginBottom: 6,
              }}
            />
          </IconButton>

          <Box ml={1}>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 700,
                color: "#000",
                fontSize: "18px",
              }}
            >
              We identified {artifactCount} artifacts associated with this
              subject.
              <br />
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: "#000",
              }}
            >
              Please select where to move the artifacts.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" mb={2} sx={{ backgroundColor: "#EFF5FC" }}>
          <Box ml={1}>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 700,
                color: "#000",
                fontSize: "14px",
              }}
            >
              Original subject
              <br />
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: "#000",
              }}
            >
              {selectedSubject}
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="body2">Move artifact to:</Typography>
          <Typography
            variant="caption"
            sx={{ color: useThemeRender("textColorLayer4"), fontSize: "12px" }}
          >
            *Required
          </Typography>
        </Box>

        <Autocomplete
          onChange={handleArtifactChange}
          options={[...options]}
          getOptionLabel={(option) => option.name || ""}
          value={artifact}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Please select"
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  color: textColorLayer2,
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  fontSize: "var(--Font-Size-Small, 14px)",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "var(--Line-Height-Small, 20px)",
                },
              }}
            />
          )}
          sx={{
            background: useThemeRender("insideCardBackgroundColorLayer1"),

            "& .MuiInputBase-root": {
              height: "36px",
              color: useThemeRender("textColorLayer2"),
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: useThemeRender("iconColor1"),
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              "& fieldset": {
                borderColor: useThemeRender("borderColorLayer2"),
              },
              "&:hover fieldset": {
                borderColor: useThemeRender("borderColorLayer2"),
              },
              "& .MuiAutocomplete-clearIndicator": {
                color: useThemeRender("iconColor1"),
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "right" }}>
        <Box className="ButtonBox">
          <Box
            sx={{
              border:
                "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
            }}
            className="ButtonStyle"
            onClick={handleCloseDialog}
          >
            <Typography
              sx={{
                color: "#005CBC",
                fontWeight: 700,
              }}
              className="textStyle"
            >
              Cancel
            </Typography>
          </Box>
          <Box
            sx={{
              background: "var(--Tokens-Button-Primary, #005CBC)",
            }}
            className="ButtonStyle"
            onClick={handleConfirmClick}
          >
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 700,
                p: "5px 10px",
              }}
              className="textStyle"
            >
              Confirm
            </Typography>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePopUp;
