import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useThemeRender from "../../theme/useThemeRender";
import {
  Typography,
  styled,
  Box,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { getFormattedDate } from "../../utils/function";

const StyledBox = styled(Box)({
  display: "flex",
  width: "full",
  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
  background: "var(--Tokens-Background-Layer-02, #EFF5FC)",
  padding: "8px 16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
});

const StyledTypography = styled(Typography)({
  color: "var(--Tokens-Text-Secondary, #616161)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 14px)",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
});

const StyledTypography2 = styled(Typography)({
  color: "var(--Tokens-Text-Secondary, #616161)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 12px)",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "var(--Line-Height-Small, 18px)", // 142.857%
});

export default function CuratorApprovalModal({
  approveModal,
  isDeny,
  setIsModalVisible,
  makePendingApprovalApiCall,
  user,
}) {
  const [explanation, setExplantation] = useState("");
  const handleClose = () => {
    setIsModalVisible(false);
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  const denyhandler = (status) => {
    makePendingApprovalApiCall(status, explanation);
  };

  return (
    <Dialog
      open={approveModal}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          border: `10px solid  ${useThemeRender("borderColorLayer3")}`,
          background: useThemeRender("cardBackgroundColorLayer1"),
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          padding: isMobile ? "20px" : "40px",
          width: isMobile ? "100%" : "500px",
        },
      }}
    >
      <DialogContent
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          sx={{
            color: "var(--Tokens-Text-Primary, #3A3A3A)",
            textAlign: "center",
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H5, 24px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H5, 32px)",
          }}
        >
          {isDeny ? "Deny" : "Approver details "}
        </Typography>
        {isDeny && (
          <Typography
            sx={{
              color: "var(--Tokens-Text-Primary, #3A3A3A)",
              textAlign: "center",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
              fontSize: "var(--Font-Size-H5, 14px)",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "var(--Line-Height-H5, 20px)",
            }}
          >
            Please explain the reason of the denial
          </Typography>
        )}
        <StyledBox>
          <StyledTypography>Name</StyledTypography>
          <StyledTypography2>{`${user?.FirstName} ${user?.LastName}`}</StyledTypography2>
        </StyledBox>
        <StyledBox>
          <StyledTypography>Date</StyledTypography>
          <StyledTypography2>{getFormattedDate()}</StyledTypography2>
        </StyledBox>
        {isDeny && (
          <Box
            sx={{
              display: "flex",
              width: "full",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                //color: isDarkTheme && darkTheme.textColorLayer1,
              }}
            >
              Explanation
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{fontSize: "10px",   fontWeight: 500, color: "#616161" }}
            >
              *Required
            </Typography>
            </Box>
            <TextField
              placeholder="Type here"
              multiline
              minRows={4} // Controls the initial height
              maxRows={8}
              value={explanation}
              onChange={(e) => setExplantation(e.target.value)}
              style={{
                width: "100%",
                //   backgroundColor: isDarkTheme
                //     ? darkTheme.cardBackgroundColorLayer1
                //     : lightTheme.cardBackgroundColorLayer1,
                border: "1px solid white",
              }}
              InputProps={{
                style: {
                  // color: isDarkTheme
                  //   ? darkTheme.textColorLayer1
                  //   : lightTheme.textColorLayer1,
                },
              }}
              // {...register("comments")}
              variant="outlined"
              size="small"
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            font: "bold",
            fontWeight: 700,
            borderRadius: "24px",
            "&:hover": {
              background: "#004B9A",
            },
            marginRight: "10px",
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        {!isDeny && (
          <Button
            onClick={() => denyhandler("approve")}
            variant="contained"
            sx={{
              font: "bold",
              fontWeight: 700,
              textTransform: "none",
              borderRadius: "24px",
              backgroundColor: "#005CBC",
            }}
          >
            Approve
          </Button>
        )}
        {isDeny && (
          <Button
            onClick={() => denyhandler("deny")}
            variant="contained"
            disabled={explanation === "" && true}
            sx={{
              font: "bold",
              fontWeight: 700,
              textTransform: "none",
              borderRadius: "24px",
              backgroundColor: "#005CBC",
            }}
          >
            Deny
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
