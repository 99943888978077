import React from "react";
import { Box } from "@mui/material";
import NavigationLink from "./NavigationLink";
import NavigationDropDown from "./NavigationDropDown";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../theme/Theme";
const NavigationBar = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  const { navigations } = useSelector((state) => state.nav);

  console.log(navigations, "----navigations------");

  return (
    <Box
      sx={{
        display: { xs: "none", md: "none", lg: "flex" },
        padding: "8px 24px",
        alignItems: "center",
        gap: "24px",
        alignSelf: "stretch",
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer3
          : lightTheme.backgroundColorLayer3,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, fontSize: "14px", color: "#616161" }}>
        {navigations.map((nav) =>
          nav.children ? (
            <NavigationDropDown
              key={nav.navigationId}
              label={nav.lable}
              menuItems={nav.children.map((child) => ({
                label: child.lable,
                url: child.url,
              }))}
            />
          ) : (
            <NavigationLink
              key={nav.navigationId}
              to={nav.url}
              label={nav.lable}
            />
          )
        )}
      </Box>
    </Box>
  );
};

export default NavigationBar;
