import { Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilterListIcon from "@mui/icons-material/FilterList";

const SearchFilter = ({
  name,
  setContentTitle,
  setContentType,
  setStatus,
  setSector,
  setCountry,
  setLanguage,
  setSubject,
  contentType,
  contentTitle,
  status,
  sector,
  country,
  language,
  subject,
}) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [visibleValue, setVisibileValue] = useState("");
  const handleInputValue = (event) => {
    const value = event.target.value;
    switch (name) {
      case "title":
        setContentTitle(value);
        break;
      case "type":
        setContentType(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "sector":
        setSector(value);
        break;
      case "country":
        setCountry(value);
        break;
      case "language":
        setLanguage(value);
        break;
      case "subject":
        setSubject(value);
        break;
      default:
        console.warn(`Unhandled name: ${name}`);
    }
  };

  useEffect(() => {
    switch (name) {
      case "title":
        setVisibileValue(contentTitle || "");
        break;
      case "type":
        setVisibileValue(contentType || "");
        break;
      case "status":
        setVisibileValue(status || "");
        break;
      case "sector":
        setVisibileValue(sector || "");
        break;
      case "country":
        setVisibileValue(country || "");
        break;
      case "language":
        setVisibileValue(language || "");
        break;
      case "subject":
        setVisibileValue(subject || "");
        break;
      default:
        console.warn(`Unhandled name: ${name}`);
    }
  }, [contentType, contentTitle, status, sector, country, language, subject]);
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        value={visibleValue}
        onChange={(e) => handleInputValue(e)}
        placeholder={`User Input`}
        sx={{
          width: "70%",
          "& .MuiInputBase-root": {
            height: "24px",
            border: isDarkTheme ? "1px solid white" : "",
          },
          "& .MuiOutlinedInput-input": {
            padding: "4px 8px",
            color: isDarkTheme ? "white" : "black",
          },
        }}
      />
      <FilterListIcon
        sx={{
          marginLeft: "8px",
          width: "16px",
          height: "16px",
          fill: isDarkTheme ? "white" : "black",
        }}
      />
    </Box>
  );
};

export default SearchFilter;
