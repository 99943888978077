import { Box, Typography } from '@mui/material'
import React from 'react'
import useThemeRender from '../../../../theme/useThemeRender'

function AccordionDetail({header,data}) {
    const insideCardBackgroundColorLayer6 = useThemeRender("insideCardBackgroundColorLayer6")
    const textColorLayer1 = useThemeRender("textColorLayer1");
    console.log(data,'data')
  return (
    <Box
    sx={{
      display: "flex",
      padding: "8px 16px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "4px",
      alignSelf: "stretch",
      borderRadius: "8px",
      background: useThemeRender("insideCardBackgroundColorLayer5")
    }}
  >
    <Typography
      sx={{
        color: useThemeRender("textColorLayer4"),
        fontFamily: "var(--Typography-Font-Family-Body, Inter)",
        fontSize: "var(--Font-Size-Small, 14px)",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "var(--Line-Height-Small, 20px)",
      }}
    >
      {header}
    </Typography>
   
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
      }}
    >
     {data.length > 0 && data?.map((item)=>(
      <Box
        sx={{
          display: "flex",
          padding: "8px 4px",
          alignItems: "center",
          gap: "2px",
          borderRadius: "4px",
          background: insideCardBackgroundColorLayer6,
          color: textColorLayer1,
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "133%",
        }}
      >
      {header =="Items" ?  item.title : item.name}
      </Box>
     ))}
    </Box>                 
  </Box>
  )
}

export default AccordionDetail