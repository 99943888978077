// src/styles.js
export const commonTypographyStyles = {
  fontFamily: "Inter", // Ensure Inter is loaded in your project
  fontStyle: "normal",
  lineHeight: "20px",
};

export const titleTypographyStyles = {
  ...commonTypographyStyles,
  fontSize: "18px", // Font size for H6
  fontWeight: 700, // Bold
  lineHeight: "28px", // Line height
};

export const descriptionTypographyStyles = {
  ...commonTypographyStyles,
  fontSize: "14px", // Small font size
  fontWeight: 500, // Medium weight
};

export const labelTypographyStyles = {
  ...commonTypographyStyles,
  fontSize: "14px", // Font size
  fontWeight: 700, // Font weight (bold)
};

export const valueTypographyStyles = {
  ...commonTypographyStyles,
  fontSize: "14px", // Font size
  fontWeight: 500, // Medium weight
};

export const flexBoxStyles = {
  display: "flex",
  alignItems: "flex-start",
  gap: "24px", // Custom gap
  alignSelf: "stretch",
  width: "100%",
};
