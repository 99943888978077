import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import InternalHeader from "./InternalHeader";
import InternalFilter from "./InternalFilter";
import InternalCommonSection from "./CommonSection/InternalCommonSection";
import { useSelector } from "react-redux";
import ViewSummary from "../ViewSummary/ViewSummary";

export default function InternalPageLayout({ pageData, header }) {
  const selectedView = useSelector((state) => state.view);
  const [grid, setGrid] = useState(selectedView);
  const [selectedSection, setSelectedSection] = useState(null);
  const uniqueKey = require("unique-key");

  useEffect(() => {
    setGrid(selectedView);
  }, [selectedView]);

  return (
    <Box
      sx={{
        mt: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        mb: "16px",
        paddingX: { xs: "16px", md: "53px" },
      }}
    >
      <InternalHeader header={header} />
      <Box sx={{ zIndex: 1000 }}>
        <InternalFilter
          setGrid={setGrid}
          pageData={pageData}
          header={header}
          grid={grid}
          setSelection={setSelectedSection}
        />
      </Box>
      {pageData?.internalSubPages?.map((section, index) => {
        return (
          <InternalCommonSection
            data={section}
            key={`${uniqueKey("key_")}-menu-item`}
            grid={grid}
            selectedSection={selectedSection}
            setGrid={setGrid}
          />
        );
      })}
      <ViewSummary />
    </Box>
  );
}

InternalPageLayout.propTypes = {
  pageData: PropTypes.shape({
    internalSubPages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
        content: PropTypes.string,
      })
    ),
  }),
  header: PropTypes.string.isRequired,
};
