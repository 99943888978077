import {
  Box,
  Divider,
  useMediaQuery,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TextInputField from "../../../utils/Fields/TextInputField";
import DropZone from "../../../utils/Upload/Dropzone";
import { useDropzone } from "react-dropzone";
import {
  UploadInfo,
  UploadInfoDescription,
  UploadInfoLabel,
  ViewDocumentButton,
} from "./curatorApprovalStyle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getTextFieldStyles } from "../../../utils/FieldStyles/FieldStyles";
import { getFormattedDate } from "../../../utils/function";
import noPreviewImage from "../../../assets/no-preview.png";
import SelectedImage from "./SelectedImage";
import useThemeRender from "../../../theme/useThemeRender";
import { darkTheme } from "../../../theme/Theme";

const ArtifactManagement = ({
  register,
  artifactManagementArray,
  setArtifactManagementArray,
  downloadFile,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [isImageDropped, setIsImageDropped] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState([]);
  const isDarkTheme = useSelector((store) => store.theme);
  const onDrop = (acceptedFiles) => {
    const newImage = acceptedFiles[0];
    const imageWithPreview = Object.assign(newImage, {
      preview: URL.createObjectURL(newImage),
    });
    setSelectedImage([imageWithPreview]);
    setIsImageDropped(true);

    const currentStateData = {
      ...artifactManagementArray[currentSelectedIndex],
    };
    currentStateData.imageInfo = newImage;
    const newArtifactManagementData = [...artifactManagementArray];
    newArtifactManagementData[currentSelectedIndex] = currentStateData;
    setArtifactManagementArray(newArtifactManagementData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop,
    maxFiles: 1,
  });

  const onDragEnd = (result, index) => {
    if (!result.destination) return;

    const reorderedImages = Array.from(selectedImage);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);
    setSelectedImage(reorderedImages);

    const currentStateData = {
      ...artifactManagementArray[index],
    };
    currentStateData.imageInfo = reorderedImages;
    const newArtifactManagementData = [...artifactManagementArray];
    newArtifactManagementData[index] = currentStateData;
    setArtifactManagementArray(newArtifactManagementData);
  };

  return (
    <Box
      sx={{
        background: useThemeRender("cardBackgroundColorLayer2"),
      }}
    >
      {artifactManagementArray?.map((item, index) => (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "16px",
              padding: "16px",
            }}
            key={index}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: { sm: "100%", md: "360px" },
              }}
            >
              {/* <TextInputField
                label="Version Number"
                type="number"
                errors={false}
                key={`${index}-versionNumber-pending`}
                register={register}
                name="versionNumber"
                value={parseInt(item?.versionNumber)}
                disabled={true}
                onChange={(e) => {
                  const currentStateData = {
                    ...item,
                  };
                  currentStateData.versionNumber = parseInt(e?.target.value);
                  const newArtifactManagementData = [
                    ...artifactManagementArray,
                  ];
                  newArtifactManagementData[index] = currentStateData;
                  setArtifactManagementArray(newArtifactManagementData);
                }}
                width={!isMobile ? 250 : "100%"}
              /> */}

              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  //color: isDarkTheme && darkTheme.textColorLayer1,
                }}
              >
                Version number
              </Typography>

              <TextField
                id="outlined-size-small"
                size="small"
                key={`${item?.versionNumber}-text-field`}
                type="number"
                value={parseInt(item?.versionNumber)}
                disabled={true}
                width={!isMobile ? 250 : "100%"}
                autoComplete="off"
                sx={getTextFieldStyles(isMobile, isDarkTheme, darkTheme)}
                onChange={(e) => {
                  const currentStateData = {
                    ...item,
                  };
                  currentStateData.versionNumber = parseInt(e?.target.value);
                  const newArtifactManagementData = [
                    ...artifactManagementArray,
                  ];
                  newArtifactManagementData[index] = currentStateData;
                  setArtifactManagementArray(newArtifactManagementData);
                }}
                style={{
                  backgroundColor: "#F3F7FD",
                }}
              />
              {/* <Box>
                {isImageDropped ? (
                  <SelectedImage
                    label="Thumbnail"
                    images={selectedImage}
                    onDragEnd={(event, index) => onDragEnd(event, index)}
                  />
                ) : (
                  <DropZone
                    onDrop={() => {
                      setCurrentSelectedIndex(index);
                      onDrop(index);
                    }}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    label="Thumbnail"
                  />
                )}
              </Box> */}
              <Box>
                <SelectedImage
                  label="Thumbnail"
                  images={[
                    {
                      preview: noPreviewImage,
                      name: item?.fileName,
                    },
                  ]}
                  onDragEnd={(event, index) => onDragEnd(event, index)}
                />
              </Box>
              <ViewDocumentButton
                variant="outlined"
                sx={{
                  marginLeft: isMobile && "auto",
                }}
                onClick={() => downloadFile(item?.fileId, item?.fileName)}
              >
                Open item
                <ArrowForwardIcon sx={{ height: "16px", width: "16px" }} />
              </ViewDocumentButton>
            </Box>

            <UploadInfo sx={{ width: { sm: "100%", md: "400px" } }}>
              <UploadInfoLabel>Uploaded by</UploadInfoLabel>
              <UploadInfoDescription>{item?.uploadedBy}</UploadInfoDescription>
            </UploadInfo>

            <UploadInfo sx={{ width: { sm: "100%", md: "400px" } }}>
              <UploadInfoLabel>Upload date</UploadInfoLabel>
              <UploadInfoDescription>
                {getFormattedDate(item?.uploadedDate)}
              </UploadInfoDescription>
            </UploadInfo>
          </Box>
          {artifactManagementArray.length !== index + 1 && (
            <Divider sx={{ width: "100%", height: "20px" }} />
          )}
        </>
      ))}
    </Box>
  );
};

export default ArtifactManagement;
