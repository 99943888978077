import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomAccordion from "../../utils/CustomAccordian/CustomAccordian";
import AccordionChild from "../../utils/CommonAccordianChild/AccordionChild";
import Notes from "../../utils/CommonAccordianChild/Notes/Notes";
import { useSelector } from "react-redux";
import { darkTheme } from "../../theme/Theme";
import { formatDate, sortTableData } from "../../utils/function";

export default function UploadTabSection({ data = [], search }) {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const isDarkTheme = useSelector((store) => store.theme);
  const [filtered, setFiltered] = useState([]);
  const handleAccordionToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  useEffect(() => {
    console.log(data, "----sort---------");

    const newData = sortTableData(data, "title");
    const filteredRecords = newData?.filter((record) =>
      record.title.toLowerCase().includes(search.toLowerCase())
    );
    setFiltered(filteredRecords);
  }, [search, data]);
  // Handle empty data
  if (!filtered.length) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          padding: "16px",
          background: isDarkTheme ? darkTheme.backgroundColorLayer2 : "#FBFCFE",
          borderRadius: "16px",
          boxShadow:
            "var(--Elevation-X-Elevation-03, 0px) var(--Elevation-Y-Elevation-03, 2px) var(--Elevation-Blur-Elevation-03, 24px) var(--Elevation-Spread-Elevation-03, 8px) var(--Elevation-Colors-Elevation-03, rgba(0, 92, 188, 0.12))",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          No records found.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "16px",
        gap: "16px",
        borderRadius: "16px",
        background: isDarkTheme ? darkTheme.backgroundColorLayer2 : "#FBFCFE",
        boxShadow:
          "var(--Elevation-X-Elevation-03, 0px) var(--Elevation-Y-Elevation-03, 2px) var(--Elevation-Blur-Elevation-03, 24px) var(--Elevation-Spread-Elevation-03, 8px) var(--Elevation-Colors-Elevation-03, rgba(0, 92, 188, 0.12))",
      }}
    >
      {filtered?.map((accordion, index) => {
        // Transform the accordion data
        const details = [
          { label: "Visibility", content: accordion.visibility },
          { label: "Sectors", content: accordion.sector },
          { label: "Country", content: accordion.country },
          { label: "Uploaded by ", content: accordion.uploadedBy },
          { label: "Language", content: accordion.language },
          { label: "Upload date", content: formatDate(accordion.uploadedDate) },
        ];

        const sectionData = [
          { label: "Subjects", content: accordion?.subject || [] },
        ];

        return (
          <CustomAccordion
            key={accordion?.id}
            headerText={accordion.title}
            footerText={accordion.uploadedBy}
            expanded={expandedIndex === index}
            onToggle={() => handleAccordionToggle(index)}
          >
            <AccordionChild
              accordionContent={details}
              sectionData={sectionData}
              component={
                <Notes
                  headerText="Document"
                  notes={accordion?.documentNotes || ""}
                />
              }
            />
          </CustomAccordion>
        );
      })}
    </Box>
  );
}
