import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Button } from "pepsico-ds";
import { useSelector } from "react-redux";
import DataTable from "./DataTable";
import { TableWrapper } from "./style";
import { CURATOR_PAGE_API_CONSTANT } from "../../../constant/constant";
import { curatorCSVFileDownload } from "../../../utils/function";
import CustomCuratorFilter from "../CustomCuratorFilter";
import downloadIcon from "../../../assets/download_icon.png";
import useThemeRender from "../../../theme/useThemeRender";

const ArtifactHistory = ({ pageName, Tabledata }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [pageData, setPageData] = useState([]);
    const serachText = useSelector((state) => state.curatorPageLookUpData).search;
  const [filterValue, setFilterValue] = useState({
    contentType: null,
    visibility: null,
    contentLevel: null,
  });
  const curatorPageLookUpData = useSelector(
    (state) => state.curatorPageLookUpData
  );

  useEffect(() => {
    setPageData(Tabledata);
  }, [Tabledata]);

  useEffect(() => {
    const filteredArray = Tabledata;
    const filteredRows = [];
    if (filterValue?.contentLevel !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.subjects?.includes(filterValue?.contentLevel)) {
          filteredRows.push(currentRow);
        }
      });
    }

    if (filterValue?.visibility !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.country?.includes(filterValue?.visibility)) {
          filteredRows.push(currentRow);
        }
      });
    }
    if (filterValue?.contentType !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.country?.includes(filterValue?.contentType)) {
          filteredRows.push(currentRow);
        }
      });
    }
    if (
      filterValue?.contentType === null &&
      filterValue?.visibility === null &&
      filterValue?.contentLevel === null
    ) {
      setPageData(Tabledata);
    } else {
      setPageData(filteredRows);
    }
  }, [filterValue]);

  const updatedFilterValue = (value, type) => {
    if (type === "contentType") {
      setFilterValue((preState) => {
        return {
          ...preState,
          contentType: value === undefined ? null : value,
        };
      });
    }

    if (type === "visibility") {
      setFilterValue((preState) => {
        return {
          ...preState,
          visibility: value === undefined ? null : value,
        };
      });
    }

    if (type === "contentLevel") {
      setFilterValue((preState) => {
        return {
          ...preState,
          contentLevel: value === undefined ? null : value,
        };
      });
    }
  };

  useEffect(() => {
    const filteredArray = pageData;
    const filteredRows = [];
    if (filterValue?.contentLevel !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.contentLevel?.includes(filterValue?.contentLevel)) {
          filteredRows.push(currentRow);
        }
      });
    }

    if (filterValue?.contentType !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.contentType?.includes(filterValue?.contentType)) {
          filteredRows.push(currentRow);
        }
      });
    }
    if (filterValue?.visibility !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.visibility?.includes(filterValue?.visibility)) {
          filteredRows.push(currentRow);
        }
      });
    }

    if (
      filterValue?.contentLevel === null &&
      filterValue?.contentType === null &&
      filterValue?.visibility === null
    ) {
      
      setPageData(Tabledata);
    } else {
      setPageData(filteredRows);
      
    }
  }, [filterValue]);

  useEffect(() => {
    const searchedRow = [];
    let timer = null;
    if (serachText !== "" && pageData?.length > 0) {
      timer = setTimeout(() => {
        pageData?.filter((item) => {
          const matchesSearch = Object?.values(item)?.some((value) =>
            String(value)
              ?.toLowerCase()
              ?.includes(serachText?.toLowerCase() || "")
          );
          if (matchesSearch) {
            searchedRow.push(item);
          }
        });
        if (serachText === "") {
          setPageData(Tabledata);
          
        } else {
          setPageData(searchedRow);
          
        }
      }, 250);
    } else if (serachText === '') {
      setPageData(Tabledata);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [serachText]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingBottom: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "8px",
          gap: "8px",
          alignItems: "center",
          alignSelf: "stretch",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "4px" }}>
          <CustomCuratorFilter
            pageName={CURATOR_PAGE_API_CONSTANT.artificat_history}
            filters={curatorPageLookUpData}
            updatedFilterValue={updatedFilterValue}
            filterValue={filterValue}
          />
        </Box>
        <Button
          text="Export"
          size="medium"
          onClick={() => curatorCSVFileDownload(pageData, pageName)}
          isDark={isDarkTheme}
        />
      </Box>

      <Box
        sx={{
          height: "100%",
          padding: "16px",
          background: useThemeRender("cardBackgroundColorLayer1"),
        }}
      >
        <TableWrapper isMobile={isMobile} isDarkTheme={isDarkTheme}>
          <DataTable pageName={pageName} Tabledata={pageData} />
        </TableWrapper>
      </Box>
    </Box>
  );
};

export default ArtifactHistory;
