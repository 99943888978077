import React, { useEffect, useState } from "react";
import { Filter, Search, Button } from "pepsico-ds";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { Box, Autocomplete, TextField, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { buttonStyle, boxStyle, filterStyle } from "./InternalStyle";
import {
  setCountry,
  setFlashColor,
  setIpOwnerShip,
  setSearch,
  setSector,
  setVariety,
} from "../../slices/internalPageSlice";
import useThemeRender from "../../theme/useThemeRender";
import { BASE_URL } from "../../constant/apiUrl";
import PropTypes from "prop-types";
import { getAPI } from "../../API/ApiService";
import Loading from "../../utils/Loader";
import { CustomPopper } from "../../utils/CustomPopper/CustomPopper";
import { getActiveItems } from "../../utils/function";
export default function InternalFilter({
  setGrid,
  pageData,
  header,
  setSelection,
  grid,
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const iconColor1 = useThemeRender("iconColor1");
  const borderColorLayer2 = useThemeRender("borderColorLayer2");
  const isDarkTheme = useSelector((state) => state.theme);

  const [sectorData, setSectorData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [varietyData, setVarietyData] = useState([]);
  const [flashColorData, setFlashColorData] = useState([]);
  const [ipOwnershipData, setIpOwnershipData] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState(sectorData);
  const [filteredSector, setFilteredSector] = useState(countryData);
  const [filteredVariety, setFilteredVariety] = useState(varietyData);
  const [filteredFlashColor, setFilteredFlashColor] = useState(flashColorData);
  const [filteredIpOwnerShip, setFilteredIpOwnerShip] =
    useState(ipOwnershipData);
  const [loading, setLoading] = useState(true);
  const [setError] = useState(null);
  const { variety } = useSelector((state) => state.internalPage);
  const handleSelectedSector = (sectorName) => {
    dispatch(setSector(sectorName));
  };

  const handleSelectedCountry = (countryName) => {
    dispatch(setCountry(countryName));
  };
  const handleSearch = (value) => {
    dispatch(setSearch(value));
  };

  const handleSelect = (view) => {
    setGrid(view);
  };

  const dropDown = pageData?.internalSubPages?.map((item) => {
    return {
      id: item.subPageId,
      label: item.subPageName,
    };
  });

  const handleScrollToSection = (event, value) => {
    const label = value?.label;

    if (label) {
      setSelection(label);
    }
  };

  const handleVarietyChange = (value) => {
    dispatch(setVariety(value));
  };
  const handleSelectedFleshColor = (value) => {
    console.log(value, "valuevalue");
    dispatch(setFlashColor(value));
  };
  const handleIpOwnerShip = (value) => {
    dispatch(setIpOwnerShip(value));
  };

  useEffect(() => {
    setFilteredSector(sectorData);
  }, [sectorData]);
  useEffect(() => {
    setFilteredCountries(countryData);
  }, [countryData]);
  useEffect(() => {
    setFilteredVariety(varietyData);
  }, [varietyData]);
  useEffect(() => {
    setFilteredFlashColor(flashColorData);
  }, [flashColorData]);
  useEffect(() => {
    setFilteredIpOwnerShip(ipOwnershipData);
  }, [ipOwnershipData]);
  const fetchSectorData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/all?lookUpType=SECTOR`
      );
      if (response.status === 200) {
        const activeItems = getActiveItems(response.data);
        setSectorData(activeItems);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchCountryData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/all?lookUpType=COUNTRY`
      );
      if (response.status === 200) {
        const activeItems = getActiveItems(response.data);
        setCountryData(activeItems);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchVarietyData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/all?lookUpType=VARIETY`
      );
      if (response.status === 200) {
        const activeItems = getActiveItems(response.data);
        setVarietyData(activeItems);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchFleshColoryData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/all?lookUpType=FLESH_COLOR`
      );
      if (response.status === 200) {
        const activeItems = getActiveItems(response.data);
        setFlashColorData(activeItems);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchIpOwnershipData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/all?lookUpType=IPOWNERSHIP`
      );
      if (response?.status === 200) {
        const activeItems = getActiveItems(response.data);
        setIpOwnershipData(activeItems);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSectorData();
    if (header !== "Potato variety") {
      fetchCountryData();
      fetchVarietyData();
    } else if (header === "Potato variety") {
      fetchFleshColoryData();
      fetchIpOwnershipData();
    }
  }, [header, setError]);
  const RenderGridButtons = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "2.5px" }}>
        <Button
          onClick={() => handleSelect("tile")}
          style={{
            ...buttonStyle,
            backgroundColor: grid === "tile" ? "#005CBC" : "#fff",
            color: grid === "tile" ? "#fff" : "#005CBC",
            border: grid === "tile" ? "none" : "1px solid #D7E5F8",
          }}
        >
          <GridOnIcon sx={{ color: grid === "tile" ? "#fff" : "#005CBC" }} />
        </Button>

        <Button
          onClick={() => handleSelect("list")}
          style={{
            ...buttonStyle,
            backgroundColor: grid !== "tile" ? "#005CBC" : "#fff",
            color: grid !== "tile" ? "#fff" : "#005CBC",
            border: grid !== "tile" ? "none" : "1px solid #D7E5F8",
          }}
        >
          <ViewListOutlinedIcon
            sx={{ color: grid !== "tile" ? "#fff" : "#005CBC" }}
          />
        </Button>
      </Box>
    );
  };
  if (loading) return <Loading />;
  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }
          : { display: "flex", justifyContent: "space-between", width: "100%" }
      }
    >
      <Box sx={boxStyle}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Autocomplete
            options={dropDown || []}
            getOptionLabel={(option) => option.label}
            sx={{
              ...filterStyle,
              background: backgroundColorLayer3,
              "& .MuiInputBase-root": {
                height: "36px",
                color: textColorLayer2,
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: iconColor1,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColorLayer2,
                },
                "&:hover fieldset": {
                  borderColor: borderColorLayer2,
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: iconColor1,
                },
              },
            }}
            onChange={handleScrollToSection}
            PopperComponent={(props) => (
              <CustomPopper isDarkTheme={isDarkTheme} {...props} />
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Go to Section" />
            )}
          />
        </Box>

        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Filter
            label="Sector"
            onChange={handleSelectedSector}
            options={(filteredSector || []).map((item) => ({
              id: item.id,
              label: item.name,
            }))}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
          {header === "Potato variety" && (
            <>
              <Filter
                label="IP Ownership"
                onChange={handleIpOwnerShip}
                options={(filteredIpOwnerShip || []).map((item) => ({
                  id: item.id,
                  label: item.name,
                }))}
                selection="single"
                size="medium"
                isDark={isDarkTheme}
              />

              <Filter
                label="Flesh Color"
                onChange={handleSelectedFleshColor}
                options={(filteredFlashColor || []).map((item) => ({
                  id: item.id,
                  label: item.name,
                }))}
                selection="single"
                size="medium"
                isDark={isDarkTheme}
              />
            </>
          )}
          {header !== "Potato variety" && (
            <Filter
              label="Country"
              onChange={handleSelectedCountry}
              options={(filteredCountries || []).map((item) => ({
                id: item.id,
                label: item.name,
              }))}
              selection="single"
              size="medium"
              isDark={isDarkTheme}
            />
          )}
          {(header === "Potato Quality" || header === "Potato Storage") && (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Filter
                label="Variety"
                onChange={handleVarietyChange}
                options={(filteredVariety || []).map((item) => ({
                  id: item.id,
                  label: item.name,
                }))}
                selection="single"
                size="medium"
                isDark={isDarkTheme}
                value={variety}
                dropdownPosition="bottom"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={
          isMobile
            ? { display: "flex", justifyContent: "space-between" }
            : { display: "flex", flexDirection: "row", gap: "16px" }
        }
      >
        <Search onUpdate={handleSearch} isDark={isDarkTheme} />
        {header !== "Library" && <RenderGridButtons />}
      </Box>
    </Box>
  );
}
InternalFilter.propTypes = {
  setGrid: PropTypes.func.isRequired,
  pageData: PropTypes.shape({
    internalSubPages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
        content: PropTypes.string,
      })
    ),
  }).isRequired,
  header: PropTypes.string.isRequired,
  setSelection: PropTypes.func.isRequired,
  grid: PropTypes.string.isRequired, // Assuming grid is a string, adjust if necessary
};
