import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"; // Import prop-types
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Filter, Button } from "pepsico-ds";
import { useSelector } from "react-redux";
import { styles } from "./searchFilterStyle";
import { getAPI } from "../../../API/ApiService";
import { BASE_URL } from "../../../constant/apiUrl";
import { useNavigate } from "react-router-dom";
import CustomSearch from "../../../utils/CustomSearch/CustomSearch";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { buttonStyle } from "../../InternalPages/InternalStyle";
const SearchFilter = ({
  setSearch,
  setVariety,
  setSector,
  setFlesh,
  setSelectedValue,
  selectedValue,
  searchText,
  setReload,
  reload,
  grid,
  setGrid,
}) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [sectorData, setSectorData] = useState([]);
  const [varietyData, setVarietyData] = useState([]);
  const [fleshColorData, setFleshColorData] = useState([]);
  const [selectedFilter] = useState([]);
  const [error, setError] = useState(null);
  const [laoding, setLoading] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  const handleSelect = (view) => {
    setGrid(view);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSelectedSector = (sectorName) => {
    setSector(sectorName.label);
  };

  const handleVariety = (sectorName) => {
    setVariety(sectorName.label);
  };

  const handleFreshColor = (sectorName) => {
    setFlesh(sectorName.label);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const token = localStorage.getItem("auth_token");

  const handleNavigate = () => {
    console.log(searchText, "addditya");

    if (searchText !== null && searchText !== undefined && searchText !== "")
      navigate(`/search-results/${searchText}`);
    setReload(!reload);
  };

  useEffect(() => {
    const fetchData = async (lookUpType, setter) => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=${lookUpType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.status === 200) {
          setter(response?.data);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const fetchAllData = async () => {
      setLoading(true);
      try {
        await fetchData("SECTOR", setSectorData);
        await delay(500); // 500ms delay
        await fetchData("VARIETY", setVarietyData);
        await delay(500); // 500ms delay
        await fetchData("FLESH_COLOR", setFleshColorData);
      } catch (err) {
        console.error("Error in fetching data with delay:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [token, setLoading, setError]);

  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "24px",
            }
          : styles.container
      }
    >
      <Box sx={styles.filterWrapper}>
        <Filter
          label="Sector"
          selection="single"
          size="medium"
          isDark={isDarkTheme}
          value={selectedFilter}
          onChange={handleSelectedSector}
          options={sectorData.map((item) => ({
            id: item.id,
            label: item.name,
          }))}
        />
        <Filter
          label="Variety"
          selection="single"
          size="medium"
          isDark={isDarkTheme}
          onChange={handleVariety}
          options={varietyData.map((item) => ({
            id: item.id,
            label: item.name,
          }))}
        />
        <Filter
          label="Flesh color"
          selection="single"
          size="medium"
          isDark={isDarkTheme}
          onChange={handleFreshColor}
          options={fleshColorData.map((item) => ({
            id: item.id,
            label: item.name,
          }))}
        />
      </Box>

      <Box
        sx={
          isMobile
            ? {
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                width: "100%",
              }
            : {
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                alignItems: "flex-end",
              }
        }
      >
        <CustomSearch
          handleNavigate={handleNavigate}
          handleSearch={handleSearch}
          searchText={searchText}
        />
        <Box sx={{ display: "flex", flexDirection: "row", gap: "2.5px" }}>
          <Button
            onClick={() => handleSelect("tile")}
            style={{
              ...buttonStyle,
              backgroundColor: grid === "tile" ? "#005CBC" : "#fff",
              color: grid === "tile" ? "#fff" : "#005CBC",
              border: grid === "tile" ? "none" : "1px solid #D7E5F8",
            }}
          >
            <GridOnIcon sx={{ color: grid === "tile" ? "#fff" : "#005CBC" }} />
          </Button>

          <Button
            onClick={() => handleSelect("list")}
            style={{
              ...buttonStyle,
              backgroundColor: grid !== "tile" ? "#005CBC" : "#fff",
              color: grid !== "tile" ? "#fff" : "#005CBC",
              border: grid !== "tile" ? "none" : "1px solid #D7E5F8",
            }}
          >
            <ViewListOutlinedIcon
              sx={{ color: grid !== "tile" ? "#fff" : "#005CBC" }}
            />
          </Button>
        </Box>
        <Box
          sx={
            isMobile
              ? {
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "8px",
                }
              : styles.resultBox
          }
        >
          <Typography
            sx={
              isDarkTheme
                ? { ...styles.typography, color: "white" }
                : styles.typography
            }
          >
            Show results by
          </Typography>
          <FormControl fullWidth>
            <Select
              value={selectedValue}
              onChange={handleChange}
              displayEmpty
              sx={styles.select(isDarkTheme)}
            >
              <MenuItem value="exactMatch" sx={styles.menuItem(isDarkTheme)}>
                Top Results
              </MenuItem>
              <MenuItem
                value="recommendedMatch "
                sx={styles.menuItem(isDarkTheme)}
              >
                Recommended
              </MenuItem>
              <MenuItem
                value="historicalMatch"
                sx={styles.menuItem(isDarkTheme)}
              >
                Historical
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

// PropType validations
SearchFilter.propTypes = {
  setSearch: PropTypes.func.isRequired,
  setVariety: PropTypes.func.isRequired,
  setSector: PropTypes.func.isRequired,
  setFlesh: PropTypes.func.isRequired,
  setSelectedValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  setReload: PropTypes.func.isRequired,
  reload: PropTypes.bool.isRequired,
};

export default SearchFilter;
