import {
  Box,
  FormControlLabel,
  Radio,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import commonStyles from "../../PotatoProtection/LargeScreen/Landing/addVariteyDrawerStyle";
import { RadioGroupWrapper } from "./curatorApprovalStyle";
import useThemeRender from "../../../theme/useThemeRender";

const CustomCuratorRadioGroup = ({ label, radioGroup = [], onChange, value }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Typography
        sx={{
          ...commonStyles.typographyLabel,
          color: useThemeRender("textColorLayer2"),
        }}
      >
        {label}
      </Typography>
      <RadioGroupWrapper
        row={!isMobile}
        onChange={onChange}
        
        sx={{
          gap: isMobile ? "8px" : "32px",
          "& .MuiTypography-root": {
            color: useThemeRender("textColorLayer4"),
          },
        }}
      >
        {radioGroup?.map((radioItem) => (
          <FormControlLabel value={radioItem?.id} control={<Radio />} label={radioItem?.label} checked={radioItem?.id === value ? true : false} />
        ))}
      </RadioGroupWrapper>
    </Box>
  );
};

export default CustomCuratorRadioGroup;
