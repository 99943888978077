import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { useSelector } from "react-redux";
import PrivacyPolicyUS from "./../../../assets/usprivacy.pdf";
import TermsAndCondition from "./../../../assets/tandc.pdf";
import PrivacyPolicyIndia from "./../../../assets/indiaprivacy.pdf";
import PrivacyPolicyCanada from "./../../../assets/canadaprivacy.pdf";
import PrivacyPolicyCommon from "./../../../assets/commonprivacy.pdf";
export default function LinkHeader() {
  const isDarkTheme = useSelector((state) => state.theme);
  const privacyPolicyUrls = {
    US: PrivacyPolicyUS,
    IN: PrivacyPolicyIndia,
    CANADA: PrivacyPolicyCanada
  };

  const { user } = useSelector((store) => store.user);
  const privacyPolicyUrl = privacyPolicyUrls[user?.countryCode] || PrivacyPolicyCommon;
  // const privacyPolicyUrl = PrivacyPolicyUS
  console.log(user,'useruser')
  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
        width: "100%",
        padding:
          "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-24, 24px)",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "var(--Spacing-spacing-24, 24px)",
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer1
          : lightTheme.backgroundColorLayer1,
      }}
    >
      {/* Link Header */}
      <Box sx={{ display: "flex", gap: 2, fontSize: "14px", color: "#616161" }}>
        <a
          href={privacyPolicyUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Privacy policy
        </a>
        <Link
          to="/content-disclaimer"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Content Disclaimer
        </Link>
        <a
          href={TermsAndCondition}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Terms and conditions
        </a>
        <Link
          to="/customer-support"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Customer support
        </Link>
      </Box>
    </Box>
  );
}
