import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import useThemeRender from "../../../../theme/useThemeRender";
import { useDispatch } from "react-redux";
import { setSpecialProjectDrawer } from "../../../../slices/adminPageSlice";

function DrawerHeader({ header,handleResetData }) {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { xs: "flex-start", sm: "space-between" },
        alignItems: { xs: "flex-start", sm: "flex-start" },
        gap: "16px",
        alignSelf: { xs: "stretch", sm: "auto" },
        flexDirection: { xs: "column" },
        width: "100%",
        padding: "0px 10px",
        paddingBottom: "10px",
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer2"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
          fontSize: "var(--Font-Size-H4, 18px)",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-H4, 40px)",
        }}
      >
        {header}
      </Typography>
      <IconButton
        onClick={handleResetData}
        sx={{
          position: "absolute",
          top: "22px",
          right: "16px",
          zIndex: 10,
        }}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
            fill={useThemeRender("iconColor1")}
          />
        </svg>
      </IconButton>
    </Box>
  );
}

export default DrawerHeader;
