import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Box, Typography } from "@mui/material";
import useThemeRender from "./../../theme/useThemeRender";

export default function SearchHeader({ search }) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "56px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Inter",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "32px",
          color: useThemeRender("textColorLayer2"),
        }}
      >
        Search Results for: {search}
      </Typography>
    </Box>
  );
}

// Defining PropTypes for the component
SearchHeader.propTypes = {
  search: PropTypes.string.isRequired, // search should be a string and is required
};
