import React, { useEffect, useRef, useCallback } from "react";
import { useMediaQuery, Box, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../constant/apiUrl";
import CardComponent from "../InternalPages/Carousal-ExpandedView/CardComponent";
import PropTypes from "prop-types";
import ListViewDesign from "../InternalPages/ListView/ListViewDesign";
import {
  updateDocumentItems,
  setHasMoreDocuments,
  setLoadingDocuments,
  updateVideoItems,
  updateLinkItems,
  setLoadingVideos,
  setLoadingLinks,
  setHasMoreVideos,
  setHasMoreLinks,
} from "../../slices/searchResultSlice";
import Loading from "../../utils/Loader";
export default function SearchItems({
  grid,
  content,
  videoSession,
  search,
  sector,
  variety,
  flesh,
  selectedValue,
  token,
  contenparam,
  linkSession,
  documentSession,
  loading,
}) {
  const {
    videoItems,
    linkItems,
    documentItems,
    hasMoreVideos,
    hasMoreLinks,
    hasMoreDocuments,
    loadingVideos,
    loadingLinks,
    loadingDocuments,
  } = useSelector((state) => state.searchResults);

  const observerRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isDarktheme = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const isLoading = useCallback(
    (contentType) => {
      return (
        (contentType === "video" && loadingVideos) ||
        (contentType === "link" && loadingLinks) ||
        (contentType === "document" && loadingDocuments)
      );
    },
    [loadingVideos, loadingLinks, loadingDocuments]
  );

  const getParams = useCallback(
    (session) => ({
      phrase: search,
      sector: sector || "",
      variety: variety || "",
      fleshColor: flesh || "",
      searchType: selectedValue,
      contentType: contenparam,
      searchSessionId: session,
    }),
    [search, sector, variety, flesh, selectedValue, contenparam]
  );

  const updateItems = useCallback(
    (newContent, contentType) => {
      if (contentType === "document") {
        dispatch(updateDocumentItems([...documentItems, ...newContent]));
        if (newContent.length === 0) dispatch(setHasMoreDocuments(false));
      } else if (contentType === "video") {
        dispatch(updateVideoItems([...videoItems, ...newContent]));
        if (newContent.length === 0) dispatch(setHasMoreVideos(false));
      } else if (contentType === "link") {
        dispatch(updateLinkItems([...linkItems, ...newContent]));
        if (newContent.length === 0) dispatch(setHasMoreLinks(false));
      }
    },
    [documentItems, linkItems, videoItems, dispatch]
  );

  const setLoadingState = useCallback(
    (type, state) => {
      if (type === "video") {
        dispatch(setLoadingVideos(state));
      } else if (type === "link") {
        dispatch(setLoadingLinks(state));
      } else if (type === "document") {
        dispatch(setLoadingDocuments(state));
      }
    },
    [dispatch]
  );

  const handleLoadMore = useCallback(
    async (contentType) => {
      const sessionMap = {
        link: linkSession,
        video: videoSession,
        document: documentSession,
      };

      const session = sessionMap[contentType] || documentSession;

      if (isLoading(contentType)) return; // Prevents triggering if already loading

      setLoadingState(contentType, true); // Set loading state to true

      const url = `${BASE_URL}/smart-search/scroll`;
      const headers = getHeaders(token);
      const params = getParams(session);

      try {
        if (session !== null && session !== undefined) {
          const response = await fetchContent(url, headers, params);

          if (response?.status === 200) {
            const newContent = response?.data?.content || [];
            updateItems(newContent, contentType);
          }
        }
      } catch (error) {
        console.error(`Error fetching ${contentType} data:`, error);
      } finally {
        setLoadingState(contentType, false); // Reset loading state to false
      }
    },
    [
      token,
      setLoadingState,
      videoSession,
      linkSession,
      documentSession,
      getParams,
      isLoading,
      updateItems,
    ]
  );

  const getHeaders = (token) => ({
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  });

  const fetchContent = async (url, headers, params) => {
    return axios.get(url, { headers, params });
  };

  useEffect(() => {
    if (!hasMoreVideos && !hasMoreLinks && !hasMoreDocuments) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // Trigger load more only for relevant content type
          if (contenparam === "document" && hasMoreDocuments)
            handleLoadMore("document");
          if (contenparam === "video" && hasMoreVideos) handleLoadMore("video");
          if (contenparam === "link" && hasMoreLinks) handleLoadMore("link");
        }
      },
      { threshold: 0.5 } // Adjusted to trigger when 50% is in view
    );

    const currentRef = observerRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, [
    hasMoreVideos,
    hasMoreLinks,
    hasMoreDocuments,
    loadingVideos,
    loadingLinks,
    loadingDocuments,
    handleLoadMore,
    contenparam,
  ]);

  if (
    videoItems.length === 0 &&
    linkItems.length === 0 &&
    documentItems?.length === 0
  ) {
    return <div>{`No ${content} found`}</div>;
  }
  if (loading) return <Loading />;
  return (
    <div>
      {grid === "tile" && (
        <Box
          sx={{
            backgroundColor: isMobile && !isDarktheme ? "white" : "none",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            padding: { xs: "16px", md: "none" },
            gap: { xs: "16px", md: "24px" },
            flexWrap: "wrap",
          }}
        >
          {videoItems.length > 0 &&
            content === "Videos" &&
            (videoItems || [])?.map((item) => (
              <CardComponent card={item} key={item.id} content={content} />
            ))}
          {linkItems?.length > 0 &&
            content === "Links" &&
            (linkItems || [])?.map((link) => (
              <CardComponent card={link} key={link.id} content={content} />
            ))}
          {documentItems.length > 0 &&
            content === "Documents" &&
            (documentItems || [])?.map((doc) => (
              <CardComponent card={doc} key={doc.id} content={content} />
            ))}
        </Box>
      )}

      {/* Render Links */}
      {grid !== "tile" && (
        <Box
          sx={{
            backgroundColor: isMobile && !isDarktheme ? "white" : "none",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            padding: { xs: "16px", md: "none" },
            gap: { xs: "16px", md: "24px" },
            flexWrap: "wrap",
          }}
        >
          {documentItems.length > 0 && content === "Documents" && (
            <ListViewDesign data={documentItems} content={content} />
          )}
          {videoItems.length > 0 && content === "Videos" && (
            <ListViewDesign data={videoItems} content={content} />
          )}

          {linkItems.length > 0 && content === "Links" && (
            <ListViewDesign data={linkItems} content={content} />
          )}
        </Box>
      )}

      {/* Observer div */}
      <div ref={observerRef} style={{ height: "1px" }}></div>

      {(loadingVideos || loadingLinks || loadingDocuments) && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

SearchItems.propTypes = {
  grid: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  videoSession: PropTypes.string,
  search: PropTypes.string.isRequired,
  sector: PropTypes.string,
  variety: PropTypes.string,
  flesh: PropTypes.string,
  selectedValue: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  contenparam: PropTypes.string.isRequired,
  documentSession: PropTypes.string,
  linkSession: PropTypes.string,
};
