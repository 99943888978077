import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  Chip,
  Button,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
const CustomSelect = ({
  label,
  value,
  options,
  onChange,
  required = false,
  multiple = true,
}) => {
  const [open, setOpen] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const isDarkTheme = useSelector((state) => state.theme);
  useEffect(() => {
    setTempValue(value);
  }, [value]);
  const handleToggle = (option) => {  
    if (tempValue?.some(item => item?.id === (option?.id))) {
      setTempValue(tempValue?.filter((v) => v?.id !== option?.id));
    } else {
      setTempValue([...tempValue, option]);
    }
  };

  const handleSelectAll = () => {
    if (tempValue?.length === options?.length) {
      setTempValue([]);
    } else {
      setTempValue(options?.map((option) => option));
    }
  };

  const handleRemove = (option) => {
    setTempValue(tempValue?.filter((v) => v?.id !== option));
  };

  const handleSave = () => {
    onChange(tempValue);
    setOpen(false);
  };

  const handleCancel = () => {
    setTempValue(value);
    setOpen(false);
  };
const handleClose = () => {
  setOpen(false) 
  handleCancel()
}
  return (
    <Box
      sx={{
        width: { xs: "300px", md: "400px" },
        marginBottom: 2,
        color: isDarkTheme
          ? darkTheme.textColorLayer1
          : lightTheme.textColorLayer1,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
          {label}
        </Typography>
        {required && (
          <Typography
            variant="subtitle1"
            sx={{ marginBottom: 1, fontSize: "14px", color: "#616161" }}
          >
            *Required
          </Typography>
        )}
      </Box>

      <Select
        multiple={multiple}
        value={tempValue}
        open={open}
        onOpen={() => {
          setTempValue(value);
          setOpen(true);
        }}
        onClose={handleClose}
        renderValue={() => {
          const selectedLabels = tempValue?.map((val) => {
            const option = options?.find((option) =>
              Array.isArray(val) ? val?.some((item) => item?.id === option?.id) : val?.id === option?.id
            );
            return option ? option?.label : "";
          });

          const selectedCount = tempValue?.length;
          const totalOptions = options?.length;

          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                }}
              >
                {selectedLabels[0]}
              </Box>

              {selectedCount <= totalOptions && (
                <Typography
                  variant="body2"
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.textColorLayer1
                      : lightTheme.textColorLayer1,
                    borderRadius: "4px",
                    background: isDarkTheme
                      ? darkTheme.smallTextBackground
                      : lightTheme.smallTextBackground,
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    padding: "2px",
                  }}
                >
                  {selectedCount > 1 ? `+${selectedCount - 1}` : null}
                </Typography>
              )}
            </Box>
          );
        }}
        sx={{
          width: "100%",
          maxWidth: "100%",
          "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            padding: "10px 14px",
            backgroundColor: isDarkTheme
              ? darkTheme.cardBackgroundColorLayer1
              : lightTheme.cardBackgroundColorLayer1,
            border: "1px solid",
            borderColor: isDarkTheme
              ? darkTheme.borderColorLayer1
              : lightTheme.borderColorLayer1,
            borderRadius: 1,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: isDarkTheme
              ? darkTheme.borderColorLayer1
              : lightTheme.borderColorLayer1,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: isDarkTheme
              ? darkTheme.borderColorLayer1
              : lightTheme.borderColorLayer1,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: isDarkTheme
              ? darkTheme.borderColorLayer1
              : lightTheme.borderColorLayer1,
          },
          "& .MuiSelect-icon": {
            color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: isDarkTheme
                ? darkTheme.backgroundColorLayer3
                : lightTheme.backgroundColorLayer3,
              "& .MuiMenuItem-root": {
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
                "&:hover": {
                  backgroundColor: isDarkTheme
                    ? darkTheme.dropdownHoverColorLayer3
                    : lightTheme.dropdownHoverColorLayer3,
                },
              },
            },
          },
        }}
      >
        <Box
          sx={{
            maxHeight: 300,
            overflow: "auto",
            padding: 1,
            Maxwidth: "100%",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
            },
          }}
        >
          <MenuItem
            onClick={handleSelectAll}
            selected={tempValue}
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            <Checkbox
              checked={tempValue?.length === options?.length}
              sx={{
                color: isDarkTheme
                  ? darkTheme.checkBoxLayer1
                  : lightTheme.checkBoxLayer1,
              }}
            />
            <ListItemText
             primaryTypographyProps={{
              sx: {
                color: "var(--Tokens-Text-Secondary, #616161)",
                fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                fontSize: "var(--Font-Size-Small, 14px)",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "var(--Line-Height-Small, 20px)",
                textTransform: "uppercase",
              },
            }}
              primary="Select All"
            />
          </MenuItem>
          <Divider
            sx={{ background: isDarkTheme && darkTheme.textColorLayer1 }}
          />

          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              alignItems: "center",
              flexWrap: "wrap",
              Maxwidth: "100%",
              overflow: "hidden",
            }}
          >
            {tempValue?.map((val) => (
              <Chip
                key={val?.id}
                deleteIcon={<CloseIcon />}
                sx={{
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                  borderRadius: "4px",
                  background: isDarkTheme
                    ? darkTheme.smallTextBackground
                    : lightTheme.smallTextBackground,
                  fontWeight: 400,
                  lineHeight: "18px",
                  padding: "2px",
                  "& .MuiChip-deleteIcon": {
                    backgroundColor: isDarkTheme
                      ? darkTheme.smallTextBackground
                      : lightTheme.smallTextBackground,
                  },
                  mb: 1,
                }}
                label={options?.find((option) => option?.id === val?.id)?.label}
                onDelete={() => handleRemove(val?.id)}
              />
            ))}
          </Box>

          <Divider
            sx={{
              background: isDarkTheme && darkTheme.textColorLayer1,
              marginTop: 0.5,
            }}
          />

          {options?.map((option, ind) => (
            <Box>
              <MenuItem
                key={option?.id}
                onClick={() => handleToggle(option)}
              >
                <Checkbox
                  checked={tempValue?.some((item) => item?.id == option?.id)}
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.checkBoxLayer1
                      : lightTheme.checkBoxLayer1,
                  }}
                />
                <ListItemText primary={option?.label} />
              </MenuItem>
              {ind < options.length - 1 && (
                <Divider
                  sx={{ background: isDarkTheme && darkTheme.textColorLayer1 }}
                />
              )}
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flex: "1 0 0",
            gap: 2,
            padding: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{
              color: "#005CBC",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "20px",
              borderRadius: "24px",
              border: "1px solid #005CBC",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              color: "#FFFFFF",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "20px",
              borderRadius: "24px",
              border: "1px solid #005CBC",
              textTransform: "none",
            }}
          >
            Save options
          </Button>
        </Box>
      </Select>
    </Box>
  );
};

export default CustomSelect;
