import { Box } from "@mui/material";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { CURATOR_LEGAL_APPROVAL_OPTIONS } from "../../../constant/constant";
import CustomCuratorRadioGroup from "./CustomCuratorRadioGroup";
import noPreviewImage from "../../../assets/no-preview.png";
import ImageList from "../../Administration/Screens/LandingPageHero.js/ImageList";
import DropZone from "../../../utils/Upload/Dropzone";
import useThemeRender from "../../../theme/useThemeRender";

const Approval = ({
  isDarkTheme,
  isDisabled,
  approvalSection,
  setApprovalSection,
  uploadArtificatsFiles,
  deleteArtifcateFile,
}) => {
  const [isImageDropped, setIsImageDropped] = useState(false);
  console.log('approvalSection', approvalSection);
  const onDrop = (acceptedFiles) => {
    const newImage = acceptedFiles[0];
    let imageWithPreview = null;
    if (acceptedFiles?.[0]?.path?.indexOf('.pdf') > -1 ) {
      imageWithPreview = Object.assign(noPreviewImage, {
        preview: noPreviewImage,
      });
    } else {
       imageWithPreview = Object.assign(newImage, {
        preview: URL.createObjectURL(newImage),
      });
    }
    uploadArtificatsFiles(acceptedFiles?.[0], true);
    setIsImageDropped(true);
    setApprovalSection((stateData) => {
      return {
        ...stateData,
        dropImage: imageWithPreview,
      };
    });
  };

  const onReplace = async (acceptedFiles) => {
    const newImage = acceptedFiles[0]?.file;
    const imageWithPreview = Object.assign(newImage, {
      preview: URL.createObjectURL(newImage),
    });
    uploadArtificatsFiles(acceptedFiles?.[0]?.file, true);
    setIsImageDropped(true);
    setApprovalSection((stateData) => {
      return {
        ...stateData,
        dropImage: imageWithPreview,
      };
    });
  };

  const handleDeleteClick = async (file) => {
    setIsImageDropped(false);
    deleteArtifcateFile(approvalSection?.uploadedImageId);
  };


  const getSelectedLegalOptions = (selectedValue) => {

    if (selectedValue === false || selectedValue === 3) {
      return 3;
    }
    if (selectedValue === true || selectedValue === 2) {
      return 2;
    }
    if (selectedValue === false || selectedValue === 1) {
      return 1;
    }

    return null;
  };

  const { getRootProps, getInputProps } = useDropzone({
    disabled: isDisabled,
    accept: {
      'image/jpeg': ['.jpeg', '.png'],
      'application/pdf': ['.pdf']
    },
    onDrop,
    maxFiles: 1,
  });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "16px",
        background: useThemeRender("cardBackgroundColorLayer2"),
      }}
    >
      <CustomCuratorRadioGroup
        label={"Have you received legal approval?"}
        value={getSelectedLegalOptions(approvalSection?.legalApproval)}
        onChange={(e) => {
          setApprovalSection((stateData) => {
            return {
              ...stateData,
              legalApproval: parseInt(e?.target?.value),
            };
          });
        }}
        radioGroup={CURATOR_LEGAL_APPROVAL_OPTIONS}
      />

      <Box sx={{ width: { xs: "100%", md: "400px" } }}>
        {isImageDropped ? (
          <ImageList
            images={[approvalSection?.dropImage]}
            onDelete={handleDeleteClick}
            onReplace={onReplace}
          />
        ) : (
          <DropZone
            onDrop={onDrop}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDarkTheme={isDarkTheme}
            label="Document"
            isDisabled={isDisabled}
            uploadImageLabel= "Upload File"
          />
        )}
      </Box>
    </Box>
  );
};

export default Approval;
