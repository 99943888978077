import { darkTheme, lightTheme } from "../../theme/Theme";
import { Popper } from "@mui/material";
import PropTypes from "prop-types";
export function CustomPopper({ isDarkTheme, ...props }) {
  return (
    <Popper
      {...props}
      sx={{
        "& .MuiAutocomplete-listbox": {
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer3
            : lightTheme.backgroundColorLayer3,
          color: isDarkTheme
            ? darkTheme.textColorLayer1
            : lightTheme.textColorLayer1,
          "& .MuiAutocomplete-option:hover": {
            backgroundColor: isDarkTheme
              ? darkTheme.dropdownHoverColorLayer3
              : lightTheme.dropdownHoverColorLayer3,
          },
        },
      }}
    />
  );
}
CustomPopper.propTypes = {
  isDarkTheme: PropTypes.bool, // Indicates if the dark theme is applied
  // Spread props for Popper component; this validation ensures it accepts all valid Popper props.
  // You can extend this to validate specific props if needed.
  props: PropTypes.object,
};
