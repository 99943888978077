import React, { useState } from "react";
import DynamicTable from "../../utils/Table/DynamicTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Typography, Box } from "@mui/material";
import AccordionChild from "../../utils/CommonAccordianChild/AccordionChild";
import Notes from "../../utils/CommonAccordianChild/Notes/Notes";
export default function UploadHistoryTable({ searchText, rows }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const toggleRowExpansion = (rowId) => {
    setExpandedRow((prev) => (prev === rowId ? null : rowId));
  };

  const renderActions = (row) =>
    expandedRow ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  const baseColumns = [
    { id: "actions", label: " ", width: "80px" },
    {
      id: "title",
      label: "CONTENT TITLE",
      width: "200px",
      headerWidth: "100%",
    },
    {
      id: "type",
      label: "CONTENT TYPE",
      width: "200px",
      headerWidth: "100%",
    },
    {
      id: "status",
      label: "STATUS",
      width: "200px",
      cellStyle: "cellStyle",
    },
    { id: "sector", label: "SECTOR", width: "200px" },
    { id: "country", label: "COUNTRY", width: "200px" },
    { id: "language", label: "LANGUAGE", width: "200px" },
    { id: "subject", label: "SUBJECT", width: "200px" },
  ];
  const invitationStatusRenderer = (status, row) => {
    console.log(status, "--staty--");

    let text, backgroundColor;
    switch (status) {
      case "Pending":
        backgroundColor = "#AAD2E2";

        text = "Pending";
        break;
      case "Approved":
        backgroundColor = "#B3DACB";
        text = "Approved";
        break;
      case "DENIED":
        text = "Denied";
        backgroundColor = "#F6CCD2";

        break;
      default:
        text = status;
        break;
    }

    return (
      <Typography
        sx={{
          display: "inline-block", // Ensure the background wraps the text only
          backgroundColor,
          color: "var(--Colors-Black, #000)", // Color
          fontFeatureSettings: "'liga' off, 'clig' off", // Font feature settings
          fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family
          fontSize: "var(--Font-Size-XSmall, 12px)", // Font size
          fontStyle: "normal", // Font style
          fontWeight: 400, // Font weight
          lineHeight: "var(--Line-Height-XSmall, 18px)", // Line height
          padding: "2px 8px", // Optional: Add some padding for better appearance
          borderRadius: "4px", // Optional: Add rounded corners
        }}
      >
        {text}
      </Typography>
    );
  };

  const RenderNotes = () => {
    console.log(selectedRow, "---selected-----");

    return (
      <>
        {selectedRow.status === "DENIED" && (
          <Notes headerText={"Denied"} notes={selectedRow?.rejectedReason} />
        )}
        <Notes headerText={"Documents"} notes={selectedRow?.documentNotes} />
      </>
    );
  };
  const ChildComponent = ({ data }) => {
    const newObject = {
      Visibility: data.visibility,
      Sectors: data.sector,
      Country: data.country,
      "Uploaded by": data.uploadedBy,
      Language: data.language,
      "Upload date": data.uploadedDate,
    };
    const childArray = Object.entries(newObject).map(([label, content]) => ({
      label,
      content,
    }));
    const sectionData = [{ label: "Subjects", content: data?.subject || [] }];

    const renderHistoyChildPage = () => {
      return (
        <Box
          sx={{
            display: "flex",
            padding: "16px", // Equivalent to var(--Spacing-spacing-16, 16px)
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px", // Equivalent to var(--Spacing-spacing-16, 16px)
            flex: "1 0 0", // Flex-grow, flex-shrink, and flex-basis values
            borderTop: "1px solid #DFDFDF", // Equivalent to var(--Tokens-Border-Divider, #DFDFDF)
          }}
        >
          <AccordionChild
            accordionContent={childArray}
            sectionData={sectionData}
            component={<RenderNotes selectedRow={selectedRow} />}
          />
        </Box>
      );
    };

    return <div className="w-full">{renderHistoyChildPage()}</div>;
  };

  const renderChild = (row) => <ChildComponent data={row} />;
  return (
    <DynamicTable
      rows={rows}
      columns={baseColumns}
      renderActions={(row) => renderActions(row)}
      onRowClick={(row) => {
        console.log(row, "--row---");
        setSelectedRow(row);
        toggleRowExpansion(row.id); // Pass row.id correctly
      }}
      showColumnFilters={false}
      expandedRow={expandedRow}
      setExpandedRow={setExpandedRow}
      searchText={searchText}
      rowsPerPage={10}
      renderChildComponent={renderChild}
      cellRenderer={invitationStatusRenderer}
    />
  );
}
