import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, Button } from "@mui/material";
import image from "../../../../assets/spotlight3.png";
import SpotlightCardItem from "./SpotlightCardItem";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useDispatch, useSelector } from "react-redux";
import { setDisplay } from "../../../../slices/adminPageSlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useThemeRender from "../../../../theme/useThemeRender";
import ViewSummary from "../../../ViewSummary/ViewSummary";
import AlertBox from "./AlertBox";
import axios from "axios";
import { getAPI } from "../../../../API/ApiService";
import { BASE_URL } from "../../../../constant/apiUrl";
import Loading from "../../../../utils/Loader";
import { setViewSummaryDrawer } from "../../../../slices/viewSummarySlice";
export default function Spotlight({ flexDirection, width } = {}) {
  const dispatch = useDispatch();
  const [spotlightArray, setSpotlightArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleteSlotId, setDeleteSlotId] = React.useState(false);
  const [fetch, setFetch] = useState(false);
  const isDarkTheme = useSelector((state) => state.theme);
  const token = localStorage.getItem("auth_token");
  const { viewSummaryDrawer, viewPageName, summaryDetails } = useSelector(
    (store) => store.viewSummary
  );
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );

  useEffect(() => {
    dispatch(setDisplay(false));
    dispatch(setViewSummaryDrawer(false));
  }, []);
  useEffect(() => {
    setLoading(true);
    const fetchAllData = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/spotlights`);
        if (response?.status === 200) {
          setSpotlightArray(response?.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchAllData();
  }, [fetch, token]);
  const sortedData = (spotlightArray || [])?.sort(
    (a, b) => a.slotNumber - b.slotNumber
  );
  if (loading) return <Loading />;
  return (
    <div className="flex flex-col gap-4">
      <Box
        sx={{
          display: "flex",
          width: "100%",
          padding: "16px var(--Spacing-spacing-16, 16px)",
          flexDirection: flexDirection ? flexDirection : "column",
          alignItems: "flex-start",
          gap: "16px",
          borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
          backgroundColor: isDarkTheme
            ? darkTheme.backgroundColorLayer1
            : "#FFF",
        }}
      >
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer2
              : lightTheme.textColorLayer2,
            fontFamily: "Inter",
            fontSize: 24,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "32px",
          }}
        >
          {` Landing page - ${spotlightArray?.length} of 9 slots filled`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "2rem",
            flexWrap: "wrap",
          }}
        >
          {(sortedData || [])?.map((item, index) => {
            return (
              <SpotlightCardItem
                key={item?.id}
                card={item}
                width={width}
                setOpen={setOpen}
                contenId={item?.contenId}
                slotNumber={item?.slotNumber}
                id={item?.id}
                setDeleteSlotId={setDeleteSlotId}
              />
            );
          })}
          <Box
            sx={{
              display: "flex",
              width: width ? width : "397px",
              padding: "24px",
              height: "477px",
              flexDirection: "column",
              justifyContent: "center", // Centers content vertically
              alignItems: "center", // Centers content horizontally
              gap: "16px",
              borderRadius: "8px",
              background: "#FBFCFE",
              boxShadow: "0px 0px 24px 0px rgba(0, 92, 188, 0.08)",
              backgroundColor: insideCardBackgroundColorLayer1,
            }}
          >
            <Button
              sx={{
                // display: "flex",
                // padding: "8px 24px", // var(--Spacing-spacing-03, 8px) var(--Spacing-spacing-05, 24px)
                // justifyContent: "center",
                // alignItems: "center",
                gap: "8px", // var(--Spacing-spacing-03, 8px)
                // flex: "1 0 0",
                borderRadius: "24px", // var(--Corner-radius-corner-radius-large, 24px)
                border: "1px solid #005CBC", // var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)
                textTransform: "none",
              }}
            >
              <Typography
                sx={{
                  color: "#005CBC", // var(--Tokens-Button-Outlined, #005CBC)
                  fontFamily: "Inter, sans-serif", // var(--Typography-Font-Family-Body, Inter)
                  fontSize: "16px", // var(--Font-Size-Body, 16px)
                  fontStyle: "normal",
                  fontWeight: 700, // Bold weight
                  lineHeight: "22px", // var(--Line-Height-Body, 22px)
                }}
              >
                Add content
              </Typography>
              <AddCircleOutlineIcon />
            </Button>
          </Box>
          {viewSummaryDrawer && <ViewSummary />}
          <AlertBox
            deleteSlotId={deleteSlotId}
            setFetch={setFetch}
            token={token}
            action={"Remove"}
          />
        </Box>
      </Box>
    </div>
  );
}
