import React, { useState } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { Pagination } from "pepsico-ds";
import CardComponent from "./CardComponent";
import { commonTextStyle } from "../InternalStyle";
import Expanded from "./Expanded";
import useThemeRender from "../../../theme/useThemeRender";
import PropTypes from "prop-types";
export default function InternalCarousal({
  data,
  content,
  flexDirection,
  heading,
  isHeadingBig,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [isExpanded, setIsExpanded] = useState(false);
  const cardsPerPage = 1;
  const totalPages = Math.ceil(data?.length / cardsPerPage);

  const handlePaginationChange = (page) => {
    // Update the current index based on the selected page
    setCurrentIndex((page - 1) * cardsPerPage);
  };
  const handleExpandClick = () => {
    setIsExpanded((prev) => !prev);
  };

  const iconColor1 = useThemeRender("iconColor1");
  const textColorLayer2 = useThemeRender("textColorLayer2");

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        padding: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={
            isHeadingBig
              ? {
                  color: iconColor1,
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
                  fontSize: "clamp(1.25rem, 0.625rem + 2vw, 1.75rem);",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "clamp(1.875rem, 1.0938rem + 2.5vw, 2.5rem);",
                }
              : {
                  ...commonTextStyle,
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "28px",
                  color: textColorLayer2,
                }
          }
        >
          {heading}
        </Typography>

        <Button
          sx={{
            display: "flex",
            flexDirection: "row",
            textTransform: "none",
            gap: "2px",
          }}
          onClick={handleExpandClick}
        >
          <Typography
            sx={{
              color: "#005CBC",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "22px",
            }}
          >
            {isExpanded ? "Collapse" : "Expand All"}
          </Typography>
          {isExpanded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 12 13"
              fill="none"
            >
              <path
                d="M3.705 8.07121L6 5.78121L8.295 8.07121L9 7.36621L6 4.36621L3 7.36621L3.705 8.07121Z"
                fill="#005CBC"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M4.94 5.72656L8 8.7799L11.06 5.72656L12 6.66656L8 10.6666L4 6.66656L4.94 5.72656Z"
                fill="#005CBC"
              />
            </svg>
          )}
        </Button>
      </Box>
      {isExpanded ? (
        <Expanded
          data={data}
          content={content}
          flexDirection={flexDirection}
          heading={heading}
        />
      ) : (
        <>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              overflow: "hidden",
              padding: "15px 0px",
            }}
          >
            {/* Slide Track */}
            <Box
              sx={{
                display: "flex",

                transform: `translateX(-${
                  isMobile ? currentIndex * 105 : currentIndex * 450
                }${isMobile ? "%" : "px"})`,
                transition: "transform 0.5s ease-in-out",
                gap: "16px",
              }}
            >
              {/* Cards */}
              {data?.map((card) => (
                <CardComponent
                  key={card?.id}
                  card={card}
                  isMobile={isMobile}
                  content={content}
                  flexDirection={flexDirection}
                  heading={heading}
                />
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            <Typography
              sx={{
                color: "var(--button-color-button-primary, #005CBC)",
                textAlign: "center",
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                fontSize: "var(--Font-Size-Body, 16px)",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "var(--Line-Height-Body, 22px)",
              }}
            >
              {` ${Math.min(
                isMobile ? currentIndex + 1 : currentIndex + 3,
                data?.length
              )} of ${data?.length}`}
            </Typography>

            <div className="flex flex-row gap-6">
              <Pagination
                currentPage={Math.floor(currentIndex / cardsPerPage) + 1}
                onUpdate={handlePaginationChange}
                pageCount={totalPages}
                size="medium"
                variant="dot"
              />
            </div>
          </Box>
        </>
      )}
    </Box>
  );
}

InternalCarousal.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Assuming each card has an `id`
      // Add other fields specific to your card data if needed
    })
  ).isRequired, // Ensure `data` is an array of objects
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Depending on what `content` is
  flexDirection: PropTypes.oneOf(["row", "column"]), // If flex direction is limited to "row" or "column"
  heading: PropTypes.string, // Heading should be a string
  isHeadingBig: PropTypes.bool, // Boolean flag for heading style
};
