import React, { useEffect, useState } from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import Loading from "../utils/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getAPI } from "../API/ApiService";
import { BASE_URL } from "../constant/apiUrl";
import {
  setCountry,
  setFlashColor,
  setIpOwnerShip,
  setSearch,
  setSector,
  setVariety,
} from "../slices/internalPageSlice";
import { showErrorToast } from "../utils/Toast/toast";
import PropTypes from "prop-types";
function SustainabilitySubpage({ urlName, header }) {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState(null);
  const { favourite } = useSelector((store) => store.internalPage);
  const userId = 1;
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=${urlName}&userId=${userId}`
        );
        if (response?.status === 200) {
          setPageData(response?.data);
          dispatch(setSector(""));
          dispatch(setCountry(""));
          dispatch(setSearch(""));
          dispatch(setVariety(""));
          dispatch(setFlashColor(""));
          dispatch(setIpOwnerShip(""));
        }
      } catch (err) {
        showErrorToast(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [favourite, urlName, dispatch]);
  if (loading) return <Loading />;
  return <InternalPageLayout pageData={pageData} header={header} />;
}

export default SustainabilitySubpage;

SustainabilitySubpage.propTypes = {
  urlName: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};
