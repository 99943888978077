import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedPage: "defaultPage",
  display: false,
  heroForm: {},
  detailsForm: {},
  specialProjectDrawer: false,
  openSpecialProjectPopup: false,
  popupContent: "",
  specialProjectCardData: "",
  specialProjectCardType: "",
  specialProjectTitle: "",
  specialProjectDescription: "",
  specialProjectStatus: true,
  specialProjectEditId: "",
  specialProjectEditData: "",
  selectedListOfValues: "",
  listOfValueSideTrayName: "",
  listOfValueName: "",
  listOfValueSelectedSector: "",
  listOfValueSelectedCountry: "",
  listOfValueStatus: true,
  listOfValuesEndPoint: "",
  listOfFieldValueDescription: "",
  listOfValueSearch: "",
  selectedListOfRowValue: "",
  isOpenListOfvaluePopup: false,
  listOfValuePopupMessage: "",
  listOfValuePopupStatus: "",
  disableNextButton: true,
  fileUploaded: false,
  internalFileDocument:'',
  externalFileDocument:'',
  fileUploadPageName:''
};

const adminPageSlice = createSlice({
  name: "adminpage",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setDisplay: (state, action) => {
      state.display = action.payload;
    },
    updateHeroForm: (state, action) => {
      state.heroForm = action.payload;
    },
    updateDetailsForm: (state, action) => {
      state.detailsForm = action.payload;
    },
    setSpecialProjectDrawer: (state, action) => {
      state.specialProjectDrawer = action.payload;
    },
    setOpenSpecialProjectPopup: (state, action) => {
      state.openSpecialProjectPopup = action.payload;
    },
    setPopupContent: (state, action) => {
      state.popupContent = action.payload;
    },
    setSpecialProjectCardData: (state, action) => {
      state.specialProjectCardData = action.payload;
    },
    setSpecialProjectCardType: (state, action) => {
      state.specialProjectCardType = action.payload;
    },
    setSpecialProjectTitle: (state, action) => {
      state.specialProjectTitle = action.payload;
    },
    setSpecialProjectDescription: (state, action) => {
      state.specialProjectDescription = action.payload;
    },
    setSpecialProjectStatus: (state, action) => {
      state.specialProjectStatus = action.payload;
    },
    setSpecialProjectEditId: (state, action) => {
      state.specialProjectEditId = action.payload;
    },
    setSpecialProjectEditData: (state, action) => {
      state.specialProjectEditData = action.payload;
    },
    setSelectedListOfValues: (state, action) => {
      state.selectedListOfValues = action.payload;
    },
    setlistOfValueSideTrayName: (state, action) => {
      state.listOfValueSideTrayName = action.payload;
    },
    setListOfValueName: (state, action) => {
      state.listOfValueName = action.payload;
    },
    setlistOfValueSelectedSector: (state, action) => {
      state.listOfValueSelectedSector = action.payload;
    },
    setListOfValueSelectedCountry: (state, action) => {
      state.listOfValueSelectedCountry = action.payload;
    },
    setListOfValueStatus: (state, action) => {
      state.listOfValueStatus = action.payload;
    },
    setListOfValuesEndPoint: (state, action) => {
      state.listOfValuesEndPoint = action.payload;
    },
    setListOfFieldValueDescription: (state, action) => {
      state.listOfFieldValueDescription = action.payload;
    },
    setListOfValueSearch: (state, action) => {
      state.listOfValueSearch = action.payload;
    },
    setSelectedListOfRowValue: (state, action) => {
      state.selectedListOfRowValue = action.payload;
    },
    setIsOpenListOfvaluePopup: (state, action) => {
      state.isOpenListOfvaluePopup = action.payload;
    },
    setListOfValuePopupMessage: (state, action) => {
      state.listOfValuePopupMessage = action.payload;
    },
    setListOfValuePopupStatus: (state, action) => {
      state.listOfValuePopupStatus = action.payload;
    },
    setDisableNextButton: (state, action) => {
      state.disableNextButton = action.payload;
    },
    setFileUploaded: (state, action) => {
      state.fileUploaded = action.payload;
    },
    setInternalFileDocument: (state, action) => {
      state.internalFileDocument = action.payload;
    },
    setExternalFileDocument: (state, action) => {
      state.externalFileDocument = action.payload;
    },
    setFileUploadPageName: (state, action) => {
      state.fileUploadPageName = action.payload;
    },
  },
});
export const {
  setPage,
  setDisplay,
  updateHeroForm,
  updateDetailsForm,
  setSpecialProjectDrawer,
  setOpenSpecialProjectPopup,
  setPopupContent,
  setSpecialProjectCardData,
  setSpecialProjectCardType,
  setSpecialProjectTitle,
  setSpecialProjectDescription,
  setSpecialProjectStatus,
  setSpecialProjectEditId,
  setSpecialProjectEditData,
  setSelectedListOfValues,
  setlistOfValueSideTrayName,
  setListOfValueName,
  setlistOfValueSelectedSector,
  setListOfValueSelectedCountry,
  setListOfValueStatus,
  setListOfValuesEndPoint,
  setListOfFieldValueDescription,
  setListOfValueSearch,
  setSelectedListOfRowValue,
  setIsOpenListOfvaluePopup,
  setListOfValuePopupMessage,
  setListOfValuePopupStatus,
  setDisableNextButton,
  setFileUploaded,
  setInternalFileDocument,
  setExternalFileDocument,
  setFileUploadPageName
} = adminPageSlice.actions;
export default adminPageSlice.reducer;
