import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  fritoSearch: "",
  fritoSector: "",
  fritoCountry: "",
  fritoLanguage: "",
  fritoSubject: "",
  fritoimages: {},
  forumData: [],
};

const fritoForumPage = createSlice({
  name: "fritoForumPage",
  initialState,
  reducers: {
    setFritoSearch: (state, action) => {
      state.fritoSearch = action.payload;
    },
    setFritoSector: (state, action) => {
      state.fritoSector = action.payload;
    },
    setFritoCountry: (state, action) => {
      state.fritoCountry = action.payload;
    },
    setFritoLanguage: (state, action) => {
      state.fritoLanguage = action.payload;
    },
    setFritoSubject: (state, action) => {
      state.fritoSubject = action.payload;
    },
    setFritoImage(state, action) {
      const { url } = action.payload;
      state.fritoimages = url;
    },
    clearFritoImages(state, action) {
      state.fritoimages = {};
    },
    setForumData(state, action) {
      state.forumData = action.payload;
    },
  },
});
export const {
  setFritoSearch,
  setFritoSector,
  setFritoCountry,
  setFritoLanguage,
  setFritoSubject,
  setFritoImage,
  clearFritoImages,
  setForumData,
} = fritoForumPage.actions;
export default fritoForumPage.reducer;
