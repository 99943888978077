import React, { useState, useEffect } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../../../../../slices/adminPageSlice";
import ReplaceTableHeader from "./ReplaceTableHeader/ReplaceTableHeader";
import { TabsNavigation, Tab } from "pepsico-ds";
import ReplaceTableSearch from "./ReplaceTableSearch";
import TabSection from "./TabSection/TabSection";
import { darkTheme } from "../../../../../theme/Theme";
import ViewSummary from "../../../../ViewSummary/ViewSummary";
import { BASE_URL } from "../../../../../constant/apiUrl";

import { getAPI } from "../../../../../API/ApiService";
import Loading from "../../../../../utils/Loader";
import AlertBox from "../AlertBox";
export default function RepalceCards() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const { content } = location.state || {};
  const { viewSummaryDrawer } = useSelector((store) => store.viewSummary);
  const { alertMessage } = useSelector((store) => store.spotlight);
  const [document, setDocument] = useState([]);
  const [selected, setSelected] = useState(null);
  const [video, setVideo] = useState([]);
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("auth_token");
  useEffect(() => {
    setLoading(true);
    const fetchAllData = async () => {
      try {
        const respone = await getAPI(
          `${BASE_URL}/internal-pages/page-info/find-by-page-id?pageId=15&userId=1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        console.log(respone, "-----response---------");

        if (respone?.status === 200) {
          setDocument(respone?.data?.internalSubPages[0]?.documents);
          setVideo(respone?.data?.internalSubPages[0]?.videos);
          setLinks(respone?.data?.internalSubPages[0]?.links);
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    fetchAllData();
  }, [token]);

  const handleBack = () => {
    navigate("/admin");
    dispatch(setPage("In the spotlight"));
  };
  const handleClick = (index) => setTab(index);
  if (loading) return <Loading />;
  return (
    <Box
      sx={{
        marginTop: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "40px", // var(--Spacing-spacing-40, 40px)
          padding: "0px 48px",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          alignSelf: "stretch",
        }}
      >
        <Button
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            gap: "4px",
            textTransform: "none",
            padding: 0,
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleBack}
        >
          <ArrowBackIcon
            sx={{ width: "24px", height: "24px", fill: "#005CBC" }}
          />
          <Typography
            sx={{
              color: "var(--Tokens-Button-Borderless, #005CBC)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Body, 16px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Body, 22px)",
            }}
          >
            Back to in the spotlight
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          paddingX: isMobile ? "0px" : "66px",
          paddingBottom: "24px",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            padding: "16px 16px 0px 16px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
            backgroundColor: isDarkTheme
              ? darkTheme.cardBackgroundColorLayer3
              : "#FFF",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
          >
            <ReplaceTableHeader content={content} />
            <Typography
              sx={{
                color: isDarkTheme
                  ? "white"
                  : "var(--Tokens-Text-Primary, #3A3A3A)",
                fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
                fontSize: "var(--Font-Size-H6, 18px)",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "var(--Line-Height-H6, 28px)", // 155.556%
              }}
            >
              Replace with any of the available items
            </Typography>
            <Box
              sx={
                isMobile
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: "16px",
                    }
                  : { display: "flex", justifyContent: "space-between" }
              }
            >
              <TabsNavigation selectedIndex={tab}>
                <Tab
                  iconTrailing={false}
                  orientation="horizontal"
                  label="Documents"
                  onClick={() => handleClick(0)}
                />
                <Tab
                  iconTrailing={false}
                  label="Videos"
                  onClick={() => handleClick(1)}
                />
                <Tab
                  iconTrailing={false}
                  label="links"
                  onClick={() => handleClick(2)}
                />
              </TabsNavigation>
              <ReplaceTableSearch />
            </Box>
            {tab === 0 && (
              <TabSection pageData={document} setSelected={setSelected} />
            )}
            {tab === 1 && (
              <TabSection pageData={video} setSelected={setSelected} />
            )}
            {tab === 2 && (
              <TabSection pageData={links} setSelected={setSelected} />
            )}
          </Box>
          {viewSummaryDrawer && <ViewSummary />}
          {alertMessage && (
            <AlertBox
              token={token}
              action={"Replace"}
              selected={selected}
              content={content}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
