// src/styles.js
export const boxStyles = {
  width: "100%",
  display: "flex",
  padding: "8px 16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  flex: "1 0 0",
  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
};

export const titleStyles = {
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 14px)",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
};

export const contentStyles = {
  fontFeatureSettings: "'liga' off, 'clig' off",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-XSmall, 12px)",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "var(--Line-Height-XSmall, 18px)", // 150%
};
