import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FileFormat from "../../../utils/Upload/FileFormat";
import useThemeRender from "../../../theme/useThemeRender";
const SelectedImage = ({ label, images, onDragEnd }) => {
  const fileInputRef = useRef(null);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Typography
        sx={{
          color: useThemeRender("textColorLayer2"),
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "var(--Font-Size-XSmall, 12px)",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "var(--Line-Height-XSmall, 18px)",
        }}
      >
        {label}
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="image-list">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {images?.map((image, index) => (
                <Draggable
                  key={image.preview}
                  draggableId={image.preview}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        background: "#FFF",
                        borderRadius: "8px",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                        position: "relative", // Required for absolute positioning
                      }}
                    >
                      <img
                        src={image?.preview}
                        alt="Uploaded preview"
                        style={{
                          width: "100%",
                          height: "160px",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />

                      {/* Hidden file input element */}
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        <Box
          sx={{
            padding: "8px",
            display: "flex",
            gap: "8px",
            alignItems: "center",

            "& svg": {
              height: "16px",
              width: "16px",
            },

            "& svg > g > path": {
              fill: "#0078A7",
            },
          }}
        >
          <FileFormat />
          <Typography
            sx={{
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "12px",
              fontWeight: 400,
              color: useThemeRender("textColorLayer2"),
            }}
          >
            {images?.[0]?.name}
          </Typography>
        </Box>
      </DragDropContext>
    </Box>
  );
};

export default SelectedImage;
