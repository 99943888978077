import React from "react";
import { useMediaQuery } from "@mui/material";

const CarouselImage = ({ src, alt }) => {
  const isSmall = useMediaQuery("(max-width:600px)");
  console.log(src, "--src---");

  return (
    <div
      style={{
        position: "relative",
        height: "420px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Image with inline styles for pixel-perfect scaling */}
      <img
        src={`${src[0]?.preview}`}
        alt={alt}
        style={{
          height: "420px",
          width: "100%",
          objectFit: "cover",
          aspectRatio: "16 / 9",

          imageRendering: "pixelated", // Ensures pixel-perfect rendering
        }}
      />

      {/* Gradient overlay */}
      <div
        style={{
          position: "absolute",
          inset: 0,
          background: isSmall
            ? `linear-gradient(179deg, rgba(0, 0, 0, 0.70) 47.25%, rgba(0, 0, 0, 0.00) 91.73%)`
            : `linear-gradient(90deg, rgba(0, 0, 0, 0.60) 28.31%, rgba(0, 0, 0, 0.00) 58.8%)`,
          zIndex: 1, // Ensure gradient is on top of the image
        }}
      />
    </div>
  );
};

export default CarouselImage;
