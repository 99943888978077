const extractUrls = (navigationData) => {
  let urls = [];

  const traverse = (items) => {
    items.forEach((item) => {
      if (item.url) {
        urls.push(item.url);
      }
      if (item.children) {
        traverse(item.children);
      }
    });
  };

  traverse(navigationData);
  return urls;
};
export default extractUrls;
