import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import CustomPagination from "../../../PotatoProtection/LargeScreen/Landing/CustomPagination";
import {
  setListOfValueSearch,
  setListOfValuesEndPoint,
  setlistOfValueSideTrayName,
  setSelectedListOfRowValue,
  setSpecialProjectDrawer,
} from "../../../../slices/adminPageSlice";
import { useDispatch } from "react-redux";
import useThemeRender from "../../../../theme/useThemeRender";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../../constant/apiUrl";
import { getAPI } from "../../../../API/ApiService";

function ListOfValuesTable() {
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const { selectedListOfValues, listOfValuesEndPoint, listOfValueSearch } =
    useSelector((store) => store.adminpage);
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [OriginalData, setOriginalData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const paginatedData =
    filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ||
    [];
  const { favourite } = useSelector((store) => store.internalPage);
  const handleSelect = (row) => {
    dispatch(setSelectedListOfRowValue(row));
    dispatch(setlistOfValueSideTrayName("Edit value"));
    dispatch(setSpecialProjectDrawer(true));
  };
  useEffect(() => {
    switch (selectedListOfValues) {
      case "Content level":
        dispatch(setListOfValuesEndPoint("CONTENT_LEVEL"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Sector":
        dispatch(setListOfValuesEndPoint("SECTOR"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Country":
        dispatch(setListOfValuesEndPoint("COUNTRY"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Region/Country":
        dispatch(setListOfValuesEndPoint("country-sector"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Crop Type":
        dispatch(setListOfValuesEndPoint("CROP_TYPE"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Disclaimer":
        dispatch(setListOfValuesEndPoint("DISCLAIMER"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Language":
        dispatch(setListOfValuesEndPoint("LANGUAGE"));
        dispatch(setListOfValueSearch(""));
        break;
      case "IP Ownership":
        dispatch(setListOfValuesEndPoint("IPOWNERSHIP"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Flesh Color":
        dispatch(setListOfValuesEndPoint("FLESH_COLOR"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Organization":
        dispatch(setListOfValuesEndPoint("ORGANIZATION"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Bussiness Unit":
        dispatch(setListOfValuesEndPoint("BUSINESS_UNIT"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Company":
        dispatch(setListOfValuesEndPoint("COMPANY"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Department":
        dispatch(setListOfValuesEndPoint("DEPARTMENT"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Partner Type":
        dispatch(setListOfValuesEndPoint("PARTNER_TYPE"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Period":
        dispatch(setListOfValuesEndPoint("PERIOD"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Reason For Access":
        dispatch(setListOfValuesEndPoint("REASON_FOR_ACCESS"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Region":
        dispatch(setListOfValuesEndPoint("REGION"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Subject":
        dispatch(setListOfValuesEndPoint("SUBJECT"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Special Projects":
        dispatch(setListOfValuesEndPoint("SPECIAL_PROJECTS"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Variety":
        dispatch(setListOfValuesEndPoint("VARIETY"));
        dispatch(setListOfValueSearch(""));
        break;
      case "Visibility":
        dispatch(setListOfValuesEndPoint("VISIBILITY"));
        dispatch(setListOfValueSearch(""));
        break;
      default:
        break;
    }
  }, [selectedListOfValues]);
  const sortSectorCountryData = (data) => {
    console.log(data, "datadata");
    return data?.sort((a, b) => {
      const sectorNameA = a?.sector?.name?.toLowerCase() || "z";
      const sectorNameB = b?.sector?.name?.toLowerCase() || "z";

      if (sectorNameA < sectorNameB) return -1;
      if (sectorNameA > sectorNameB) return 1;
      return 0;
    });
  };
  const fetchDataSectorCountryData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/find-all-country-sector`
      );
      if (response?.status == 200) {
        const sortedData = sortSectorCountryData(response?.data?.response);
        setOriginalData(sortedData);
        setFilterData(sortedData);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const sortOtherLOV = (data) => {
    return data?.sort((a, b) => {
      const nameA = a?.name?.toLowerCase();
      const nameB = b?.name?.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  };
  const fetchOtherLOVData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/find-all?lookUpTypes=${listOfValuesEndPoint}`
      );
      if (response?.status == 200) {
        const sortedData = sortOtherLOV(
          response?.data?.response?.[listOfValuesEndPoint]
        );
        setOriginalData(sortedData);
        setFilterData(sortedData);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (listOfValuesEndPoint == "country-sector") {
      fetchDataSectorCountryData();
    } else if (listOfValuesEndPoint !== "") {
      fetchOtherLOVData();
    }
  }, [listOfValuesEndPoint, favourite]);

  useEffect(() => {
    if (listOfValuesEndPoint == "country-sector") {
      const filtered = OriginalData?.filter(
        (row) =>
          row?.name
            ?.toLowerCase()
            ?.includes(listOfValueSearch?.toLowerCase()) ||
          row?.sector?.name
            ?.toLowerCase()
            ?.includes(listOfValueSearch?.toLowerCase())
      );
      setFilterData(filtered);
    } else {
      const filtered = OriginalData?.filter(
        (row) =>
          row?.name
            ?.toLowerCase()
            ?.includes(listOfValueSearch?.toLowerCase()) ||
          row?.description
            ?.toLowerCase()
            ?.includes(listOfValueSearch?.toLowerCase())
      );
      setFilterData(filtered);
    }
  }, [listOfValueSearch]);
  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        borderTop: "2px solid #D7E5F8",
        background: useThemeRender("cardBackgroundColorLayer1"),
      }}
    >
      <Table>
        <TableHead>
          <TableRow
            sx={{
              borderBottom: "2px solid #D7E5F8",
            }}
          >
            <TableCell
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                borderBottom: "2px solid #D7E5F8",
                color: textColorLayer2,
              }}
            >
              {listOfValuesEndPoint == "country-sector" ? "Sector" : "Name"}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                borderBottom: "2px solid #D7E5F8",
                color: textColorLayer2,
              }}
            >
              {listOfValuesEndPoint == "country-sector"
                ? "Country"
                : "Description"}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                borderBottom: "2px solid #D7E5F8",
                color: textColorLayer2,
              }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                "&:last-child td": { borderBottom: "none" },
                borderBottom: "2px solid #D7E5F8",
              }}
              onClick={() => handleSelect(row)}
            >
              <TableCell
                sx={{
                  color: textColorLayer2,
                  fontSize: "14px",
                  borderBottom: "2px solid #D7E5F8",
                }}
              >
                {listOfValuesEndPoint == "country-sector"
                  ? row?.sector?.name
                  : row?.name}
              </TableCell>
              <TableCell
                sx={{
                  color: textColorLayer2,
                  fontSize: "14px",
                  borderBottom: "2px solid #D7E5F8",
                }}
              >
                {listOfValuesEndPoint == "country-sector"
                  ? row?.name
                  : row?.description}
              </TableCell>
              <TableCell
                sx={{
                  color: textColorLayer2,
                  fontSize: "14px",
                  borderBottom: "2px solid #D7E5F8",
                }}
              >
                {row?.active ? "Active" : "Inactive"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={Math.ceil(filterData?.length / rowsPerPage)}
        />
      </Box>
    </TableContainer>
  );
}

export default ListOfValuesTable;
