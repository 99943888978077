import { CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <div className="loader-container" data-testid="loader">
      <CircularProgress className="loader" />
    </div>
  );
};

export default Loading;
