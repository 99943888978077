import { toast } from "react-toastify";
import "./toastStyles.css";
import CustomMessage from "./CustomMessage";
const showSuccessToast = (message) => {
  toast.success(<CustomMessage message={message} success={true} />, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    icon: false,
    closeButton: false,
    className: "custom-success-toast",
  });
};

const showErrorToast = (message) => {
  toast.error(<CustomMessage message={message} success={false} />, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    icon: false,
    closeButton: false,
    className: "custom-error-toast", // Add custom class for error
  });
};

export { showErrorToast, showSuccessToast };
