import { Box } from "@mui/material";
import React from "react";
import SelectCurrentCurator from "./SelectCurrentCurator";
import Header from "./Header";
function Curator() {
  return (
    <Box
      sx={{
        display: "flex",
        padding: {
          xs: "8px 14px 0",
          sm: "12px 32px 0",
          md: "16px 52px 0",
        },
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Header />
      <SelectCurrentCurator />
    </Box>
  );
}
export default Curator;
