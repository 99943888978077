import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
import CustomSelect from "./CustomSelect";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { setDisableNextButton } from "../../slices/adminPageSlice";
import { useDispatch } from "react-redux";
import { getActiveItems } from "../../utils/function";
const quillModules = {
  // toolbar: [
  //   ["bold", "italic", "underline"],
  //   [{ align: "" }, { align: "center" }, { align: "right" }],
  //   [{ list: "ordered" }, { list: "bullet" }],
  // ],
  toolbar: false
};
const optimizedStyles = (isDarkTheme) => `
 .editor-container {
    position: relative;
    width:100%;
  }

  .editor-container .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
    box-shadow: none; 
  }

  .editor-container .ql-container {
    height: 200px; 
    width: 100%;
    border: 1px solid #C0C0C0; 
    border-radius:4px;
    box-shadow: none;
    outline: none; 
    color: ${
      isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1
    };
  }
.editor-container .ql-container .ql-editor.ql-blank::before {
    color: ${
      isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4
    };
  }
  .editor-container .ql-container:before {
  content: '';
  position: absolute;
  top: -1px; 
  left: 0;
  right: 0;
  height: 1px;
  background-color: #C0C0C0;
  border-radius: 4px;
}
`;
const ExternalStepTwoForm = ({
  externalData,
  setExternalData,
  handleDropDown,
}) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [sectorData, setSectorData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const handleQuillChange = (setter) => (value) => {
    setter((prevData) => ({
      ...prevData,
      notes: value,
    }));
  };
  useEffect(() => {
    const fetchSectorData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=SECTOR`
        );
        if (response?.status === 200) {
          const transformedData = response?.data?.map(item => {
            const { name, ...rest } = item; 
            return { ...rest, label: name }; 
          });
          const activeItems = getActiveItems(transformedData);
          setSectorData(activeItems);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchCountries = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=COUNTRY`
        );
        if (response?.status === 200) {
          const transformedData = response?.data?.map(item => {
            const { name, ...rest } = item; 
            return { ...rest, label: name }; 
          });
          const activeItems = getActiveItems(transformedData);
          setCountryData(activeItems);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchSubject = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=SUBJECT`
        );
        if (response?.status === 200) {
          const transformedData = response?.data?.map(item => {
            const { name, ...rest } = item; 
            return { ...rest, label: name }; 
          });
          const activeItems = getActiveItems(transformedData);
          setSubjectData(activeItems);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSectorData();
    fetchCountries();
    fetchSubject();
  }, []);
  if (typeof window !== "undefined") {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = optimizedStyles(isDarkTheme);
    document.head.appendChild(styleSheet);
  }
  const sectionStyles = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
  };
  const [selectedSectorData,setSelectedSectorData] = useState([])
  const [selectedCountryData,setSelectedCountryData] = useState([])
  const [selectedSubjectData,setSelectedSubjectData] = useState([])
  
  useEffect(()=>{
      const selectedData = sectorData?.filter(item => externalData?.sectorIds?.includes(item?.id));
      setSelectedSectorData(selectedData)
  },[externalData?.sectorIds,sectorData])
  useEffect(()=>{
      const selectedData = subjectData?.filter(item => externalData?.subjectIds?.includes(item?.id));
      setSelectedSubjectData(selectedData)
  },[externalData?.subjectIds,subjectData])
  useEffect(()=>{
    const selectedData = countryData?.filter(item => externalData?.countryIds?.includes(item?.id));
    setSelectedCountryData(selectedData)
  },[externalData?.countryIds,countryData])
  const handleSelect = (e,name) => {
    const ids = e?.map(item => item?.id);
      handleDropDown(name, ids);
  }
  const options = {
    subject: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    country: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    sector: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    specialProject: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
  };
  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,

    width: "100%",
  };
  const renderEditor = (value, onChange, placeholder) => (
    <Box className="editor-container">
      <ReactQuill
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={quillModules}
        style={{ height: "100%", width: "100%" }}
      />
    </Box>
  );
  const handleTitleChange = (e) => {
    const title = e.target.value;
    setExternalData((prevData) => ({
      ...prevData,
      contentTitle: title,
    }));
  };
  useEffect(() => {
    dispatch(setDisableNextButton(true));
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        ...sectionStyles,
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer1"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "32px",
          mb: 4,
        }}
      >
        Step 2: Metadata
      </Typography>
      <Box>
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
          }}
        >
          Content title
        </Typography>
        <TextField
          placeholder="Add Title"
          value={externalData.contentTitle}
          onChange={handleTitleChange}
          size="small"
          sx={{
            borderRadius: "4px",
            // width: { xs: "140%", md: "200%" },
            width: { xs: "300px", md: "400px" },
            "& .MuiInputBase-input::placeholder": {
              color: isDarkTheme
                ? darkTheme.textColorLayer4
                : lightTheme.textColorLayer4,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&:hover fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&.Mui-focused fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
            },
            "& .MuiInputBase-input": {
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            },
          }}
        />
      </Box>

      <CustomSelect
        label="Subjects"
        value={selectedSubjectData}
        onChange={(e)=>handleSelect(e,'subjectIds')}
        options={subjectData}
        required={true}
      />

      <CustomSelect
        label="Sectors"
        value={selectedSectorData}
        onChange={(e)=>handleSelect(e,'sectorIds')}
        options={sectorData}
        required={true}
      />
      <CustomSelect
        label="Countries"
        value={selectedCountryData}
        onChange={(e)=>handleSelect(e,'countryIds')}
        options={countryData}
        required={true}
      />

      <Box sx={baseBoxStyle}>
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
            mb: 1,
            ml: -2,
          }}
        >
          Document Notes
        </Typography>
        {renderEditor(
          externalData.notes,
          handleQuillChange(setExternalData),
          "Type here"
        )}
      </Box>
    </Box>
  );
};

export default ExternalStepTwoForm;
