import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
function Notification({ open, setOpen , message ,type }) {
 const dispatch = useDispatch()
  const handleClose = () => {
   dispatch(setOpen(false));
  };
  const notificationTypes = {
    success: {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="white"/>
    </svg>,
      backgroundColor: "#008553",
    },
    error: {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="white"/>
    </svg>,
      backgroundColor: "#D40020",
    },
  };
  const { icon, backgroundColor } = notificationTypes[type] || {};
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          "& .MuiAlert-icon": {
            display: "none",
          },
          "& .MuiAlert-message": {
            p: "0",
          },
          "& .MuiSnackbar-root": {
            borderRadius: "4px",
          },
          background: backgroundColor,
          padding: "8px 16px",
          borderRadius: "4px !important",
          maxWidth: "500px",
        }}
        action={null}
      >
        <Box
          sx={{
            display: "flex",
            gap: "clamp(15px, 2vw, 25px)",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px" }}>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"
                fill="white"
              />
            </svg> */}
            {icon}
            <p
              style={{
                marginTop: "2px",
                color: "#fff",
                fontWeight: 400,
                fontSize: "clamp(13px, 2vw, 14px)",
                textAlign: "start",
              }}
              className="textStyle"
            >
              {message}
            </p>
          </Box>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            sx={{ mt: "1px" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="white"
              />
            </svg>
          </IconButton>
        </Box>
      </Alert>
    </Snackbar>
  );
}

export default Notification;
