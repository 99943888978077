import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import Loading from "../utils/Loader";

const Logout = () => {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    logout();
  }, []);

  const logout = async () => {
    try {
      // Remove all tokens managed by Okta
      await oktaAuth.tokenManager.clear();

      // Sign out the user from Okta and redirect them to the postLogoutRedirectUri
      await oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin, // Ensure this is configured in Okta settings
        revokeAccessToken: true, // Revoke access token to prevent reuse
        revokeRefreshToken: true, // Revoke refresh token if applicable
      });
      // Clear custom tokens or app-specific data from localStorage/sessionStorage
      localStorage.clear(); // or localStorage.removeItem("key_name");
      sessionStorage.clear();
      navigate("/login");
    } catch (error) {
      console.error("Error during sign-out:", error);
      navigate("/login");
    }
  };

  return <Loading />;
};

export default Logout;
