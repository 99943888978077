import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  Switch,
  Button,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomMultiSelect from "./CustomMultiSelect";
import { setView } from "./../../slices/viewSlice";
import { toggleDarkTheme } from "../../slices/themeSlice";
import { darkTheme, lightTheme } from "../../theme/Theme";
import { getAPI, postAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { getActiveItems } from "../../utils/function";
import useThemeRender from "../../theme/useThemeRender";
const PreferencesDrawer = ({ open, onClose,setDrawerOpen }) => {
  const [language, setLanguage] = useState();
  const [region, setRegion] = useState([]);
  const [country, setCountry] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const [fleshColor, setFleshColor] = useState([]);

  const [tempLanguage, setTempLanguage] = useState([]);
  const [tempRegion, setTempRegion] = useState([]);
  const [tempCountry, setTempCountry] = useState([]);
  const [tempSubjects, setTempSubjects] = useState([]);
  const [tempVarieties, setTempVarieties] = useState([]);
  const [tempFleshColor, setTempFleshColor] = useState([]);
  const [drawerHeight, setDrawerHeight] = useState("90%");
  const [prevLanguage,setPrevLanguage] = useState()
  const [prevRegion, setPrevRegion] = useState([]);
  const [prevCountry, setPrevCountry] = useState([]);
  const [prevSubjects, setPrevSubjects] = useState([]);
  const [prevVarieties, setPrevVarieties] = useState([]);
  const [prevFleshColor, setPrevFleshColor] = useState([]);
  const dispatch = useDispatch();
  const selectedView = useSelector((state) => state.view);
  const handleViewChange = (event) => {
    dispatch(setView(event.target.value));
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleLanguageChange = (event) => {
    const uniqueToSecondArray = prevLanguage
    ?.filter(secondItem => ![event.target.value]?.some(firstItem => firstItem.id === secondItem.id))
    ?.map(item => ({ ...item, mode: "PURGE" }));
    const newArray = [...[{id : event.target.value.id ,name : event.target.value.name , mode:'PERSIST'}], ...uniqueToSecondArray];
    setTempLanguage(event.target.value);
    setPrevLanguage(newArray)
    console.log(event?.target?.value,'event?.target?.value')
  };
  const isDarkTheme = useSelector((state) => state.theme);
  const handleSave = async () => {
    try {
      setLanguage(tempLanguage);
      setRegion(tempRegion);
      setCountry(tempCountry);
      setSubjects(tempSubjects);
      setVarieties(tempVarieties);
      setFleshColor(tempFleshColor);
      const payload = {
        appPreferencesDTO: {
          theme: isDarkTheme ? "DARK" : "LIGHT",
          view: selectedView === "tile" ? "TILE" : "LIST",
          USER_PREFERENCES_LANGUAGE: prevLanguage
        },
        userPreferencesDTOS: {
          USER_PREFERENCES_REGION: prevRegion,
          USER_PREFERENCES_COUNTRY: prevCountry,
          USER_PREFERENCES_SUBJECT: prevSubjects,
          USER_PREFERENCES_VARIETY: prevVarieties,
          USER_PREFERENCES_FLESH_COLOR: prevFleshColor,
        },
      };
      console.log("payload", payload);
      
      try {
        const response = await postAPI(`${BASE_URL}/preferences/save`, payload);
        if (response?.status === 200) {
          console.log("API Response:", response);
          onClose()
        }
      }catch (error){
        console.error("Error saving preferences:", error);
      }
    } catch (error) {
      console.error("Error saving preferences:", error);
    }
  };

  const handleCancel = () => {
    setTempLanguage(language);
    setTempRegion(region);
    setTempCountry(country);
    setTempSubjects(subjects);
    setTempVarieties(varieties);
    setTempFleshColor(fleshColor);
    onClose();
  };

  const handleResizeStart = (e) => {
    e.preventDefault();

    const handleResize = (moveEvent) => {
      const currentY = moveEvent.clientY || moveEvent.touches[0].clientY;
      const newHeight = `calc(100% - ${currentY}px)`;
      setDrawerHeight(newHeight);
    };

    const stopResize = () => {
      window.removeEventListener("mousemove", handleResize);
      window.removeEventListener("mouseup", stopResize);
      window.removeEventListener("touchmove", handleResize);
      window.removeEventListener("touchend", stopResize);
    };

    window.addEventListener("mousemove", handleResize);
    window.addEventListener("mouseup", stopResize);
    window.addEventListener("touchmove", handleResize);
    window.addEventListener("touchend", stopResize);
  };
  const handleToggle = () => {
    dispatch(toggleDarkTheme());
  };
  const [languageOption, setLanguageOption] = useState([]);
  const [regionOption, setRegionOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [subjectOption, setSubjectOption] = useState([]);
  const [varietyOption, setVarietyOption] = useState([]);
  const [fleshColorOption, setFleshColorOption] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstTimeOpen,setFirstTimeOpen] = useState(true)
  useEffect(() => {
    const fetchData = async (endPoint) => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/find-all?lookUpTypes=${endPoint}`
        );
        if (response?.status === 200) {
          const endpointOptionsMapping = {
            LANGUAGE: setLanguageOption,
            REGION: setRegionOption,
            COUNTRY: setCountryOption,
            SUBJECT: setSubjectOption,
            VARIETY: setVarietyOption,
            FLESH_COLOR: setFleshColorOption,
          };
        
          const activeItems = getActiveItems(response?.data?.response?.[endPoint]);
        
          if (endpointOptionsMapping[endPoint]) {
            endpointOptionsMapping[endPoint](activeItems);
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData("LANGUAGE");
    fetchData("REGION");
    fetchData("COUNTRY");
    fetchData("SUBJECT");
    fetchData("VARIETY");
    fetchData("FLESH_COLOR");
  }, []);

  useEffect(()=>{
    if(languageOption?.length > 0){
      const fetchUserPreferences = async () => {
        try {
          const response = await getAPI(
            `${BASE_URL}/preferences/find-by-user-id`
          );
          if (response?.status === 200) {
            const preferences = response?.data?.response;
            const selectedLanguage = languageOption?.find((item) => item?.id === preferences?.appPreferencesDTO?.USER_PREFERENCES_LANGUAGE[0]?.id);
            const language = preferences?.appPreferencesDTO?.USER_PREFERENCES_LANGUAGE
            const region = preferences?.userPreferencesDTOS?.USER_PREFERENCES_REGION
            const country = preferences?.userPreferencesDTOS?.USER_PREFERENCES_COUNTRY
            const subject = preferences?.userPreferencesDTOS?.USER_PREFERENCES_SUBJECT
            const variety = preferences?.userPreferencesDTOS?.USER_PREFERENCES_VARIETY
            const fleshColor = preferences?.userPreferencesDTOS?.USER_PREFERENCES_FLESH_COLOR
            setTempLanguage(
              selectedLanguage
            );
            setPrevLanguage(
              language || []
            );
            setTempRegion(
              region || []
            );
            setPrevRegion(
              region || []
            );
            setTempCountry(
              country || []
            );
            setPrevCountry(
              country || []
            );
            setTempSubjects(
              subject || []
            );
            setPrevSubjects(
              subject || []
            );
            setTempVarieties(
              variety || []
            );
            setPrevVarieties(
              variety || []
            );
            setTempFleshColor(
              fleshColor || []
            );
            setPrevFleshColor(
              fleshColor || []
            );
            
            if (
             ( language?.length === 0 ||
              region?.length === 0 ||
              country?.length === 0 ||
              subject?.length === 0 ||
              variety?.length === 0 ||
              fleshColor?.length === 0) &&
              firstTimeOpen
            ) {
                setDrawerOpen(true);
                setFirstTimeOpen(false);
            }
          }
        } catch (err) {
          console.error("Error fetching user preferences:", err);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      fetchUserPreferences();
    }
  },[open,languageOption])
  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          height: isMobile ? drawerHeight : "100%",
          overflow: "auto",
          background: useThemeRender("backgroundColorLayer3")
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: useThemeRender("drawerOverlayColorLayer1")
          },
        },
      }}
    >
      {isMobile && (
        <Box
          sx={{
            width: "36px",
            height: "5px",
            background:
              "linear-gradient(0deg, rgba(61, 61, 61, 0.5) 0%, rgba(61, 61, 61, 0.5) 100%), rgba(127, 127, 127, 0.4)",
            backgroundBlendMode: "overlay, luminosity",
            borderRadius: "2.5px",
            position: "absolute",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            cursor: "ns-resize",
            zIndex: 1,
          }}
          onMouseDown={handleResizeStart}
          onTouchStart={handleResizeStart}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          fontWeight: 700,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          Preferences
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon
            style={{
              color: useThemeRender("iconColor1"),
            }}
          />
        </IconButton>
      </Box>

      <Box
        sx={{
          width: isMobile ? "90%" : 350,
          padding: 2,
          backgroundColor: useThemeRender("backgroundColorLayer1"),
          mb: 2,
          mx: isMobile ? 2 : 4,
        }}
        role="presentation"
      >
        <Typography
          sx={{
            fontWeight: 700,
            marginBottom: 2,
            marginTop: 4,
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          Application
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          <Typography>Dark Theme</Typography>
          <Switch checked={isDarkTheme} onChange={handleToggle} />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>Default View</Typography>
            <RadioGroup row value={selectedView} onChange={handleViewChange}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <FormControlLabel
                  value="tile"
                  control={<Radio />}
                  label="Tile"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <FormControlLabel
                  value="list"
                  control={<Radio />}
                  label="List"
                />
              </Box>
            </RadioGroup>
          </Box>
        </Box>
        <Box
          sx={{
            marginBottom: 2,
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          <Typography variant="subtitle2" sx={{ marginBottom: 0.5 }}>
            Language
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="language-select-label"
              id="language-select"
              value={tempLanguage}
              onChange={handleLanguageChange}
              sx={{
                backgroundColor: useThemeRender("cardBackgroundColorLayer1"),
                height: "48px",
                color: useThemeRender("textColorLayer1"),
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: useThemeRender("borderColorLayer1"),
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: useThemeRender("borderColorLayer1"),
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: useThemeRender("borderColorLayer1"),
                },
                "& .MuiSelect-icon": {
                  color: useThemeRender("iconColor1"),
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor:
                      isDarkTheme && darkTheme.backgroundColorLayer3,
                  },
                },
              }}
            >
              {languageOption.map((item) => (
                <MenuItem
                  value={item}
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.textColorLayer1
                      : lightTheme.textColorLayer1,
                  }}
                >
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <CustomMultiSelect
          label="Region"
          value={tempRegion}
          onChange={setTempRegion}
          options={regionOption}
          prevSelectedValues={prevRegion}
          setPrevSelectedValues={setPrevRegion}
        />

        <CustomMultiSelect
          label="Country"
          value={tempCountry}
          onChange={setTempCountry}
          options={countryOption}
          prevSelectedValues={prevCountry}
          setPrevSelectedValues={setPrevCountry}
        />

        <Typography
          sx={{
            fontWeight: 700,
            marginBottom: 2,
            marginTop: 4,
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          Content
        </Typography>

        <CustomMultiSelect
          label="Subjects"
          value={tempSubjects}
          onChange={setTempSubjects}
          options={subjectOption}
          prevSelectedValues={prevSubjects}
          setPrevSelectedValues={setPrevSubjects}
        />

        <CustomMultiSelect
          label="Varieties"
          value={tempVarieties}
          onChange={setTempVarieties}
          options={varietyOption}
          prevSelectedValues={prevVarieties}
          setPrevSelectedValues={setPrevVarieties}
        />

        <CustomMultiSelect
          label="Flesh Color"
          value={tempFleshColor}
          onChange={setTempFleshColor}
          options={fleshColorOption}
          prevSelectedValues={prevFleshColor}
          setPrevSelectedValues={setPrevFleshColor}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "64px",
          padding: "16px 24px",
          justifyContent: "space-between",
          alignItems: "center",
          flexShrink: 0,
          alignSelf: "stretch",
          background: "#FBFCFE",
          boxShadow: "0px 1px 0px 0px #DFDFDF inset",
          backgroundColor:  useThemeRender("backgroundColorLayer3")
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flex: "1 0 0",
            gap: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{
              color: "#005CBC",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "20px",
              borderRadius: "24px",
              border: "1px solid #005CBC",
              textTransform:"none"
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              color: "#FFFFFF",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "20px",
              borderRadius: "24px",
              border: "1px solid #005CBC",
              textTransform:"none"
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default PreferencesDrawer;
