import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  sector: "",
  country: "",
  variety: "",
  flashColor: "",
  ipOwnerShip: "",
};

const internalFilter = createSlice({
  name: "internalFilter",
  initialState,
  reducers: {
    setSectorFilter: (state, action) => {
      state.sector = action.payload;
    },
    setCountryFilter: (state, action) => {
      state.country = action.payload;
    },
    setVarietyFilter: (state, action) => {
      state.variety = action.payload;
    },

    setFlashColorFilter: (state, action) => {
      state.flashColor = action.payload;
    },
    setIpOwnerShipFilter: (state, action) => {
      state.ipOwnerShip = action.payload;
    },
  },
});
export const {
  setCountryFilter,
  setFlashColorFilter,
  setIpOwnerShipFilter,
  setSectorFilter,
  setVarietyFilter,
} = internalFilter.actions;
export default internalFilter.reducer;
