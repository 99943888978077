import { darkTheme } from "../../theme/Theme";

const accordionStyles = {
  container: {
    width: "100%",
  },
  summaryBox: (expanded, theme) => ({
    display: "flex",
    height: !expanded ? "104px" : "49px",
    flexDirection: "column",
    alignItems: "flex-start",
    flexShrink: 0,
    alignSelf: "stretch",
    background: theme
      ? darkTheme.insideCardBackgroundColorLayer3
      : "var(--Tokens-Background-Layer-01, #FBFCFE)",
    boxShadow: "0px -2px 0px 0px var(--Tokens-Border-Default, #D7E5F8) inset",
    cursor: "pointer",
    paddingX: "8px",
  }),
  summaryContent: {
    display: "flex",
    width: "100%",
    height: "49px",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    justifyContent: "space-between",
  },
  headerText: {
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-Body, 16px)",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "var(--Line-Height-Body, 22px)", // 137.5%
  },
  expandBox: {
    display: "flex",
    padding: "var(--Spacing-spacing-02, 4px) 0px",
    justifyContent: "center",
    alignItems: "center",
    gap: "var(--Spacing-spacing-02, 4px)",
  },
  expandText: {
    color: "var(--Tokens-Button-Borderless, #005CBC)",
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-Body, 16px)",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "var(--Line-Height-Body, 22px)", // 137.5%
  },
  footerBox: {
    display: "flex",
    height: "38px",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    flexShrink: 0,
    borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
    background: "var(--Tokens-Background-Layer-02, #EFF5FC)",
  },
  footerText: {
    color: "var(--Tokens-Text-Secondary, #616161)",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-Small, 14px)",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
  },
  footerSubText: {
    color: "var(--Tokens-Text-Secondary, #616161)",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-Small, 14px)",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
  },
  detailsBox: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
  },
};

export default accordionStyles;
