import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  approveModal: false,
  isDeny: false,
  editSlider: false,
  actionData: null,
  fetch: false,
};

const invitationSlice = createSlice({
  name: "invitation",
  initialState,
  reducers: {
    setAppoveModal: (state, action) => {
      state.approveModal = action.payload;
    },
    setDeny: (state, action) => {
      state.isDeny = action.payload;
    },
    setEditSlider: (state, action) => {
      state.editSlider = action.payload;
    },
    setActionData: (state, action) => {
      state.actionData = action.payload;
    },
    setFetchPending: (state, action) => {
      state.fetch = action.payload;
    },
  },
});
export const {
  setAppoveModal,
  setDeny,
  setEditSlider,
  setActionData,
  setFetchPending,
} = invitationSlice.actions;
export default invitationSlice.reducer;
