import React from "react";
import { Typography, Box, Button } from "@mui/material";
import image from "../../../../assets/spotlight3.png";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import useThemeRender from "../../../../theme/useThemeRender";
import {
  setSummaryDetails,
  setViewPageName,
  setViewSummaryDrawer,
} from "../../../../slices/viewSummarySlice";
import { useNavigate } from "react-router-dom";
import { setAlert } from "../../../../slices/spotlightSlice";
export default function SpotlightCardItem({
  width,

  id,
  card,
  slotNumber,
  setDeleteSlotId,
}) {
  const isDarkTheme = useSelector((state) => state.theme);
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const boxShadowlayerColor1 = useThemeRender("boxShadowlayerColor1");
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleRemove = () => {
    dispatch(setAlert(true));
    setDeleteSlotId(slotNumber);
  };
  const handleReplace = () => {
    navigate(`/admin/replace`, {
      state: { content: card },
    });
    console.log(id, "----------");
  };

  const handleViewSummary = () => {
    dispatch(setViewSummaryDrawer(true));
    dispatch(setSummaryDetails(card));
    dispatch(setViewPageName('Documents'));
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: width ? width : "397px",
        padding: "24px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        borderRadius: "8px",
        background: insideCardBackgroundColorLayer1,
        boxShadow: `0px 0px 24px 0px ${boxShadowlayerColor1}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignSelf: "stretch",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
            fill={isDarkTheme ? "white" : "#3A3A3A"}
          />
        </svg>
        <button
          style={{
            display: "flex",
            width: "110px",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "4px", // Assuming the value for gap is 4px as per the CSS variable.
          }}
          onClick={handleRemove}
        >
          <Typography
            sx={{
              color: "#005CBC",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "Inter",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "22px", // 137.5% of font size 16px
            }}
          >
            Remove
          </Typography>
          <DeleteOutlinedIcon
            style={{ width: "16px", height: "16px", fill: "#005CBC" }}
          />
        </button>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "0px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "12px",
          alignSelf: "stretch",
          borderRadius: "8px",
        }}
      >
        <img src={image} />
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "24px",
          padding: "2px 8px",
          alignItems: "center",
          gap: "2px",
          borderRadius: "4px",
          backgroundColor: "#FFDCBB", // Orange color
        }}
      >
        <Typography
          sx={{
            color: "#000", // Black color
            fontFeatureSettings: "'liga' off, 'clig' off", // Turn off ligatures and contextual forms
            fontFamily: "Inter", // Font family
            fontSize: "12px", // XSmall font size
            fontStyle: "normal", // Normal font style
            fontWeight: 400, // Regular weight
            lineHeight: "18px", // Line height (150%)
          }}
        >
          Slot {`${slotNumber}`}
        </Typography>
      </Box>
      <Typography
        sx={{
          color: textColorLayer2,
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
          fontSize: "var(--Font-Size-H5, 24px)",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-H5, 32px)",
          minHeight: "64px", // 2 lines * lineHeight (32px)
          overflow: "hidden", // Hides overflowing content
          textOverflow: "ellipsis", // Adds ellipsis
          display: "-webkit-box", // Required for multiline ellipsis
          WebkitBoxOrient: "vertical", // Required for multiline ellipsis
          WebkitLineClamp: 2, // Limit to 2 lines
          alignSelf: "stretch",
        }}
      >
        {card?.title}
      </Typography>
      <Typography
        sx={{
          color: textColorLayer2,
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "var(--Font-Size-Body, 16px)",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "var(--Line-Height-Body, 22px)",
          maxHeight: "44px", // 2 lines * lineHeight (32px)
          overflow: "hidden", // Hides overflowing content
          textOverflow: "ellipsis", // Adds ellipsis
          display: "-webkit-box", // Required for multiline ellipsis
          WebkitBoxOrient: "vertical", // Required for multiline ellipsis
          WebkitLineClamp: 2, // Limit to 2 lines
          alignSelf: "stretch",
        }}
      >
        {card?.description}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "16px",
          alignSelf: "stretch",
        }}
      >
        <Button
          sx={{
            display: "flex",
            padding: "4px 16px", // `var(--Spacing-spacing-02, 4px)` for top and bottom, `var(--Spacing-spacing-0, 0px)` for left and right
            justifyContent: "center",
            alignItems: "center",
            gap: "4px", // `var(--Spacing-spacing-02, 4px)`
            cursor: "pointer",
            textTransform: "none",
            borderRadius: "var(--Corner-radius-effect-corner-3, 24px)",
            border:
              "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
          }}
          onClick={handleViewSummary}
        >
          <Typography
            sx={{
              color: "var(--Tokens-Button-Borderless, #005CBC)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Small, 14px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Small, 20px)",
            }}
          >
            View Summary
          </Typography>
          <RemoveRedEyeOutlinedIcon
            sx={{
              fill: "#005CBC",
              width: "20px",
              height: "20px",
            }}
          />
        </Button>
        <Button
          sx={{
            display: "flex",
            padding: "4px 16px", // `var(--Spacing-spacing-02, 4px)` for top and bottom, `var(--Spacing-spacing-0, 0px)` for left and right
            justifyContent: "center",
            alignItems: "center",
            gap: "4px", // `var(--Spacing-spacing-02, 4px)`
            cursor: "pointer",
            textTransform: "none",
            backgroundColor: "#005CBC",
            borderRadius: "var(--Corner-radius-effect-corner-3, 24px)",
            border:
              "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
          }}
          onClick={() => handleReplace()}
        >
          <Typography
            sx={{
              color: "white",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Small, 14px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Small, 20px)",
            }}
          >
            Replace
          </Typography>
          <SwapHorizIcon
            sx={{
              fill: "white",
              width: "20px",
              height: "20px",
            }}
          />
        </Button>
      </Box>
    </Box>
  );
}
