import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import BackToListButton from "./BackToListButton";
import RequestTitle from "./RequestTitle";
import DescriptionCard from "./DescriptionCard";
import { useParams } from "react-router-dom";
import { getAPI } from "../../../API/ApiService";
import { BASE_URL } from "../../../constant/apiUrl";
import Loading from "../../../utils/Loader";
import { showErrorToast } from "../../../utils/Toast/toast";
export default function RequestInformationDetails() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [loading, setLoading] = useState(false);
  const [rfiData, setRfiData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/rfi/${id}`);
        if (response?.status === 200) {
          setRfiData(response?.data);
        }
      } catch (e) {
        showErrorToast("Failed to load RFI");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  if (loading) return <Loading />;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        marginTop: "16px", // Replace with theme spacing if applicable
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          paddingX: isMobile ? "10px" : "53px",
          gap: "24px",
        }}
      >
        <BackToListButton />
        <RequestTitle rfiData={rfiData} id={id} />
        <DescriptionCard rfiData={rfiData} id={id} setRfiData={setRfiData} />
      </Box>
    </Box>
  );
}
