import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  videoItems: [],
  linkItems: [],
  documentItems: [],
  hasMoreVideos: true,
  hasMoreLinks: true,
  hasMoreDocuments: true,
  loadingVideos: false,
  loadingLinks: false,
  loadingDocuments: false,
};

const searchResultSlice = createSlice({
  name: "searchResults",
  initialState,
  reducers: {
    updateVideoItems(state, action) {
      // Filter out duplicates using id
      const newItems = action.payload.filter(
        (item) =>
          !state.videoItems.some((existingItem) => existingItem.id === item.id)
      );
      state.videoItems = [...state.videoItems, ...newItems];
    },
    updateLinkItems(state, action) {
      // Filter out duplicates using id
      const newItems = action.payload.filter(
        (item) =>
          !state.linkItems.some((existingItem) => existingItem.id === item.id)
      );
      state.linkItems = [...state.linkItems, ...newItems];
    },
    updateDocumentItems(state, action) {
      // Filter out duplicates using id
      const newItems = action.payload.filter(
        (item) =>
          !state.documentItems.some(
            (existingItem) => existingItem.id === item.id
          )
      );
      state.documentItems = [...state.documentItems, ...newItems];
    },
    setHasMoreVideos(state, action) {
      state.hasMoreVideos = action.payload;
    },
    setHasMoreLinks(state, action) {
      state.hasMoreLinks = action.payload;
    },
    setHasMoreDocuments(state, action) {
      state.hasMoreDocuments = action.payload;
    },
    setLoadingVideos(state, action) {
      state.loadingVideos = action.payload;
    },
    setLoadingLinks(state, action) {
      state.loadingLinks = action.payload;
    },
    setLoadingDocuments(state, action) {
      state.loadingDocuments = action.payload;
    },
    clearState() {
      return initialState; // Reset state to initial
    },
  },
});

export const {
  updateVideoItems,
  updateLinkItems,
  updateDocumentItems,
  setHasMoreVideos,
  setHasMoreLinks,
  setHasMoreDocuments,
  setLoadingVideos,
  setLoadingLinks,
  setLoadingDocuments,
  clearState,
} = searchResultSlice.actions;

export default searchResultSlice.reducer;
