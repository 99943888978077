import { Box, useMediaQuery } from "@mui/material";
import { Button, Filter, Search } from "pepsico-ds";
import React, { useEffect, useState } from "react";
import {
  setCountry,
  setSearch,
  setSector,
  setVariety,
} from "../../slices/internalPageSlice";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../constant/apiUrl";
import { getAPI } from "../../API/ApiService";
import {
  setFritoCountry,
  setFritoLanguage,
  setFritoSearch,
  setFritoSector,
  setFritoSubject,
} from "../../slices/fritoForumPageSlice";
import { getActiveItems } from "../../utils/function";
function ForumFilter({ setGrid, grid }) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme);

  const [sectorData, setSectorData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [language, setLanguage] = useState([]);
  const [subject, setSubject] = useState([]);
  const [filteredSector, setFilteredSector] = useState(sectorData);
  const [filteredCountries, setFilteredCountries] = useState(countryData);
  const [filteredLanguage, setFilteredLanguage] = useState(language);
  const [filteredSubject, setFilterSubject] = useState(subject);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const handleSelectedSector = (sectorName) => {
    dispatch(setFritoSector(sectorName));
  };
  const handleSelectedCountry = (countryName) => {
    dispatch(setFritoCountry(countryName));
  };
  const handleSearch = (value) => {
    dispatch(setFritoSearch(value));
  };

  const handleSelect = (view) => {
    setGrid(view);
  };

  const handleLanguageChange = (value) => {
    dispatch(setFritoLanguage(value));
  };

  const handleSubjectChange = (value) => {
    dispatch(setFritoSubject(value));
  };

  useEffect(() => {
    setFilteredSector(sectorData);
  }, [sectorData]);
  useEffect(() => {
    setFilteredCountries(countryData);
  }, [countryData]);
  useEffect(() => {
    setFilteredLanguage(language);
  }, [language]);
  useEffect(() => {
    setFilterSubject(subject);
  }, [subject]);

  const fetchSectorData = async () => {
    try {
      const response = await getAPI(`${BASE_URL}/lookup/all?lookUpType=SECTOR`);
      if (response?.status === 200) {
        const activeItems = getActiveItems(response?.data);
        setSectorData(activeItems);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchCountryData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/all?lookUpType=COUNTRY`
      );
      if (response?.status === 200) {
        const activeItems = getActiveItems(response?.data);
        setCountryData(activeItems);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchLanguageData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/all?lookUpType=LANGUAGE`
      );
      if (response?.status === 200) {
        const activeItems = getActiveItems(response?.data);
        setLanguage(activeItems);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchSubjectData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/all?lookUpType=SUBJECT`
      );
      if (response?.status === 200) {
        const activeItems = getActiveItems(response?.data);
        setSubject(activeItems);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSectorData();
    fetchCountryData();
    fetchLanguageData();
    fetchSubjectData();
  }, []);

  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }
          : { display: "flex", justifyContent: "space-between", width: "100%" }
      }
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Filter
            label="Sector"
            onChange={handleSelectedSector}
            options={[
              ...filteredSector?.map((item) => {
                return { id: item.id, label: item.name };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            label="Country"
            onChange={handleSelectedCountry}
            options={[
              ...filteredCountries?.map((item) => {
                return { id: item.id, label: item.name };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            label="Language"
            onChange={handleLanguageChange}
            options={[
              ...filteredLanguage?.map((item) => {
                return { id: item.id, label: item.name };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
          <Filter
            label="Subject"
            onChange={handleSubjectChange}
            options={[
              ...filteredSubject?.map((item) => {
                return { id: item.id, label: item.name };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
        </Box>
      </Box>
      <Box
        sx={
          isMobile
            ? { display: "flex", justifyContent: "space-between" }
            : { display: "flex", flexDirection: "row", gap: "16px" }
        }
      >
        <Search onUpdate={handleSearch} isDark={isDarkTheme} />
        <Box sx={{ display: "flex", flexDirection: "row", gap: "2.5px" }}>
          <Button
            onClick={() => handleSelect("tile")}
            style={{
              display: "flex",
              minWidth: "40px",
              minHeight: "40px",
              height: "32px",
              padding: "8px 4px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: grid === "tile" ? "#005CBC" : "#fff",
              color: grid === "tile" ? "#fff" : "#005CBC",
              border: grid === "tile" ? "none" : "1px solid #D7E5F8",
            }}
          >
            <GridOnIcon sx={{ color: grid === "tile" ? "#fff" : "#005CBC" }} />
          </Button>

          <Button
            onClick={() => handleSelect("list")}
            style={{
              display: "flex",
              minWidth: "40px",
              minHeight: "40px",
              height: "32px",
              padding: "8px 4px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: grid !== "tile" ? "#005CBC" : "#fff",
              color: grid !== "tile" ? "#fff" : "#005CBC",
              border: grid !== "tile" ? "none" : "1px solid #D7E5F8",
            }}
          >
            <ViewListOutlinedIcon
              sx={{ color: grid !== "tile" ? "#fff" : "#005CBC" }}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ForumFilter;
