import { useEffect, useState } from "react";
import extractUrls from "../utils/relevantRoutes";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import SuccessBox from "../components/Upload/SuccessBox";

export default function UserBasedRoute() {
  const { navigations } = useSelector((state) => state.nav);
  const [exist, setExist] = useState(true);
  const location = useLocation();
  const { flagUser } = useSelector((store) => store.user);

  useEffect(() => {
    let newUrls = [];
    newUrls = extractUrls(navigations);

    if (flagUser.roleName === "Curator") {
      newUrls.push("/potato-protection-status/:id");
      newUrls.push("/curator/pending-approval/:id");
      newUrls.push("/curator/yearly-review/:id");
      newUrls.push("/curator/artifact-history/:id");
      newUrls.push("/curator/denied-artificats-history/:id");
    }
    if (flagUser?.roleName === "Administrator") {
      newUrls.push("/potato-protection-status/:id");
      newUrls.push("/curator/pending-approval/:id");
      newUrls.push("/curator/yearly-review/:id");
      newUrls.push("/curator/artifact-history/:id");
      newUrls.push("/curator/denied-artificats-history/:id");
      newUrls.push("/potato-protection-status/:id");
      newUrls.push("/special-project-edit");
      newUrls.push("/admin/replace");
    }

    const matchRoute = (path, pattern) => {
      const pathSegments = path.split("/").filter(Boolean);
      const patternSegments = pattern.split("/").filter(Boolean);

      if (pathSegments.length !== patternSegments.length) return false;

      return patternSegments.every((segment, i) => {
        return segment.startsWith(":") || segment === pathSegments[i];
      });
    };

    const routeExists = newUrls.some((pattern) =>
      matchRoute(location.pathname, pattern)
    );

    setExist(routeExists);
  }, [location, navigations, flagUser]);

  if (exist) return <Outlet />;
  if (!exist)
    return (
      <div>
        <SuccessBox text={true} />
      </div>
    );
}
