import { BASE_URL, FILE_SERVICE_BASE_URL } from "../constant/apiUrl";
import { getAPI, postAPI } from "./ApiService";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  LOOKUP_API_FIELDS,
  CURATOR_PAGE_API_CONSTANT,
} from "../constant/constant";

export const useGetFilterOptions = (filterType) => {
  const fetchFilterOptions = useQuery({
    queryKey: ["filter-options"],
    queryFn: async () => {
      const res = await getAPI(`/lookup/all?lookUpType=${filterType}`);
      return res?.data;
    },
    onError: (error) => {
      console.error("Failed to fetch filter options", error);
    },
  });

  return fetchFilterOptions;
};

export const useGetPendingApprovalList = () => {
  const fetchPendingApprovalList = useQuery({
    queryKey: ["pending-approval-list"],
    queryFn: async () => {
      const res = await getAPI(`${BASE_URL}/curator/pending-approval-list`);
      return res?.data;
    },
    onError: (error) => {
      console.error("Failed to fetch market places", error);
    },
  });

  return fetchPendingApprovalList;
};

export const getCuratorApiListByPageName = async (pageName) => {
  if (pageName === CURATOR_PAGE_API_CONSTANT.pending_approval) {
    return await getAPI(`${BASE_URL}/curator/pending-approval-list`)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        return error;
      });
  } else if (pageName === CURATOR_PAGE_API_CONSTANT.yearly_review) {
    return await getAPI(`${BASE_URL}/curator/yearly-review-list`)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        return error;
      });
  }
};

export const getSelectedPendingApprivalData = async (contentGuid) => {
  return await getAPI(`${BASE_URL}/curator/pending-approval/${contentGuid}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSelectedYearyPendingApprivalData = async (contentGuid) => {
  return await getAPI(`${BASE_URL}/curator/yearly-review/${contentGuid}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const submitPendingApprovalRequest = async (apiPayload) => {
  return await postAPI(
    `${BASE_URL}/curator/pending-approval/approve-content`,
    apiPayload
  )
    .then((response) => {
      // console.log("submitPendingApprovalRequest data", response);
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const denyPendingApprovalRequest = async (apiPayload) => {
  return await postAPI(
    `${BASE_URL}/curator/pending-approval/reject-content`,
    apiPayload
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const submitYearyPendingApprovalRequest = async (apiPayload) => {
  return await postAPI(
    `${BASE_URL}/curator/pending-approval/approve-yearly-review`,
    apiPayload
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const archiveYearlyPendingApprovalRequest = async (apiPayload) => {
  return await postAPI(
    `${BASE_URL}/curator/pending-approval/reject-yearly-review`,
    apiPayload
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const uploadYearlyReviewArtificatsFiles = async (
  filePayload,
  isLegalDoc = false
) => {
  let formData = new FormData();
  if (isLegalDoc) {
    formData.append("file", filePayload);
  } else {
    formData.append(
      "file",
      filePayload.file ? filePayload.file : filePayload?.[0]
    );
  }

  return await postAPI(
    // `${BASE_URL}/file-manager/upload`,
    `${FILE_SERVICE_BASE_URL}/fritolay/file-manager/upload?user=ADMIN`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.log("uploadYearlyReviewArtificatsFiles data", error);
      return error;
    });
};

export const deleteCuratorFile = async (fileId) => {
  if (!fileId) {
    console.error("File ID is required to delete the file.");
    return;
  }

  try {
    const response = await axios.delete(
      `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error deleting the file:", error);
  }
};

export const downloadFileToLocal = async (id, fileName = 'contentFile') => {
  console.log("id", id);
  try {
    const imageResponse = await axios.get(
      `${FILE_SERVICE_BASE_URL}/fritolay/file-manager/download-by-id?fileId=${id}&user=ADMIN`,
      {
        headers: {
          Authorization: `Bearer ${localStorage?.getItem("auth_token")}`,
          "Content-Type": "application/octet-stream",
        },
        responseType: "arraybuffer",
      }
    );
    console.log("id", imageResponse);
    if (imageResponse?.data) {
      let fileType = imageResponse.type || "";
      if (!fileType.match(/^(image|video|application|pdf|text)\//)) {
        fileType = "application/octet-stream"; // Fallback for unsupported types
      }
      const blob = new Blob([imageResponse.data], { type: fileType });

      const url = URL.createObjectURL(blob); //
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      URL.revokeObjectURL(url);
      document.body.removeChild(link);

      return {
        status: 200,
        data: [],
      };
    } else {
      console.warn(`No data found for image ID: ${id}`);
      return null;
    }
  } catch (error) {
    // showErrorToast("Failed to Load Image");
    console.error(`Error fetching image for ID ${id}:`, error);
    return null; // Return null on error
  }
};

export const curatorPageLookUpFirstApiCall = async () => {
  let curtorLookup = {
    specialProjects: [],
    subjects: [],
    sectors: [],
    countries: [],
    language: [],
    subjectMatterExpert: [],
  };
  return Promise.allSettled([
    getSpecialProjectLookUpData(),
    getSubjectLookUpData(),
    getSectorLookUpData(),
    getCountriesLookUpData(),
    getLanguageLookUpData(),
  ])
    .then((response) => {
      if (response.length > 0) {
        curtorLookup = {
          specialProjects: response?.[0].value,
          subjects: response?.[1].value,
          sectors: response?.[2].value,
          countries: response?.[3].value,
          language: response?.[4].value,
        };
      }

      return curtorLookup;
    })
    .catch((error) => {
      console.log("curatorPageLookUpFirstApiCall error ", error);
      return error;
    });
};

export const curatorPageLookUpSecondApiCall = async () => {
  let curtorLookup = {
    disclaimer: [],
    periods: [],
    subjectMatterExpert: [],
    cropType: [],
    visbility: [],
    contentLevel: [],
  };
  return Promise.allSettled([
    getDisclaimerLookUpData(),
    getPeriodLookUpData(),
    getSMELookupData(),
    getCropTypeLookupData(),
    getVisibilityLookup(),
    getContentLevelLookup(),
  ])
    .then((response) => {
      if (response.length > 0) {
        curtorLookup = {
          disclaimer: response?.[0].value,
          periods: response?.[1].value,
          subjectMatterExpert: response?.[2].value,
          cropType: response?.[3].value,
          visibility: response?.[4].value,
          contentLevel: response?.[5].value,
        };
      }

      return curtorLookup;
    })
    .catch((error) => {
      console.log("curatorPageLookUpSecondApiCall error ", error);
      return error;
    });
};

export const getSpecialProjectLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.SPECIAL_PROJECTS}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSubjectLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.SUBJECT}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSectorLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.SECTOR}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCountriesLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.COUNTRY}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getLanguageLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.LANGUAGE}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getDisclaimerLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.DISCLAIMER}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPeriodLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.PERIOD}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCropTypeLookupData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.CROP_TYPE}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getVisibilityLookup = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.VISIBILITY}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getContentLevelLookup = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.CONTENT_LEVEL}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSMELookupData = async () => {
  return await getAPI(`${BASE_URL}/sme/list-sme`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};
