import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import FormStepper from "./FormStepper";
import NavigationButtons from "./NavigationButtons";
import ExternalStepOneForm from "./ExternalStepOneForm";
import ExternalStepTwoForm from "./ExternalStepTwoForm.jsx";
import SuccessBox from "./SuccessBox";
import axios from "axios";
import {BASE_URL, FILE_SERVICE_BASE_URL} from "../../constant/apiUrl.js"
import Loading from "../../utils/Loader.js";
import { showSuccessToast } from "../../utils/Toast/toast";
import { setExternalFileDocument, setFileUploaded, setInternalFileDocument } from "../../slices/adminPageSlice.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const steps = ["STEP 1", "STEP 2"];

const ExternalUpload = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [externalData, setExternalData] = useState({
    contentType: "DOCUMENT",
    contentTitle: "",
    // subjects: [],
    // sectors: [],
    // countries: [],
    notes: "",
    attachmentLinkUrl: "",
    attachmentFileId: "",
    thumbnailFileId: "",
    subjectIds: [],
    sectorIds: [],
    countryIds: [],
    specailProjects: [0],
    languageIds: [0],
  });

  const token = localStorage.getItem("auth_token");
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const dispatch = useDispatch()
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const { fileUploadPageName } = useSelector((store) => store.adminpage);
  const handleSaveDraft = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/content/savedraft`,
        externalData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        showSuccessToast("Draft saved successfully!");
        console.log("data passed", externalData);
        console.log("Response:", response.data);
      } else {
        alert("Failed to save draft");
        console.error("Error:", response.data);
      }
    } catch (error) {
      console.error("Error saving draft:", error);
      alert("Failed to save draft");
    } finally {
      setLoading(false);
    }
  };

  const handleDropDown = (label, val) => {
    setExternalData((prev) => {
      return { ...prev, [label]: val };
    });
  };
useEffect(()=>{
  dispatch(setInternalFileDocument(null));
  dispatch(setFileUploaded(false));
  dispatch(setExternalFileDocument(null));
},[])
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ExternalStepOneForm
            externalData={externalData}
            setExternalData={setExternalData}
          />
        );
      case 1:
        return (
          <ExternalStepTwoForm
            externalData={externalData}
            setExternalData={setExternalData}
            handleDropDown={handleDropDown}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      {!success ? (
        <>
          <Box sx={{ width: "90%", margin: "auto", mt: 2, mb: 2 }}>
            <FormStepper steps={steps} activeStep={activeStep} />
            <Box sx={{ mt: 4 }}>{renderStepContent(activeStep)}</Box>
          </Box>

          <NavigationButtons
            onSaveDraft={handleSaveDraft}
            activeStep={activeStep}
            totalSteps={steps.length}
            handleNext={handleNext}
            handleBack={handleBack}
            setSuccess={setSuccess}
            data={externalData}
            setData={setExternalData}
          />
        </>
      ) : (
        <>
          <SuccessBox setActiveStep={setActiveStep} setSuccess={setSuccess} />
        </>
      )}
      {loading && <Loading />}
    </>
  );
};

export default ExternalUpload;
