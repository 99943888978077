import React, { useEffect, useState } from "react";
import InternalHeader from "../InternalPages/InternalHeader";
import { Box, Button, Card, Typography, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ForumFilter from "./ForumFilter";
import useThemeRender from "../../theme/useThemeRender";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constant/apiUrl";
import { getAPI, patchAPI } from "../../API/ApiService";
import ForumMainPageListView from "./ForumMainPageListView";
import Loading from "../../utils/Loader";

import { setForumData } from "../../slices/fritoForumPageSlice";
import fetchAndCacheImage from "../../utils/fetchandCatchImages";
import { showErrorToast } from "../../utils/Toast/toast";
function FritoForumMainPage() {
  const selectedView = useSelector((state) => state.view);
  console.log(selectedView, "selectedView");
  const [grid, setGrid] = useState(selectedView);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const textColorLayer1 = useThemeRender("textColorLayer1");
  const textColorLayer5 = useThemeRender("textColorLayer5");
  const boxShadowlayerColor1 = useThemeRender("boxShadowlayerColor1");
  const insideCardBackgroundColorLayer6 = useThemeRender(
    "insideCardBackgroundColorLayer6"
  );
  const [loading, setLoading] = useState(true);

  const backgroundColorLayer2 = useThemeRender("backgroundColorLayer2");
  useEffect(() => {
    setGrid(selectedView);
  }, [selectedView]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([]);
  const {
    fritoSearch,
    fritoSector,
    fritoCountry,
    fritoLanguage,
    fritoSubject,
    forumData,
  } = useSelector((state) => state.fritoForumPage);

  function filterContent(
    documents,
    search = "",
    fritoSector = null,
    fritoCountry = null,
    fritoLanguage = null,
    fritoSubject = null
  ) {
    const searchText = search?.toLowerCase() || "";
    const matchesFilters = (item) => {
      const matchesSearch = Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText)
      );
      const matchesCountry =
        !fritoCountry ||
        item.countries?.some(
          (country) => country.toLowerCase() === fritoCountry.toLowerCase()
        );
      const matchesSector =
        !fritoSector ||
        item.sectors?.some(
          (sector) => sector.toLowerCase() === fritoSector.toLowerCase()
        );

      const matchesLanguage =
        !fritoLanguage ||
        item.languages?.some(
          (language) => language.toLowerCase() === fritoLanguage.toLowerCase()
        );

      const matchesSubject =
        !fritoSubject ||
        item.subjects?.some(
          (subject) => subject.toLowerCase() === fritoSubject.toLowerCase()
        );

      return (
        matchesSearch &&
        matchesCountry &&
        matchesSector &&
        matchesLanguage &&
        matchesSubject
      );
    };
    const filteredDocuments = documents?.filter(matchesFilters);
    setFilterData(filteredDocuments);
  }
  const handleNavigate = async (item) => {
    const contentId = item?.id;

    try {
      await patchAPI(`${BASE_URL}/forum/increment-view-count/${contentId}`, {});
    } catch (err) {
      showErrorToast(err.message);
    } finally {
      navigate("/frito-forum-detail-page", { state: item });
    }
  };
  useEffect(() => {
    filterContent(
      forumData,
      fritoSearch,
      fritoSector?.label,
      fritoCountry?.label,
      fritoLanguage?.label,
      fritoSubject?.label
    );
  }, [
    forumData,
    fritoSector,
    fritoSearch,
    fritoCountry,
    fritoLanguage,
    fritoSubject,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (forumData?.length === 0) {
          const response = await getAPI(`${BASE_URL}/forum/find-all`);
          if (response?.status === 200) {
            setFilterData(response?.data?.response);
            const fetch = response?.data?.response;
            const updatedItems = await Promise.all(
              fetch.map(async (item) => {
                const data = await fetchAndCacheImage(item?.thumbnailFileId);
                return { ...item, url: data }; // Create a new object with the `url` property
              })
            );
            dispatch(setForumData(updatedItems));
          }
        }
      } catch (err) {
        showErrorToast(err?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, forumData]);
  if (loading) return <Loading />;
  return (
    <Box
      sx={{
        mt: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingX: { xs: "16px", md: "50px" },
      }}
    >
      <InternalHeader header={"Frito forum"} />
      <Box sx={{ zIndex: 1000 }}>
        <ForumFilter setGrid={setGrid} grid={grid} />
      </Box>
      {grid === "tile" ? (
        <Card
          sx={{
            display: "flex",
            padding: "var(--Spacing-spacing-24, 24px)",
            alignItems: "flex-start",
            alignContent: "flex-start",
            gap: "24px var(--Spacing-spacing-24, 24px)",
            borderRadius: "var(--Corner-radius-effect-corner-2, 8px)",
            background: backgroundColorLayer2,
            boxShadow:
              "var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
            flexWrap: "wrap",
          }}
        >
          {filterData?.map((item) => (
            <Card
              sx={{
                width: isMobile ? "100%" : "380px",
                flex: "0 0 auto",
                boxShadow: `0px 0px 24px 0px ${boxShadowlayerColor1}`,
                borderRadius: "0px",
                background: "000",
              }}
              key={item.id}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "var(--Spacing-spacing-24, 24px)",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "var(--Spacing-spacing-16, 16px)",
                  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
                  boxShadow:
                    "var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
                  background: insideCardBackgroundColorLayer1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    padding: "var(--Spacing-spacing-0, 0px)",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "var(--Spacing-spacing-12, 12px)",
                    alignSelf: "stretch",
                  }}
                >
                  <img
                    src={item?.url}
                    alt="forum"
                    style={{ width: "331.97px", height: "133.56px" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "var(--Spacing-spacing-8, 8px)",
                    alignSelf: "stretch",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: textColorLayer5,
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily:
                        "var(--Typography-Font-Family-Heading, Inter)",
                      fontSize: "var(--Font-Size-H5, 24px)",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "var(--Line-Height-H5, 32px)",
                      alignSelf: "stretch",
                    }}
                  >
                    {item?.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: textColorLayer5,
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                      fontSize: "var(--Font-Size-Body, 16px)",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "var(--Line-Height-Body, 22px)",
                    }}
                  >
                    {item?.description}
                  </Typography>
                  <Typography
                    style={{
                      color: textColorLayer5,
                      fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                      fontSize: "var(--Font-Size-Body, 16px)",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "var(--Line-Height-Body, 22px)",
                    }}
                  >
                    Subjects
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      height: "30px",
                    }}
                  >
                    {item?.subjects?.slice(0, 3)?.map((val, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          padding:
                            "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-02, 4px)",
                          alignItems: "center",
                          gap: "2px",
                          borderRadius:
                            "var(--Corner-radius-effect-corner-1, 4px)",
                          background: insideCardBackgroundColorLayer6,
                        }}
                        key={item.id}
                      >
                        <Typography
                          sx={{
                            color: textColorLayer1,
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "133%",
                          }}
                        >
                          {val}
                        </Typography>
                      </Box>
                    ))}
                    {item?.subjects?.length > 3 && (
                      <Box
                        sx={{
                          display: "flex",
                          padding:
                            "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-02, 4px)",
                          alignItems: "center",
                          gap: "2px",
                          borderRadius:
                            "var(--Corner-radius-effect-corner-1, 4px)",
                          background: insideCardBackgroundColorLayer6,
                        }}
                      >
                        <Typography
                          sx={{
                            color: textColorLayer1,
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "133%",
                          }}
                        >
                          +{item?.subjects?.length - 3} more
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      width: "100%",
                    }}
                    onClick={() => handleNavigate(item)}
                  >
                    <Button
                      sx={{
                        display: "flex",
                        height: "var(--Spacing-spacing-32, 32px)",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "4px",
                        borderRadius:
                          "var(--Corner-radius-corner-radius-large, 24px)",
                        border:
                          "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#005CBC",
                          fontWeight: 700,
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        View Details
                      </Typography>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M6.99998 0.333008L5.82498 1.50801L10.475 6.16634H0.333313V7.83301H10.475L5.82498 12.4913L6.99998 13.6663L13.6666 6.99967L6.99998 0.333008Z"
                          fill="#005CBC"
                        />
                      </svg>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          ))}
        </Card>
      ) : (
        <ForumMainPageListView data={filterData} />
      )}
    </Box>
  );
}

export default FritoForumMainPage;
