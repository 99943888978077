import React, { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import CustomSearch from "../../../utils/CustomSearch/CustomSearch";
import FilterListIcon from "@mui/icons-material/FilterList";
import MenuList from "./MenuList";
import PropTypes from "prop-types";
export default function MainBodyHeader({ handleSearch }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: "16px",
        alignSelf: "stretch",
        position: "relative",
      }}
    >
      <CustomSearch handleSearch={handleSearch} />
      <Box
        sx={{ background: "#005CBC", padding: "4px", position: "relative" }}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <FilterListIcon
          sx={{
            fill: "white",
            height: "16px",
            width: "24px",
            cursor: "pointer",
          }}
        />
      </Box>

      {menuOpen && (
        <Box
          ref={menuRef}
          sx={{
            position: "absolute",
            top: "100%",
            right: 0,
            zIndex: 50,
            marginTop: "4px",
            backgroundColor: "white",
            boxShadow: "0px 4px 24px rgba(58, 58, 58, 0.08)",
            borderRadius: "4px",
          }}
        >
          <MenuList setMenuOpen={setMenuOpen} />
        </Box>
      )}
    </Box>
  );
}

MainBodyHeader.propTypes = {
  handleSearch: PropTypes.func,
};
