// components/LogoComponent.js
import Box from "@mui/material/Box";
import { Logo } from "pepsico-ds";
import PepsicoSymbol from "../../../assets/PepsicoSymbol.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const LogoComponent = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <Box>
      {/* Mobile Logo */}
      <Box
        sx={{
          display: { xs: "flex", lg: "none" },
          alignItems: "center",
          ml: { xs: "-20px", sm: "-200px" },
        }}
      >
        <Logo
          colorStyle="color"
          isDark={isDarkTheme}
          logoType="pepsicoSymbol"
          style={{
            width: "40px",
            height: "auto",
            cursor: "pointer",
          }}
          onClick={handleNavigate}
        />
      </Box>
      {/* Desktop Logo */}
      <Box
        sx={{
          display: { sm: "none", lg: "flex" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            width: "125.39px",
            height: "29.31px",
            textAlign: "center",
            display: { xs: "none", md: "block" },
          }}
        >
          <Logo
            colorStyle="color"
            logoType="pepsicoFullLogo"
            isDark={isDarkTheme}
            onClick={handleNavigate}
            style={{ cursor: "pointer" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LogoComponent;
