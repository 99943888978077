import React, { useEffect, useState } from "react";

import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { getAPI } from "../API/ApiService";
import { BASE_URL } from "../constant/apiUrl";
import { useSelector, useDispatch } from "react-redux";
import {
  setCountry,
  setFlashColor,
  setIpOwnerShip,
  setSearch,
  setSector,
  setVariety,
} from "../slices/internalPageSlice";

export default function PotatoQuality() {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(null);
  const [error, setError] = useState(null);
  const { favourite } = useSelector((store) => store.internalPage);
  const userId = 1;
  const dispatch = useDispatch();
  // TODO : WE HAVE USE CONSTANT API URL, WILL IMPLEMENT ENV CONFIGURATION ONCE IT WILL CONFIGURE
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=Potato%20Quality&userId=${userId}`
        );
        setPageData(response.data);
        dispatch(setSector(""));
        dispatch(setCountry(""));
        dispatch(setSearch(""));
        dispatch(setVariety(""));
        dispatch(setFlashColor(""));
        dispatch(setIpOwnerShip(""));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [favourite]);
  return <InternalPageLayout pageData={pageData} header={"Potato Quality"} />;
}
