import React from "react";
import { darkTheme, lightTheme } from "../theme/Theme";
import { useSelector } from "react-redux";
import { Button } from "pepsico-ds";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
  const isDarkTheme = useSelector((store) => store.theme);
  const navigate = useNavigate();
  const handleRoute = () => {
    navigate("/");
  };
  const styles = {
    container: {
      minHeight: "100vh",
      background: isDarkTheme
        ? darkTheme.backgroundColorLayer1
        : lightTheme.backgroundColorLayer1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: isDarkTheme ? darkTheme.textColor : lightTheme.textColor,
      padding: "20px",
    },
    heading: {
      fontSize: "4rem",
      fontWeight: "bold",
      marginBottom: "20px",
      textShadow: "2px 2px 5px rgba(0,0,0,0.3)",
    },
    subtext: {
      fontSize: "1.5rem",
      marginBottom: "30px",
    },
    button: {
      padding: "10px 20px",
      fontSize: "1rem",
      color: "#fff",
      backgroundColor: "#007BFF",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      textDecoration: "none",
      transition: "background-color 0.3s",
    },
    buttonHover: {
      backgroundColor: "#0056b3",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.heading}>404</div>
      <div style={styles.subtext}>
        Oops! The page you're looking for doesn't exist.
      </div>
      <Button text="Go Back Home" type="primary" onClick={handleRoute} />
    </div>
  );
};

export default PageNotFound;
