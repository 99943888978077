// BackToListButton.js
import React from "react";
import { Box, Typography } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";

export default function BackToListButton() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/request-information");
  };
  return (
    <Box
      data-testid="button"
      sx={{
        display: "flex",
        padding: "8px 0px", // Replace with theme spacing if needed
        justifyContent: "center",
        alignItems: "center",
        gap: "3px", // Replace with theme spacing or variable if applicablecu
        cursor: "pointer",
      }}
      onClick={handleBack}
    >
      <ArrowBackOutlinedIcon
        data-testid="img"
        sx={{ fill: "#005CBC", width: "24px", height: "24px" }}
      />
      <Typography
        sx={{
          color: "#005CBC", // Replace with theme token if applicable
          fontFamily: "Inter", // Ensure Inter font is loaded in your project
          fontSize: "16px", // Body font size
          fontStyle: "normal", // Normal style
          fontWeight: 700, // Bold font weight
          lineHeight: "22px", // Line height
          letterSpacing: "1px", // Letter spacing
        }}
      >
        Back to list
      </Typography>
    </Box>
  );
}
