import React, { useEffect, useState, memo } from "react";
import HomePageCarousal from "./../components/HomePage/HomePageCarousal";
import SpotLight from "../components/Spotlight";
import Popover from "../components/Popover";

import { Box } from "@mui/material";
import { darkTheme, lightTheme } from "../theme/Theme";
import { useDispatch, useSelector } from "react-redux";
import ViewSummary from "../components/ViewSummary/ViewSummary";

import { getAPI } from "../API/ApiService";
import { BASE_URL } from "../constant/apiUrl";
import Loading from "../utils/Loader";
import fetchAndCacheImage from "../utils/fetchandCatchImages";
import { setForumData } from "../slices/fritoForumPageSlice";

const Home = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(true);
  const { forumData } = useSelector((store) => store.fritoForumPage);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchForumData = async () => {
      try {
        if (forumData?.length === 0) {
          const response = await getAPI(`${BASE_URL}/forum/find-all`);
          if (response?.status === 200) {
            const fetch = response?.data.response;
            const updatedItems = await Promise.all(
              fetch.map(async (item) => {
                const data = await fetchAndCacheImage(item?.thumbnailFileId);
                return { ...item, url: data }; // Create a new object with the `url` property
              })
            );

            dispatch(setForumData(updatedItems));
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchForumData();
  }, [dispatch, forumData]);

  return loading ? (
    <Loading />
  ) : (
    <Box
      data-testid="home"
      sx={{
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer1
          : lightTheme.backgroundColorLayer1,
      }}
    >
      <HomePageCarousal />
      <Box
        sx={{
          padding: { sm: "40px", xs: "40px 0px 0px" },
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <SpotLight />

        <Popover />
      </Box>
      <ViewSummary />
    </Box>
  );
};

export default memo(Home);
