import { Box, Typography, Button } from "@mui/material";
import React from "react";
import DropDown from "../DropDown/DropDown";
import RenderPage from "../RenderPage/RenderPage";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../theme/Theme";

import {
  saveButtonState,
  cancelButtonState,
} from "../../../slices/buttonSlice";
export default function Administration() {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme);
  const { display } = useSelector((state) => state.adminpage);

  const handleSave = () => {
    dispatch(saveButtonState());
    // console.log(heroData); // This will log the array of hero objects
  };
  const handleCancel = () => {
    dispatch(cancelButtonState());
  };
  const buttonStyling = {
    display: "flex",
    height: "32px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "var(--Corner-radius-effect-corner-3, 24px)",
    textTransform: "none",
  };
  return (
    <>
      <div
        className="px-[83px] py-4"
        style={{
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer1
            : lightTheme.backgroundColorLayer1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            padding: "var(--Spacing-spacing-24, 24px)",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--Spacing-spacing-24, 24px)",
            flexShrink: 0,
            boxSizing: "border-box",
            overflowY: "auto",
          }}
        >
          <Typography
            sx={{
              alignSelf: "stretch",
              color: isDarkTheme
                ? darkTheme.textColorLayer2
                : lightTheme.textColorLayer2,
              fontFeatureSettings: "'liga' off, 'clig' off", // Disabling specific font features
              fontFamily: "var(--Typography-Font-Family-Heading, Inter)", // Font family
              fontSize: "var(--Font-Size-H5, 28px)", // Font size for H5
              fontStyle: "normal", // Normal style
              fontWeight: 700, // Bold font weight
              lineHeight: "var(--Line-Height-H5, 32px)", // Line height
            }}
          >
            Administration
          </Typography>
          <DropDown width={"100%"} />
          <RenderPage />
        </Box>
      </div>
      {display && (
        <Box
          sx={{
            display: "flex",

            background: isDarkTheme ? darkTheme.backgroundColorLayer1 : "#fff",
            boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
            width: "100%", // Optional, depending on the layout
            padding:
              "var(--Spacing-spacing-04, 16px) var(--Spacing-spacing-05, 24px)",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",

              height: "32px",
              alignItems: "center",
              gap: "16px",
              flexShrink: 0,
            }}
          >
            {/* Your content here */}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
              // padding: { xs: "0px 16px", md: "0px 72px" },
            }}
          >
            <Button
              size="small"
              variant="outlined"
              sx={buttonStyling}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                ...buttonStyling,
                backgroundColor: "var(--button-color-button-primary, #005CBC)",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
