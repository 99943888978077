import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import AccordionDetail from "./AccordionDetail";
import useThemeRender from "../../../../theme/useThemeRender";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useNavigate } from "react-router-dom";
function SpecialProjectAccordion({ item, expanded, handleToggle }) {
  const navigate = useNavigate();
  const cardBackgroundColorLayer3 = useThemeRender("cardBackgroundColorLayer3");
  const textColorLayer1 = useThemeRender("textColorLayer1");
  const insideCardBackgroundColorLayer5 = useThemeRender(
    "insideCardBackgroundColorLayer5"
  );
  const handleNavigate = (item) => {
    navigate("/special-project-edit", { state: item });
    console.log(item,'item')
  };

  return (
    <Accordion
      key={item.id}
      expanded={expanded === item.id}
      onClick={(e) => e.preventDefault()}
      sx={{
        width: "100%",
        background: cardBackgroundColorLayer3,
        "&.Mui-expanded": {
          margin: 0,
        },
        borderTop: "2px solid #D7E5F8",
        boxShadow: "none",
        "&:before": {
          display: "none",
          background: "none",
          "&:first-of-type": {
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
          },
          "&:last-of-type": {
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
          },
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          mb: "0px",
        },
      }}
    >
      <AccordionSummary
        onClick={(e) => e.preventDefault()}
        aria-controls="panel-content"
        id="panel-header"
        sx={{
          "& .MuiAccordionSummary-expandIconWrapper": {
            width: "40px",
            height: "40px",
            backgroundColor: "#EFF5FC",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
          },
          "& .MuiAccordionSummary-content": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box
          display={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography
                sx={{
                  color: textColorLayer1,
                  fontFamily: "Inter",
                  fontSize: "clamp(14px, 4vw, 18px)",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "28px",
                }}
              >
                {item?.name}
              </Typography>
              <Box
                sx={{
                  mr: "5px",
                  background: "#B3DACB",
                  borderRadius: "4px",
                  p: "2px",
                  color: "text.primary",
                  fontSize: 12,
                  fontWeight: 400,
                  fontFamily: "Inter",
                  lineHeight: "15.96px",
                  textAlign: "center",
                }}
              >
                {item?.status
                  ? "Active special project"
                  : "Inactive special project"}
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <IconButton
                color="primary"
                size="small"
                sx={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: insideCardBackgroundColorLayer5,
                  borderRadius: "5px",
                  border: "1px solid #D7E5F8",
                }}
                onClick={() => handleNavigate(item)}
              >
                <EditOutlinedIcon
                  sx={{
                    color: "#005CBC",
                  }}
                />
              </IconButton>
              <IconButton
                color="primary"
                size="small"
                sx={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: insideCardBackgroundColorLayer5,
                  borderRadius: "5px",
                  border: "1px solid #D7E5F8",
                }}
                onClick={() => handleToggle(item.id)}
              >
                {expanded === item.id ? (
                  <ExpandLessIcon
                    sx={{
                      color: "#005CBC",
                      "& svg": {
                        height: "24px",
                      },
                    }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{
                      color: "#005CBC",
                      "& svg": {
                        height: "24px",
                      },
                    }}
                  />
                )}
              </IconButton>
            </Box>
          </Box>
        </Box>
        {expanded === item.id ? (
          <p
            style={{
              color: textColorLayer1,
              fontSize: 12,
              fontWeight: 400,
              fontFamily: "Inter",
              lineHeight: "15.96px",
              marginTop: "5px",
            }}
          >
            {item.description}
          </p>
        ) : (
          <Box
            sx={{
              display: "flex",
              mt: "5px",
              gap: "8px",
              color: textColorLayer1,
            }}
          >
            <span>Items: {item?.contents.length}</span>
            <span>Users: {item?.users.length}</span>
          </Box>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <AccordionDetail header={"Items"} data={item.contents} />
        <AccordionDetail header={"Users"} data={item.users} />
      </AccordionDetails>
    </Accordion>
  );
}

export default SpecialProjectAccordion;
